import { Autocomplete, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { style } from "@mui/system";
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getCompaniesFun } from "../../Redux/company/action";
import commonStyles from "../../styles/Common.module.css"
import styles from "./styles/AddNews.module.css";


const categories = [
    "Short News",
    "Long News"
];

const AddNews = () => {
    const dispatch = useDispatch();
    const { companies } = useSelector((store) => store.companies);
    const [newsData, setNewsData] = useState({
        company_Id: null,
        category: "Short News",
        importance: true,
        news_document: null,
        tables: []
    });


    useEffect(() => {
        dispatch(getCompaniesFun())
    }, []);

    const setNews = (ele) => {
        const { name, value } = ele.target;

        setNewsData({
            ...newsData,
            [name]: value
        });
    };

    const handleFileChange = (ele) => {
        const { id, value } = ele.target;

        setNewsData({
            ...newsData,
            [id]: ele.target.files[0]
        });
    };

    const handleTablesChange = (ele) => {
        const {name, value} = ele.target;

        setNewsData({
            ...newsData,
            tables: [...newsData.tables, value]
        });
    };

    const setCompany_Id = (ele, value) => {

        setNewsData({
            ...newsData,
            "company_Id": value.id
        });
    };

    const addNews = async (event) => {
        event.preventDefault();
        const formData = new FormData();

        formData.append("company_Id", newsData.company_Id);
        formData.append("importance", newsData.importance);
        formData.set("news_document", newsData.news_document);
        formData.append("category", newsData.category);

        for(let i = 0; i < newsData.tables.length; i++) {
            formData.append(`tables[${i}]`, newsData.tables[i]);
        };

        const res = await fetch("https://api.sovrenn.com/news", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            },
            body: formData
        })

        const data = await res.json();

        if (res.ok) {
            setNewsData({
                company_Id: null,
                news_document: null,
                tables: []
            })
            alert("News Added Successfully");
            
        }
        else {
            alert("Erroor occurred while uploading the files");
        }
    };

    return (
        <div className={`${commonStyles.MainContainer} ${styles.CreateMainContainer}`}>
            <h2>Add New News</h2>

            <form onSubmit={addNews}>
                <div id={styles.firstChildDiv}>
                    <div className={styles.inputDiv}>
                        <label htmlFor="company_Id">Select Company</label>
                        <Autocomplete
                            fullWidth
                            size="medium"
                            disablePortal
                            id="company_Id"
                            name="company_Id"
                            options={companies}
                            sx={{ width: 300 }}
                            onChange={setCompany_Id}
                            getOptionSelected={(option, value) => option.value === value.value}
                            renderInput={(params) => <TextField required {...params} label="Company" />}
                        />
                    </div>
                    <div className={styles.inputDiv} >
                        <label htmlFor="company_Id">Select Category</label>
                        <FormControl sx={{ minWidth: "200px" }}>
                            <InputLabel id="demo-simple-select-helper-label">Category</InputLabel>
                            <Select
                                required
                                labelId="demo-simple-select-helper-label"
                                id="category"
                                name="category"
                                value={newsData.category}
                                label="Category"
                                onChange={setNews}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {categories.map((ele, ind) => {
                                    return <MenuItem key={ind} value={ele}>
                                        {ele}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={styles.inputDiv} >
                        <label htmlFor="category">Importance</label>
                        <FormControl sx={{ minWidth: "150px" }}>
                            <InputLabel

                                id="demo-simple-select-helper-label">Importance</InputLabel>
                            <Select
                                required
                                labelId="demo-simple-select-helper-label"
                                id="importance"
                                name="importance"
                                value={newsData.importance}
                                label="Importance"
                                onChange={setNews}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={true}>
                                    Yes
                                </MenuItem>
                                <MenuItem value={false}>
                                    No
                                </MenuItem>

                            </Select>
                        </FormControl>
                    </div>
                    <div className={styles.inputDiv} >
                        <label htmlFor="news_document">News Document</label>
                        <input type="file"
                            accept='text/x-markdown' name="news_document" id="news_document" onChange={handleFileChange} />
                    </div>
                </div>

                <div id={styles.tablesDiv}>
                    <div className={styles.inputDiv} >
                        <label htmlFor="tableText">Table 1</label>
                        <textarea className={styles.tablesText} name="tableText" cols="30" rows="10" onChange={handleTablesChange}></textarea>
                    </div>

                    <div className={styles.inputDiv} >
                        <label htmlFor="tableText">Table 2</label>
                        <textarea className={styles.tablesText} name="tableText" cols="30" rows="10" onChange={handleTablesChange}></textarea>
                    </div>

                    <div className={styles.inputDiv} >
                        <label htmlFor="tableText">Table 3</label>
                        <textarea className={styles.tablesText} name="tableText" cols="30" rows="10" onChange={handleTablesChange}></textarea>
                    </div>
                </div>

                <button type="submit">Add News</button>
            </form>
        </div>
    )
}

export default AddNews