import { Add, Delete, Edit } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBucketsFun } from '../../Redux/bucket/action';
import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/Bucket.module.css";

const Bucket = () => {
  const dispatch = useDispatch();
  const { functional, sectoral } = useSelector((store) => store.bucket);
  const [modal, setModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    dispatch(getBucketsFun());

    return () => {

    }
  }, []);

  const deleteBucket = async () => {
    setModal(false);
    const res = await fetch(`https://api.sovrenn.com/buckets/${selectedId}`, {
      method: "DELETE",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("sov_user")
      }
    });

    const data = await res.json();

    if (res.ok) {
      dispatch(getBucketsFun());
      alert(data.message);
      return;
    };

    alert("Something went wrong");
    return;
  };

  const handleUpdateFree = async (body, id) => {

    const res = await fetch(`https://api.sovrenn.com/buckets/${id}`, {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("sov_user")
      },
      body: JSON.stringify(body)
    });

    const data = await res.json();

    if (res.ok) {
      alert(data.basket.avail_free ? "Made this bucket free" : "This bucket is no longer available for free.")
    }
    else {
      alert("Something Went wrong");
    };

    dispatch(getBucketsFun());
    return;
  };

  return (
    <div className={commonStyles.MainContainer}>

      <div className={commonStyles.headerDiv}>
        <h1>Thematic Buckets</h1>

        <div>
          <Link to="/parent-buckets">
            <Button color="secondary" variant="outlined" fullWidth>Nested Buckets</Button>
          </Link>

          <Link to="/buckets/create">
            <Button color="secondary" variant="outlined" startIcon={<Add />} fullWidth>Create Bucket</Button>
          </Link>
        </div>
      </div>

      <h2 style={{ margin: "10px 0" }}>Functional Buckets</h2>
      <div id={styles.bucketContainer}>
        {functional.map((ele, ind) => {
          return <div className={styles.bucketCard}>
            <h3>{ele.title}</h3>
            <h4>{ele.description}</h4>

            <div className={styles.cardBtns}>
              <Link to={`/buckets/${ele.slug}`}>
                <IconButton className={styles.editButton}>
                  <Edit />
                </IconButton>
              </Link>

              <IconButton className={styles.editButton} onClick={() => {
                setModal(true);
                setSelectedId(ele._id)
              }}>
                <Delete />
              </IconButton>

              <Button size='small' color={ele.avail_free ? "error" : "success"} variant="outlined" sx={{ width: "150px" }} onClick={() => {
                handleUpdateFree(
                  {
                    avail_free: !ele.avail_free
                  },
                  ele._id
                );
              }}>{ele.avail_free ? "Remove Free" : "Make It Free"}</Button>
            </div>
          </div>
        })}
      </div>

      <h2 style={{margin: "30px 0 10px"}}>Sectoral Buckets</h2>
      <div id={styles.bucketContainer}>
        {sectoral.map((ele, ind) => {
          return <div className={styles.bucketCard}>
            <h3>{ele.title}</h3>
            <h4>{ele.description}</h4>

            <div className={styles.cardBtns}>
              <Link to={`/buckets/${ele.slug}`}>
                <IconButton className={styles.editButton}>
                  <Edit />
                </IconButton>
              </Link>

              <IconButton className={styles.editButton} onClick={() => {
                setModal(true);
                setSelectedId(ele._id)
              }}>
                <Delete />
              </IconButton>

              <Button size='small' color={ele.avail_free ? "error" : "success"} variant="outlined" sx={{ width: "150px" }} onClick={() => {
                handleUpdateFree(
                  {
                    avail_free: !ele.avail_free
                  },
                  ele._id
                );
              }}>{ele.avail_free ? "Remove Free" : "Make It Free"}</Button>
            </div>
          </div>
        })}
      </div>

      <Dialog open={modal}>
        <DialogTitle sx={{ fontWeight: "bolder" }}>Confirmation</DialogTitle>
        <DialogContent >
          <DialogContentText sx={{ color: "black", fontSize: "large" }}>
            Do you really want delete this bucket
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setModal(false);
          }}>Cancel</Button>
          <Button color='error' onClick={deleteBucket}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Bucket