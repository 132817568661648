import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/Users.module.css";
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, TablePagination } from "@mui/material";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import modalStyles from "../../styles/ModalStyle.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getUserFun, getUsersListFun, setUserDetails } from "../../Redux/Users/action";
import { CSVLink } from "react-csv";
import ReactQuill from "react-quill";
import moment from "moment/moment";


const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ],
};

const Users = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((store) => store.auth);
    const { users, total_documents, is_loading } = useSelector((store) => store.users);
    const [searchParams, setSearchParams] = useSearchParams();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({
        _id: "",
        name: "",
        email: "",
        phone: "",
        subscriptions: [],
        trial_expired: false
    });

    const [updateRemark, setUpdateRemark] = useState({
        remark: "",
        user_id: "",
        lead: "",
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(1000);

    const [manualAmount, setManualAmount] = useState(0);
    const [mailDialog, setMailDialog] = useState(false);
    const [emailContent, setEmailContent] = useState({
        user_email: "",
        subject: "",
        message: "",
    });
    const [giveAccessProgress, setGiveAccessProgress] = useState(false);

    const email = searchParams.get("email");
    const phone_number = searchParams.get("phone_number");
    const first_name = searchParams.get("first_name");
    const last_name = searchParams.get("last_name");

    const type = searchParams.get("type");
    const page_no = searchParams.get("page_no");
    const page_size = searchParams.get("page_size");

    useEffect(() => {

        if (type !== null) {
            dispatch(getUsersListFun(type, page_no, page_size))
        }
        else {
            dispatch(getUserFun({ email, phone_number, first_name, last_name }));
        }

        return () => {

        };
    }, [page_no, page_size]);


    useEffect(() => {
        if (updateRemark.user_id) {
            const updateData = setTimeout(() => {
                sendUpdateRequest();

            }, 1000);
            return () => clearTimeout(updateData)
        };

        return () => {

        }

    }, [updateRemark]);

    const updateChange = (key, value, id) => {

        if (updateRemark.user_id !== id) {
            setUpdateRemark({
                remark: "",
                user_id: "",
                lead: "",
            });
        };

        setUpdateRemark({
            ...updateRemark,
            [key]: value,
            user_id: id
        });
    };

    const sendUpdateRequest = async () => {
        let body = {};

        for (let key in updateRemark) {
            if (key == "added_in_times" || key == "added_in_fullaccess") {

                body[key] = updateRemark[key];
                continue;
            };

            if (key !== "user_id" && updateRemark[key]) {

                body[key] = updateRemark[key];
                body["remark_by"] = user.first_name + " " + user.last_name;
            };
        };

        const res = await fetch(`https://api.sovrenn.com/user/edit-info/${updateRemark.user_id}`, {
            method: "PATCH",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            },
            body: JSON.stringify(body)
        });

        const data = await res.json();

        if (res.ok) {
            if (type !== null) {
                dispatch(getUsersListFun(type, page_no, page_size))
            }
            else {
                dispatch(getUserFun({ email, phone_number, first_name, last_name }));
            }

            setUpdateRemark({
                remark: "",
                user_id: "",
                lead: "",
            });
        }
    };

    const giveAccess = (ele) => {
        console.log(ele);

        setModalData({
            _id: ele._id,
            name: ele.first_name + " " + ele.last_name,
            email: ele.email,
            phone: ele.phone_number,
            subscriptions: ele.subscriptions,
            trial_expired: ele.trial_expired
        });

        setModalOpen(true);
        return;
    };

    const giveAccessFun = async (type, id, free_access) => {
        setModalOpen(false);
        setGiveAccessProgress(true);

        const res = await fetch(`https://api.sovrenn.com/manual/${type}/${id}?amount=${manualAmount}&free_access=${free_access}`, {
            method: "PATCH",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            }
        })
            .then((r) => r.json());

        setGiveAccessProgress(false);

        if (res.success) {
            dispatch(getUserFun({ email, phone_number, first_name, last_name }));
            alert("Access has been given succssfully");
            return;
        };

        alert("Something went wrong");
        return;
    };
    const headers = [
        { label: "User Id", key: "custom_Id" },
        { label: "First Name", key: "first_name" },
        { label: "Last Name", key: "last_name" },
        { label: "Email", key: "email" },
        { label: "Country Code", key: "country_code" },
        { label: "Phone Number", key: "phone_number" },
        { label: "Total Spent", key: "total_spent" },
        { label: "State", key: "state" },
        { label: "Subscribed to", key: "subscriptions" },
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        changePath(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
        changePath(0, event.target.value);
    };

    const changePath = (page_no, page_size) => {
        navigate(`/users?type=${type}&page_no=${page_no}&page_size=${page_size}`);
    };

    const handleSendEmail = async (event) => {
        event.preventDefault();

        const res = await fetch("https://api.sovrenn.com/user/send-personal-mail", {
            method: "POST",
            body: JSON.stringify(emailContent),
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user"),
                "Content-type": "application/json"
            }
        });

        const data = await res.json();

        alert(data.message);

        setMailDialog(false);

        setEmailContent({
            user_email: "",
            subject: "",
            message: "",
        });
        return;
    };

    const date = new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear();

    if (is_loading || !users.length) {
        <div className={`${styles.MainContainer}`} >
            <div className={commonStyles.headerDiv}>
                <h1>Users</h1>

                <CSVLink data={users} filename={`users-list-${date}`} headers={headers} target="_blank">
                    <Button color="secondary" variant="outlined" startIcon={<DownloadOutlinedIcon />} sx={{ width: "250px" }}>Download List</Button>
                </CSVLink>
            </div>
            <Box sx={{ display: 'flex', justifyContent: "center", width: 100, margin: "100px auto" }}>
                <CircularProgress />
            </Box>
        </div>
    }

    return (
        <div className={`${styles.MainContainer}`} >
            <div className={commonStyles.headerDiv}>
                <h1>Users</h1>

                <CSVLink data={users} filename={`users-list-${date}`} headers={headers} target="_blank">
                    <Button color="secondary" variant="outlined" startIcon={<DownloadOutlinedIcon />} sx={{ width: "250px" }}>Download List</Button>
                </CSVLink>
            </div>


            <div id={styles.tableDiv}>
                <table id={styles.userListContainer}>
                    <thead id={styles.userListHead}>
                        <tr className={styles.userCard}>
                            <th className={styles.userId}>Where Did Hear</th>
                            <th className={styles.tableNameRow}>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Total Paid</th>
                            <th>Action Counts</th>
                            <th>App Installed</th>
                            <th>Pulse Exists</th>
                            <th>Access To</th>
                            {type === "full-access" || type === "life" || type === "news" || type === "monthly" || type === "quarterly" || type === "basket" || type === "trial-removed" ? <th>Expiry Date</th> : ""}
                            <th>Signed Up From</th>
                            {type === "full-access" || type === "life" ? "" : <th>Lead</th>}
                            <th>WP Times</th>
                            <th>WP FA</th>
                            {type === "full-access" || type === "life" ? "" : <th>Remark</th>}
                            <th>Give</th>
                        </tr>
                    </thead>

                    <tbody>
                        {users.map((ele, ind) => {
                            return <tr className={styles.userCard} key={ele._id}>
                                <td className={styles.userId}>{ele?.where_did_hear_about_sovrenn || "NA"}</td>
                                <td className={styles.tableNameRow}><Link to={`/users/${ele._id}`} target="_blank">
                                    {ele.first_name} {ele.last_name}
                                </Link></td>
                                <td style={{ color: "blue", fontWeight: "bold", cursor: "pointer" }} onClick={() => {
                                    setEmailContent({
                                        ...emailContent,
                                        user_email: ele.email
                                    });
                                    setMailDialog(true);
                                }}>
                                    {ele.email}
                                </td>

                                <td className={styles.tablePhoneNoColumn}>
                                    <a target="_blank" href={`https://wa.me/${ele.phone_number}`}>
                                        {(ele?.country_code) + " " + ele.phone_number}
                                    </a>
                                </td>

                                <td>₹{ele.total_spent}</td>
                                <td>{ele?.action_counts || 0}</td>
                                <td>{ele.has_app_installed ? "Yes" : "No"}</td>
                                <td>{ele.my_portfolio_exists ? "Yes" : "No"}</td>
                                <td className={styles.tableSubscriptions}>{ele.subscriptions.map((ele, ind) => {
                                    return <p>{ele === "news" ? "News" : ele === "full-access" ? "Full_Access" : ele === "basket" ? "SD" : ele === "trial" ? "Trial" : ele === "life" ? "Life" : ele === "monthly" ? "Monthly" : ele === "quarterly" ? "Quarterly" : ""}</p>
                                })}</td>

                                {type === "full-access" || type === "life" || type === "news" || type === "monthly" || type === "quarterly" || type === "basket" || type === "trial-removed" ? <td>
                                    {moment(ele?.expiry_date).format("Do MMM YY")}
                                </td>
                                    : ""}

                                <td>
                                    {ele.signed_up_from ? ele.signed_up_from : "NA"}
                                </td>

                                {type === "full-access" || type === "life" ? "" : <td >
                                    <select id="lead" value={ele?.lead || ""} className={styles.leadSelect}
                                        onChange={(event) =>

                                            updateChange("lead", event.target.value, ele._id)
                                        }>
                                        <option value="">Select</option>
                                        <option value="Trial Hot Lead">Trial Hot Lead</option>
                                        <option value="Times Hot Lead">Times Hot Lead</option>
                                        <option value="Full Access Hot Lead">Full Access Hot Lead</option>
                                        <option value="Not Interested">Not Interested</option>
                                        <option value="Undecided">Undecided</option>
                                        <option value="Not Connected">Not Connected</option>
                                    </select>
                                </td>}

                                <td>
                                    <input
                                        checked={ele.added_in_times ? true : false}
                                        id="added_in_times"
                                        onChange={
                                            () => {
                                                updateChange("added_in_times", !ele.added_in_times, ele._id)
                                            }
                                        }
                                        className={styles.checkBoxes}
                                        type="checkbox" />
                                </td>

                                <td>
                                    <input
                                        checked={ele.added_in_fullaccess ? true : false}
                                        id="added_in_fullaccess"
                                        className={styles.checkBoxes}
                                        type="checkbox"
                                        onChange={
                                            () => {
                                                updateChange("added_in_fullaccess", !ele.added_in_fullaccess, ele._id)
                                            }
                                        } />
                                </td>

                                {type === "full-access" || type === "life" ? "" :
                                    <td className={styles.tableRemarkColumn}>
                                        <textarea type="text"
                                            id="remark"
                                            className={styles.remarkInput}
                                            onChange={(event) =>

                                                updateChange("remark", event.target.value, ele._id)
                                            }>{ele.remark}</textarea>
                                    </td>
                                }
                                <td><button
                                    onClick={() => giveAccess(ele)}
                                >Give Access</button></td>
                            </tr>
                        })}
                    </tbody>
                </table>

                <TablePagination
                    component="div"
                    count={total_documents}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[100, 500, 1000]}
                />
            </div>

            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Box className={modalStyles.InfoModal}>
                    <h3>{modalData.name}</h3>

                    <div className={modalStyles.spanDiv}>
                        <h4>E-mail: <span>{modalData.email}</span></h4>
                        <h4>Phone: <span>{modalData.phone}</span></h4>
                    </div>

                    <div id={modalStyles.amountSelect}>
                        <label htmlFor="manualAmount">Free Or Paid (select amount):</label>

                        <input id="manualAmount" value={manualAmount} type="number" onChange={(ele) => setManualAmount(ele.target.value)} />
                    </div>

                    <div id={modalStyles.submitBtnDiv}>
                        <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("full-access", modalData?._id)}>Give Full Access</button>

                        {modalData.trial_expired || modalData.subscriptions.includes("trial") ? "" : <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("trial", modalData?._id)}>Give Trial Access</button>}

                        <button className={modalStyles.submitBtn}
                            onClick={() => giveAccessFun("news", modalData?._id)}>Give News Access</button>

                        <button className={modalStyles.submitBtn}
                            onClick={() => giveAccessFun("life", modalData?._id)}>Give Life Access</button>
                    </div>

                    {/* {!modalData.subscriptions.includes("news") && !modalData.subscriptions.includes("full-access") && !modalData.subscriptions.includes("basket") && !modalData.subscriptions.includes("trial") ?
                        <div id={modalStyles.submitBtnDiv}>
                            <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("full-access", modalData._id)}>Give Full Access</button>

                            <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("trial", modalData._id)}>Give Trial Access</button>

                            <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("basket", modalData._id)}>Give Discovery Access</button>

                            <button className={modalStyles.submitBtn}
                                onClick={() => giveAccessFun("news", modalData._id)}>Give News Access</button>

                            <button className={modalStyles.submitBtn}
                                onClick={() => giveAccessFun("life", modalData._id)}>Give Life Access</button>
                        </div>
                        :
                        modalData?.subscriptions?.includes("news") && !modalData?.subscriptions?.includes("basket") ?
                            <div id={modalStyles.submitBtnDiv}>
                                <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("full-access", modalData._id)}>Give Full Access</button>

                                <button className={modalStyles.submitBtn}
                                    onClick={() => giveAccessFun("life", modalData._id)}>Give Life Access</button>

                                <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("trial", modalData._id)}>Give Trial Access</button>

                                <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("basket", modalData._id)}>Give Discovery Access</button>
                            </div>
                            :
                            !modalData?.subscriptions?.includes("news") && modalData?.subscriptions?.includes("basket") ?
                                <div id={modalStyles.submitBtnDiv}>
                                    <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("full-access", modalData._id)}>Give Full Access</button>

                                    <button className={modalStyles.submitBtn}
                                        onClick={() => giveAccessFun("life", modalData._id)}>Give Life Access</button>

                                    <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("trial", modalData._id)}>Give Trial Access</button>

                                    <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("news", modalData._id)}>Give News Access</button>
                                </div>
                                :
                                modalData?.subscriptions?.includes("full-access") ?
                                    <div id={modalStyles.submitBtnDiv}>

                                        <button className={modalStyles.submitBtn}
                                            onClick={() => giveAccessFun("life", modalData._id)}>Give Life Access</button>
                                    </div>
                                    :
                                    <div id={modalStyles.submitBtnDiv}>
                                        <button className={modalStyles.submitBtn}
                                            onClick={() => giveAccessFun("full-access", modalData._id)}>Give Full Access</button>

                                        <button className={modalStyles.submitBtn}
                                            onClick={() => giveAccessFun("life", modalData._id)}>Give Life Access</button>

                                        <button className={modalStyles.submitBtn}
                                            onClick={() => giveAccessFun("news", modalData._id)}>Give News Access</button>
                                    </div>
                    }

                    {!modalData?.subscriptions?.includes("full-access") && !modalData?.subscriptions?.includes("life") && !modalData?.subscriptions?.includes("monthly") && !modalData?.subscriptions?.includes("quarterly") && !modalData?.subscriptions?.includes("trial") ?
                        <div id={modalStyles.submitBtnDiv} style={{ marginTop: "20px" }}>
                            <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("monthly", modalData._id)}>Give Monthly Access</button>

                            <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("quarterly", modalData._id)}>Give Quarterly Access</button>

                            <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("trial", modalData._id, true)}>Give Free Trial</button>
                        </div>
                        :
                        !modalData?.subscriptions?.includes("full-access") && !modalData?.subscriptions?.includes("life") && !modalData?.subscriptions?.includes("quarterly") && !modalData?.subscriptions?.includes("trial") ?
                            <div id={modalStyles.submitBtnDiv} style={{ marginTop: "20px" }}>
                                <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("quarterly", modalData._id)}> Give Quarterly Access</button>

                                <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("trial", modalData._id, true)}>Give Free Trial</button>
                            </div>
                            :
                            !modalData?.subscriptions?.includes("full-access") && !modalData?.subscriptions?.includes("life") && !modalData?.subscriptions?.includes("monthly") && !modalData?.subscriptions?.includes("trial") ?
                                <div id={modalStyles.submitBtnDiv} style={{ marginTop: "20px" }}>
                                    <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("monthly", modalData._id)}>Give Monthly Access</button>

                                    <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("trial", modalData._id, true)}>Give Free Trial</button>
                                </div>
                                :
                                ""
                    } */}
                </Box>
            </Modal>

            <Dialog open={mailDialog}>
                <DialogTitle sx={{ fontWeight: "bolder" }}>Email to - {emailContent.user_email}</DialogTitle>
                <DialogContent >
                    <div className={styles.inputDiv}>
                        <h3 style={{ color: "#809275", marginBottom: "10px" }}>Subject</h3>
                        <input style={{ width: "100%", height: "40px", fontSize: "medium", padding: "5px" }} value={emailContent.subject} type="text" id="subject" onChange={(ele) => {
                            setEmailContent({
                                ...emailContent,
                                [ele.target.id]: ele.target.value
                            })
                        }} required />
                    </div>

                    <div >
                        <h3 style={{ color: "#809275", margin: "10px 0" }}>Message</h3>

                        <ReactQuill
                            value={emailContent.message}
                            onChange={(value) => setEmailContent({
                                ...emailContent,
                                message: value
                            })}
                            modules={modules}
                            // formats={formats}
                            placeholder="Write something..."
                            style={{ height: "300px", width: "500px" }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={() => {
                        setMailDialog(false);
                    }}>Cancel</Button>
                    <Button onClick={handleSendEmail}>Send Mail</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={giveAccessProgress} disableBackdropClick disableEscapeKeyDown>
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '20px',
                    }}
                >
                    <CircularProgress color="secondary" style={{ width: "50px", height: "50px" }} />
                    <h3 style={{ textAlign: 'center', marginTop: '20px' }}>
                        Please wait...
                    </h3>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Users