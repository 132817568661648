import { async } from "q";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/CreateCompany.module.css";
import { industryArr, sectorArr } from "./constant";
import { getSectorsDataFun } from "../../Redux/sector/action";

const CreateCompany = () => {
    const dispatch = useDispatch();
    const { sectors } = useSelector((store) => store.sectors);

    const nameRef = useRef();
    const priceRef = useRef();
    const isinRef = useRef();
    const no_of_sharesRef = useRef();
    const ttm_patRef = useRef();
    const sectorRef = useRef();
    const industryRef = useRef();
    const market_capRef = useRef();
    const ttm_peRef = useRef();
    const revenue_growthRef = useRef();
    const profit_growthRef = useRef();
    const exchangeRef = useRef();
    const offer_price_ttm_peRef = useRef();
    const company_type_ref = useRef();

    useEffect(() => {
        dispatch(getSectorsDataFun());

        return () => {

        }
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault();

        const body = {};

        body["company_name"] = nameRef.current.value;
        body["share_price"] = priceRef.current.value;
        body["sector_id"] = sectorRef.current.value;
        body["industry"] = industryRef.current.value;
        body["isin"] = isinRef.current.value;
        body["ttm_pat"] = ttm_patRef.current.value;
        body["no_of_shares"] = no_of_sharesRef.current.value;
        body["market_cap"] = market_capRef.current.value;
        body["ttm_pe"] = ttm_peRef.current.value;
        body["profit_growth"] = profit_growthRef.current.value;
        body["revenue_growth"] = revenue_growthRef.current.value;
        body["exchange"] = exchangeRef.current.value;
        body["offer_price_ttm_pe"] = offer_price_ttm_peRef.current.value;
        body["company_type"] = company_type_ref.current.value;

        const data = await fetch("https://api.sovrenn.com/company", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user"),
                "Content-type": "application/json"
            },
            body: JSON.stringify(body)
        })
            .then((res) => res.json());


        if (data.success) {
            alert("Company added successfully!")
        }
        else {
            alert("Ooops something went wrong please try again");
        };
    };

    return (
        <div className={`${commonStyles.MainContainer} ${styles.CreateMainContainer}`}>
            <h2>Add New Company</h2>

            <div id={styles.formDiv}>
                <form action="" id={styles.form} onSubmit={handleSubmit}>
                    <div id={styles.nameNdPriceDiv}>
                        <div className={styles.inputDiv}>
                            <label htmlFor="company_name">
                                Company Name
                            </label>
                            <input ref={nameRef} type="text" id="company_name" name="company_name" required />

                        </div>
                        <div className={styles.inputDiv}>
                            <label htmlFor="share_price">
                                Share Price
                            </label>
                            <input ref={priceRef} type="text" id="share_price" name="share_price" required />
                        </div>

                        <div className={styles.inputDiv}>
                            <label htmlFor="no_of_shares">
                                Number of Shares
                            </label>
                            <input ref={no_of_sharesRef} type="text" id="no_of_shares" name="no_of_shares" required />
                        </div>

                        <div className={styles.inputDiv}>
                            <label htmlFor="exchange">Stock Exchange</label>
                            <select ref={exchangeRef} id="exchange" name="exchange" required>
                                <option value="">Select Exchange</option>

                                <option value="NSE">NSE</option>
                                <option value="BSE">BSE</option>

                            </select>
                        </div>

                    </div>

                    <div id={styles.firstChildDiv}>

                        <div className={styles.inputDiv}>
                            <label htmlFor="sector_id">Sector</label>
                            <select ref={sectorRef} id="sector_id" name="sector_id" required>
                                <option value="">Select Sector</option>

                                {sectors.map((ele, ind) => {
                                    return <option value={ele._id}>{ele.name}</option>
                                })}

                            </select>
                        </div>
                        <div className={styles.inputDiv}>
                            <label htmlFor="industry">Industry</label>
                            <select ref={industryRef} id="industry" name="industry" required>
                                <option value="">Select Industry</option>

                                {industryArr.map((ele, ind) => {
                                    return <option value={ele}>{ele}</option>
                                })}
                            </select>
                        </div>
                        <div className={styles.inputDiv}>
                            <label htmlFor="isin">ISIN</label>
                            <input ref={isinRef} type="text" name="isin" id="isin" required />
                        </div>
                    </div>

                    <div id={styles.thirdChildDiv}>

                        <div className={styles.inputDiv}>
                            <label htmlFor="market_cap">
                                Market Cap
                            </label>
                            <input ref={market_capRef} type="text" id="market_cap" name="market_cap" required />
                        </div>
                        <div style={{width: "200px"}} className={styles.inputDiv}>
                            <label htmlFor="ttm_pe">
                                TTM PE
                            </label>
                            <input ref={ttm_peRef} type="text" id="ttm_pe" name="ttm_pe" required />
                        </div>
                        <div style={{ width: "200px" }} className={styles.inputDiv}>
                            <label htmlFor="ttm_pat">
                                TTM PAT
                            </label>
                            <input ref={ttm_patRef} type="text" id="ttm_pat" name="ttm_pat" required />
                        </div>
                        <div className={styles.inputDiv}>
                            <label htmlFor="revenue_growth">
                                Revenue Growth
                            </label>
                            <input ref={revenue_growthRef} type="text" id="revenue_growth" name="revenue_growth" required />
                        </div>
                        <div className={styles.inputDiv}>
                            <label htmlFor="profit_growth">
                                Profit Uptrend
                            </label>

                            <select ref={profit_growthRef} id="profit_growth" name="profit_growth" required>
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                        <div className={styles.inputDiv}>
                            <label htmlFor="offer_price_ttm_pe">
                                Offer Price PE
                            </label>
                            <input ref={offer_price_ttm_peRef} type="text" id="offer_price_ttm_pe" name="offer_price_ttm_pe" required />
                        </div>

                        <div className={styles.inputDiv}>
                            <label htmlFor="company_type">
                                Company Type
                            </label>

                            <select ref={company_type_ref} id="company_type" name="company_type" required>
                                <option value="">Select</option>
                                <option value="SME">SME</option>
                                <option value="Non SME">Non SME</option>
                            </select>
                        </div>
                    </div>

                    <button type="submit">Add Company</button>
                </form>
            </div>
        </div>
    )
}

export default CreateCompany