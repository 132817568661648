import { Autocomplete, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { style } from "@mui/system";
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getCompaniesFun } from "../../Redux/company/action";
import commonStyles from "../../styles/Common.module.css"
import styles from "./styles/AddNews.module.css";
import OverlayCircularProgress from "../Utils/OverlayCircularProgress";
import EditorComponent from "../Editor/EditorComponent";
import { Add, ArrowUpward } from "@mui/icons-material";
import { initEditor } from "../../utils/initEditor";


const categories = [
    "Short News",
    "Long News"
];

const AddNewsByBlocks = () => {
    const dispatch = useDispatch();
    const { companies } = useSelector((store) => store.companies);
    const [newsData, setNewsData] = useState({
        company_Id: null,
        category: "Short News",
        importance: true,
    });
    const [progress, setProgress] = useState(false);
    const [blocks, setBlocks] = useState([]);
    
    const ejInstanceRef = useRef();
    useEffect(() => {
        dispatch(getCompaniesFun());

        // if (ejInstanceRef.current === null) {
            const DEFAULT_DATA = {
                "time": new Date().getTime(),
                "blocks": []
            }
            initEditor({ DEFAULT_INITIAL_DATA: DEFAULT_DATA, handleChange, ejInstanceRef });
            
            console.log({ editorJs: "initialized Init Editor" });
        // };

        console.log({ editorJs: "Invoked EditorJs component", ejInstanceRef })

        return () => {
            ejInstanceRef?.current?.destroy();
            ejInstanceRef.current = null;
        };
    }, []);

    const handleChange = (data) => {
        setBlocks(data.blocks)
    };
    
    const setCompany_Id = (ele, value) => {

        setNewsData({
            ...newsData,
            "company_Id": value.id
        });
    };

    const addNews = async (event) => {
        event.preventDefault();
        console.log({
            ...newsData,
            blocks
        });
        return;

        if (!blocks.length) {
            return alert("News content can't be empty")
        };

        if (!newsData.company_Id) {
            return alert("Select the company");
        };

        const res = await fetch("https://api.sovrenn.com/news/new", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            },
            body: JSON.stringify({
                ...newsData,
                blocks
            })
        })

        const data = await res.json();

        if (res.ok) {
            setNewsData({
                company_Id: null,
            })
            alert("News article added Successfully");
        }
        else {
            alert("Error occurred while posting news article");
        }
    };

    return (
        <div className={`${commonStyles.MainContainer} ${styles.CreateMainContainer}`}>
            <OverlayCircularProgress loading={progress} />

            <div className={commonStyles.headerDiv}>
                <h2>Add News Article</h2>

                <div>
                    <Button disabled={blocks.length ? false : true} color="secondary" variant="outlined" startIcon={<ArrowUpward />} onClick={addNews} sx={{ width: "150px" }}
                    >Post</Button>
                </div>
            </div>

            <form onSubmit={addNews}>
                <div id={styles.firstChildDiv}>
                    <div className={styles.inputDiv}>
                        <label htmlFor="company_Id">Select Company</label>
                        <Autocomplete
                            fullWidth
                            size="medium"
                            disablePortal
                            id="company_Id"
                            name="company_Id"
                            options={companies}
                            sx={{ width: 600 }}
                            onChange={setCompany_Id}
                            getOptionSelected={(option, value) => option.value === value.value}
                            renderInput={(params) => <TextField required {...params} label="Company" />}
                        />
                    </div>
                </div>
            </form>

            <div className={styles.inputDiv}>
                <label htmlFor="company_Id">News Content</label>

                <div style={{ minHeight: "500px", border: "1px solid gray" }}>
                    {/* <EditorComponent DEFAULT_INITIAL_DATA={{
                        "time": new Date().getTime(),
                        "blocks": []
                    }} handleChange={handleChange}
                    /> */}

                    <div id="editorjs"></div>
                </div>
            </div>
        </div>
    )
}

export default AddNewsByBlocks