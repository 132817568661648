import { useDispatch, useSelector } from "react-redux";
import commonStyles from "../../styles/Common.module.css"
import styles from "./styles/Pulse.module.css";
import { useEffect, useState } from "react";
import { getMacroListFun, setMacroList } from "../../Redux/Macro/action";
import tableStyles from "../Users/styles/Users.module.css";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TablePagination } from "@mui/material";


const Macro = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [macroItemUpdateData, setMacroItemUpdateData] = useState({
        title: "",
        id: ""
    });
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [totalDocuments, setTotalDocuments] = useState(0);

    const page_no = searchParams.get("page_no");
    const page_size = searchParams.get("page_size");

    useEffect(() => {

        getPulseListFun(page_no, page_size)

        return () => {

        }
    }, [page_no, page_size]);

    const getPulseListFun = async (page_no, page_size) => {
        try {
            const res = await fetch(`https://api.sovrenn.com/corporate-updates/all?page=${page_no + 1 || 1}&page_size=${page_size || 100}`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("sov_user")
                }
            });

            const data = await res.json();

            if (res.ok) {
                setData(data.data);
                setTotalDocuments(data.pagination.total_documents)
            };
        }
        catch (err) {
            console.log({ err });
        };
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        changePath(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
        changePath(0, event.target.value);
    };

    const changePath = (page_no, page_size) => {
        navigate(`/pulse?page_no=${page_no}&page_size=${page_size}`);
    };

    return (
        <div className={commonStyles.MainContainer}>

            <div className={commonStyles.headerDiv}>
                <h1>Sovrenn Pulse</h1>
            </div>

            <table id={styles.macroListContainer}>
                <thead id={styles.macroListHead}>
                    <tr>
                        <th>Sr. No.</th>
                        <th>Date</th>
                        <th>Title</th>
                    </tr>
                </thead>

                <tbody>
                    {data.map((ele, ind) => {
                        return <tr className={styles.macroItem} key={ind}>
                            <td>{ele.company_name}</td>
                            <td>{moment(ele.news_date).format("Do MMM YY, h:mm:ss a")}</td>
                            <td>{ele.ai_summary}</td>
                        </tr>
                    })}
                </tbody>
            </table>

            <TablePagination
                component="div"
                count={totalDocuments}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[100, 200, 500]}
            />
        </div>
    )
}

export default Macro