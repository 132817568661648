export const GET_PARENT_BUCKETS = "GET_PARENT_BUCKETS";
export const GET_PARENT_DATA = "GET_PARENT_DATA";
export const GET_CHILD_BUCKETS = "GET_CHILD_BUCKETS";
export const GET_CHILD_DATA = "GET_CHILD_DATA";
export const SET_LOADING_NESTED = "SET_LOADING_NESTED";

const getParentBuckets = (payload) => {
    return ({
        type: GET_PARENT_BUCKETS,
        payload
    });
};

const  getParentBucketData = (payload) => {
    return ({
        type: GET_PARENT_DATA,
        payload
    });
};

const getChildBuckets = (payload) => {
    return ({
        type: GET_CHILD_BUCKETS,
        payload
    });
};

const getChildData = (payload) => {
    return ({
        type: GET_CHILD_DATA,
        payload
    });
};

const setLoading = () => {
    return ({
        type: SET_LOADING_NESTED
    });
};

export const getParentBucketsFun = () => async (dispatch) => {
    dispatch(setLoading());

    const res = await fetch("https://api.sovrenn.com/parent-buckets");

    const data = await res.json();

    if (res.ok) {
        dispatch(getParentBuckets(data.buckets));
    };
    return;
};

export const getParentBucketDataFun = (bucket_name) => async (dispatch) => {
    dispatch(setLoading());

    const res = await fetch(`https://api.sovrenn.com/parent-buckets/${bucket_name}`);

    const data = await res.json();

    if (res.ok) {
        dispatch(getParentBucketData(data.buckets));
    };
    return;
};

export const getChildBucketsFun = () => async (dispatch) => {
    dispatch(setLoading());

    const res = await fetch(`https://api.sovrenn.com/child-buckets`);

    const data = await res.json();

    if (res.ok) {
        dispatch(getChildBuckets(data.buckets));
    };
    return;
};

export const getChildDataFun = (bucket_name) => async (dispatch) => {
    dispatch(setLoading());

    const res = await fetch(`https://api.sovrenn.com/child-buckets/${bucket_name}`);

    const data = await res.json();

    if (res.ok) {
        dispatch(getChildData(data.bucket));
    };
    return;
};
