export const GET_SECTORS = "GET_SECTORS";


const getSectors = (payload) => {
    return {
        type: GET_SECTORS,
        payload
    };
};


export const getSectorsDataFun = () => async(dispatch) => {
    const data = await fetch("https://api.sovrenn.com/sectors", {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("sov_user")
        }
    })
    .then((res) => res.json());

    if(data.success) {
        dispatch(getSectors(data.sectors));
    };
};