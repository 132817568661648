import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const OverlayCircularProgress = ({ loading }) => {
    return (
        <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="rgba(255, 255, 255, 0.7)" // Semi-transparent white background
            zIndex={1000} // Adjust the zIndex as needed
            visibility={loading ? 'visible' : 'hidden'} // Hide when not loading
        >
            {loading && <CircularProgress />}
        </Box>
    );
};

export default OverlayCircularProgress;
