import { IS_LOADING, SET_IPO_LIST } from "./action";

const init = { ipo_articles: [], is_loading: true };

export const ipoReducer = (store = init, { type, payload }) => {

    switch (type) {
        case SET_IPO_LIST: {
            return {
                ...store,
                ipo_articles: payload,
                is_loading: false
            };
        };

        case IS_LOADING: {
            return {
                ...store,
                is_loading: true
            };
        };

        default: {
            return store;
        };
    };
};