import { GET_USER, SET_LOADING, USER_ACCESS, USER_DETAILS } from "./action";

const init = { users: [], userAccess: {}, selectedUser: {}, is_loading: true, total_documents: 0};


export const userReducer = (store = init, {type, payload}) => {

    switch(type) {
        case GET_USER: {
            return {
                ...store,
                users: payload.userList,
                total_documents: payload.totalDocuments,
            };
        };

        case USER_ACCESS: {
            return {
                ...store,
                userAccess: payload
            };
        };

        case USER_DETAILS: {
            return {
                ...store,
                selectedUser: payload,
                is_loading: false
            };
        };

        case SET_LOADING: {
            return {
                ...store,
                is_loading: true
            }
        }

        default: {
            return store;
        };
    };
};
