import { Add, Delete, Edit } from "@mui/icons-material";
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Modal, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getBucketDataFun } from "../../Redux/bucket/action";
import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/BucketDetails.module.css";
import modalStyles from "../../styles/ModalStyle.module.css";
import { useState } from "react";
import { getCompaniesFun } from "../../Redux/company/action";


const BucketDetails = () => {
  const dispatch = useDispatch();
  const { title } = useParams();
  const { bucketData, isLoading } = useSelector((store) => store.bucket);
  const { companies } = useSelector((store) => store.companies);
  const [modalOpen, setModalOpen] = useState(false);
  const [companyData, setCompanyData] = useState({
    company: "",
    remark: "",
    date: ""
  });

  const [remarkModal, setRemarkModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({
    id: "",
    label: "",
    remark: "",
    date: ""
  });

  useEffect(() => {
    dispatch(getBucketDataFun(title));
    dispatch(getCompaniesFun())

    return () => {

    }
  }, []);

  const handleUpdate = async (event) => {
    event.preventDefault();

    setModalOpen(false);
    if (!companyData.company.length || !companyData.remark.length) return;


    const res = await fetch(`https://api.sovrenn.com/buckets/${bucketData._id}`, {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("sov_user")
      },
      body: JSON.stringify(companyData)
    });

    const data = await res.json();

    if (res.ok) {
      dispatch(getBucketDataFun(title));
    };

    alert(data?.message || data?.error);

    return;
  };

  const removeCompany = async () => {
    setModal(false);
    const res = await fetch(`https://api.sovrenn.com/buckets/${title}/${selectedCompany.id}`, {
      method: "PATCH",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("sov_user")
      }
    });

    const data = await res.json();

    if (res.ok) {
      dispatch(getBucketDataFun(title));
      alert("Removed a company successfully");
      return;
    };

    alert("Something went wrong!");
    return;
  }

  const handleUpdateRemark = async () => {
    setRemarkModal(false);

    const res = await fetch(`https://api.sovrenn.com/buckets/remark/${title}/${selectedCompany.id}`, {
      method: "PATCH",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("sov_user"),
        "Content-type": "application/json"
      },
      body: JSON.stringify({
        remark: selectedCompany.remark,
        date: selectedCompany.date
      })
    });

    const data = await res.json();

    if (res.ok) {
      dispatch(getBucketDataFun(title));
      alert("Updated companies remark successfully!")
      return;
    }

    alert("Something went wrong!");
    return;
  }

  const handleUpdateFree = async (body) => {

    const res = await fetch(`https://api.sovrenn.com/buckets/${bucketData._id}`, {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("sov_user")
      },
      body: JSON.stringify(body)
    });

    const data = await res.json();

    if (res.ok) {
      alert(data.basket.avail_free ? "Made this bucket free" : "This bucket is no longer available for free.")
    }
    else {
      alert("Something Went wrong");
    };

    dispatch(getBucketDataFun(title));
    return;
  };

  if (isLoading) {
    return (
      <div className={commonStyles.MainContainer}>

        <div className={commonStyles.headerDiv}>
          <h1>{title}</h1>

          <Button color="secondary" variant="outlined" startIcon={<Add />} sx={{ width: "200px" }}>Add Company</Button>
        </div>

        <Box sx={{ display: 'flex', width: 100, margin: "50px auto" }}>
          <CircularProgress />
        </Box>
      </div>
    )
  }

  return (
    <div className={commonStyles.MainContainer}>

      <div className={commonStyles.headerDiv}>
        <h1>{title}</h1>

        <div>
          <Button color={bucketData.avail_free ? "error" : "success"} variant="outlined" sx={{ width: "200px" }} onClick={() => {
            handleUpdateFree(
              {
                avail_free: !bucketData.avail_free
              }
            );
          }}>{bucketData.avail_free ? "Remove Free" : "Make It Free"}</Button>

          <Button color="secondary" variant="outlined" startIcon={<Add />} sx={{ width: "200px" }} onClick={() => {
            setModalOpen(true)
          }}>Add Company</Button>
        </div>
      </div>

      <table id={styles.companyListContainer}>
        <thead id={styles.companyListHead}>
          <tr>
            <th>Sr. No.</th>
            <th>Company</th>
            <th>Market Cap</th>
            <th>TTM PE</th>
            <th>Comment</th>
            <th></th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {bucketData?.companies?.length ?
            (
              bucketData?.companies?.map((ele, ind) => {
                return <tr className={styles.companyCard} key={ele._id}>
                  <td>{ind + 1}.</td>
                  <td>{ele.company.company_name}</td>
                  <td>{ele.company.market_cap}</td>
                  <td>{ele.company.ttm_pe}x</td>
                  <td>{ele.remark}</td>
                  <td>
                    <IconButton className={styles.editButton}
                      onClick={() => {
                        setRemarkModal(true);
                        console.log(ele)
                        setSelectedCompany({
                          label: ele.company.company_name,
                          id: ele.company._id,
                          remark: ele.remark,
                          date: ele.date
                        })
                      }}>
                      <Edit />
                    </IconButton>
                  </td>
                  <td>
                    <IconButton className={styles.editButton} onClick={() => {
                      setModal(true);
                      setSelectedCompany({
                        label: ele.company.company_name,
                        id: ele.company._id,
                        remark: ele.remark,
                        date: ele.date
                      })
                    }}>
                      <Delete />
                    </IconButton>
                  </td>
                </tr>
              })
            )
            :
            ""
          }
        </tbody>
      </table>


      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box className={modalStyles.InfoModal}>
          <h3>Select Company</h3>

          <Autocomplete
            sx={{ margin: "20px 0" }}
            fullWidth
            size="medium"
            disablePortal
            id="company_Id"
            name="company_Id"
            options={companies}
            onChange={(ele, value) => {
              setCompanyData({
                ...companyData,
                company: value.id
              })
            }}
            getOptionSelected={(option, value) => option.value === value.value}
            renderInput={(params) => <TextField required {...params} label="Company" />}
          />

          <TextField
            id="outlined-multiline-static"
            label="Remark"
            multiline
            rows={4}
            fullWidth
            sx={{ fontSize: "small" }}
            required
            onChange={(ele) => setCompanyData({
              ...companyData,
              remark: ele.target.value
            })}
            value={companyData.remark}
          />


          <input type="date" style={{ width: "100%", height: "40px", padding: "10px", fontSize: "large", margin: "20px 0" }} required onChange={(ele) => setCompanyData({
            ...companyData,
            date: ele.target.value
          })} />

          <button className={modalStyles.submitBtn} onClick={handleUpdate}>Add</button>
        </Box>
      </Modal>

      <Modal open={remarkModal} onClose={() => setRemarkModal(false)}>
        <Box className={modalStyles.InfoModal}>
          <h3>Edit Remark and Date</h3>

          <TextField
            id="outlined-multiline-static"
            label="Company"
            fullWidth
            sx={{ margin: "20px 0", fontSize: "small" }}
            disabled
            value={selectedCompany.label}
          />

          <TextField
            id="outlined-multiline-static"
            label="Remark"
            multiline
            rows={4}
            fullWidth
            sx={{ fontSize: "small" }}
            required
            onChange={(ele) => setSelectedCompany({
              ...selectedCompany,
              remark: ele.target.value
            })}
            value={selectedCompany.remark}
          />

          <input value={selectedCompany.date} type="date" style={{ width: "100%", height: "40px", padding: "10px", fontSize: "large", margin: "20px 0" }} required onChange={(ele) => setSelectedCompany({
            ...selectedCompany,
            date: ele.target.value
          })} />

          <button className={modalStyles.submitBtn} onClick={handleUpdateRemark}>Update</button>
        </Box>
      </Modal>

      <Dialog open={modal}>
        <DialogTitle sx={{ fontWeight: "bolder" }}>Confirmation</DialogTitle>
        <DialogContent >
          <DialogContentText sx={{ color: "black", fontSize: "large" }}>
            Do you really want to remove this company from the bucket
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setModal(false);
          }}>Cancel</Button>
          <Button color='error' onClick={removeCompany}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default BucketDetails