import { GET_BATCHES, GET_USERS_LIST } from "./action";

const init = {
    boughtOnlineBatches: [],
    manualAccessBatches: [],
    usersList: [],
    fullAccess: 0,
    sdAccess: 0,
    newsAccess: 0,
    noSessions: 0
};

export const trialReducer = (store = init, { type, payload }) => {

    switch (type) {
        case GET_BATCHES: {
            return {
                ...store,
                boughtOnlineBatches: payload.boughtOnlineBatches,
                manualAccessBatches: payload.manualAccessBatches
            };
        };

        case GET_USERS_LIST: {

            return {
                ...store,
                ...payload
            }
        };

        default: {
            return store
        };
    };
};