export const GET_BUCKETS = "GET_BUCKETS";
export const BUCKET_DATA = "BUCKET_DATA";
export const LOADING = "LOADING";
export const GET_STAR_BUCKETS = "GET_STAR_BUCKETS";

const getBuckets = (payload) => {
    return {
        type: GET_BUCKETS,
        payload
    };
};

const getBucketData = (payload) => {
    return {
        type: BUCKET_DATA,
        payload
    };
};

const setIsLoading = (payload) => {
    return {
        type: LOADING,
        payload
    };
};

const getStarBuckets = (payload) => {
    return {
        type: GET_STAR_BUCKETS,
        payload
    };
};


export const getBucketsFun = () => async (dispatch) => {
    dispatch(setIsLoading(true))
    const res = await fetch("https://api.sovrenn.com/buckets");

    const data = await res.json();

    if (res.ok) {
        dispatch(getBuckets(data));
        return;
    };

    dispatch(getBuckets([]));
    return;
};

export const getBucketDataFun = (slug) => async (dispatch) => {
    dispatch(setIsLoading(true))
    const res = await fetch(`https://api.sovrenn.com/buckets/${slug}`, {
        method: "POST"
    });

    const data = await res.json();

    if (res.ok) {
        dispatch(getBucketData(data.data));
        return;
    };

    dispatch(getBucketData({}));
    return;
};
