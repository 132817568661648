import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom";
import commonStyles from "../../styles/Common.module.css"
import styles from "../News/styles/AddNews.module.css";
import OverlayCircularProgress from "../Utils/OverlayCircularProgress";
import { Button } from "@mui/material";
import EditorComponent from "../Editor/EditorComponent";
import { Add } from "@mui/icons-material";
import { initEditor } from "../../utils/initEditor";


const AddDiscoveryData = () => {
    const { id } = useParams();
    const ejInstanceRef = useRef();
    const [companyData, setCompanyData] = useState({
        company_name: "",
        id: "",
        content: []
    });
    const [newContent, setNewContent] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(false);

    useEffect(() => {
        getCompanyData();

        return () => {

        }
    }, []);

    useEffect(() => {

        // if (ejInstanceRef.current === null && companyData.content.length) {

            const DEFAULT_DATA = {
                "time": new Date().getTime(),
                "blocks": companyData.content
            };

            initEditor({ DEFAULT_INITIAL_DATA: DEFAULT_DATA, handleChange, ejInstanceRef });
        // };

        return () => {
            ejInstanceRef?.current?.destroy();
            ejInstanceRef.current = null;
        };
    }, [companyData]);


    const getCompanyData = async () => {
        setIsLoading(true);

        const res = await fetch(`https://api.sovrenn.com/company/${id}`, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            }
        });

        const data = await res.json();

        if (res.ok) {
            setCompanyData({
                company_name: data.company.company_name,
                id: data.company._id,
                content: data.company?.discovery_content || []
            });
        };

        setIsLoading(false);
        return;
    };

    const handleChange = (data) => {
        setNewContent(data.blocks);
    };

    const handleUpdate = async () => {
        try {
            if (!newContent.length) {
                return alert("IPO Data is not updated");
            };

            setProgress(true);

            const res = await fetch(`https://api.sovrenn.com/company/discovery-data/${id}`, {
                method: "PATCH",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("sov_user"),
                    "Content-type": "application/json"
                },
                body: JSON.stringify({
                    discovery_content: newContent
                })
            });

            const data = await res.json();

            setProgress(false);

            if (res.ok) {
                alert("Updated data Successfully");
                return;
            };

            alert("Something went wrong");
            return;
        }
        catch (err) {

            console.log({ err });
            alert("Something went wrong");
            return;
        };
    };

    if (isLoading) {
        return <div className={commonStyles.MainContainer}>

            <div className={commonStyles.headerDiv}>
                <h2>...loading</h2>
            </div>
        </div>
    };

    return (

        <div className={commonStyles.MainContainer}>
            <OverlayCircularProgress loading={progress} />

            <div className={commonStyles.headerDiv}>
                <h2>Discovery Data for - {companyData.company_name}</h2>

                <div>
                    <Button onClick={handleUpdate} disabled={newContent.length ? companyData.content === newContent : true} color="secondary" variant="outlined" startIcon={<Add />} sx={{ width: "150px" }}
                    >Save</Button>
                </div>
            </div>


            {/* <EditorComponent DEFAULT_INITIAL_DATA={{
                "time": new Date().getTime(),
                "blocks": companyData.content
            }} handleChange={handleChange}
            /> */}

            <div id="editorjs"></div>

        </div>
    )
}

export default AddDiscoveryData