export const GET_USER = "GET_USER";
export const USER_ACCESS = "USER_ACCESS";
export const USER_DETAILS = "USER_DETAILS";
export const SET_LOADING = "SET_LOADING";

export const getUser = (payload) => {
    return {
        type: GET_USER,
        payload
    };
};

export const getUserAccess = (payload) => {
    return {
        type: USER_ACCESS,
        payload
    };
};

export const setUserDetails = (payload) => {
    return {
        type: USER_DETAILS,
        payload
    };
};

export const setLoading = () => {
    return {
        type: SET_LOADING,
    }
}

export const getUserFun = ({email, phone_number, first_name, last_name}) => async(dispatch) => {
    const res = await fetch("https://api.sovrenn.com/manual/get-user", {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user"),
            "Content-type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
            email,
            phone_number,
            first_name,
            last_name
        })
    });

    const user = await res.json();

    if (user.success) {
        dispatch(getUser({ userList: user.user, totalDocuments: user.user.length}));
    };
};

export const getUsersListFun = (type, page, rowsPerPage) => async (dispatch) => {
    dispatch(setLoading());

    let page_no;
    if(page) {
        page_no = +page + 1;
    }
    else {
        page_no = 1
    };

    let page_size;

    if (rowsPerPage) {
        page_size = rowsPerPage;
    }
    else {
        page_size = 1000
    };

    const res = await fetch(`https://api.sovrenn.com/user/list?type=${type}&page=${page_no}&page_size=${page_size}`, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user"),
        }
    });

    const user = await res.json();

    if (user.success) {
        dispatch(getUser(user));
    };
};

export const getUserAccessFun = () => async (dispatch) => {
    const res = await fetch("https://api.sovrenn.com/user/user-access", {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user"),
        }
    });

    const access = await res.json();

    if(res.ok) {
        dispatch(getUserAccess(access.access))
    }
}

export const getUserDetails = (id) => async(dispatch) => {
    const res = await fetch(`https://api.sovrenn.com/user/${id}`, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user"),
        }
    });

    const user = await res.json();

    if (user.success) {
        dispatch(setUserDetails(user));
    };
}