import styles from "../Buckets/styles/CreateBucket.module.css";
import commonStyles from "../../styles/Common.module.css"
import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, useTheme } from "@mui/material";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCompaniesFun } from "../../Redux/company/action";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


const CreateChildBucket = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [companyNames, setCompanyNames] = useState([]);
    const { companies } = useSelector((store) => store.companies);

    const investorNameRef = useRef();
    const aboutRef = useRef();

    useEffect(() => {
        dispatch(getCompaniesFun());

        return () => {

        };
    }, []);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setCompanyNames(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        const companyList = [];

        for (let i = 0; i < companies.length; i++) {
            for (let j = 0; j < companyNames.length; j++) {
                if (companies[i]["label"] === companyNames[j]) {
                    companyList.push({
                        company: companies[i]["id"],
                        remark: "NA",
                        date: new Date().toISOString().split("T")[0]
                    });
                };
            };
        };

        const bodyData = {
            bucket_name: investorNameRef.current.value,
            about: aboutRef.current.value,
            companies: companyList,
        };

        const res = await fetch("https://api.sovrenn.com/child-buckets", {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                "Authorization": localStorage.getItem("sov_user")
            },
            body: JSON.stringify(bodyData)
        });

        const data = await res.json();

        if (data.success) {
            alert("New Bucket has been created successfully!");
            
            investorNameRef.current.value = "";
            aboutRef.current.value = "";
            return;
        }

        alert("Something went wrong, Bucket creation failed.");
        return;
    }

    return (
        <div className={`${commonStyles.MainContainer} ${styles.CreateMainContainer}`}>
            <div className={commonStyles.headerDiv}>
                <h1>Create Child Bucket</h1>
            </div>

            <form onSubmit={handleSubmit}>
                <div id={styles.firstChildDiv}>
                    <div className={styles.inputDiv} style={{ marginBottom: "20px" }}>
                        <label htmlFor="bucket_name">Bucket Title</label>
                        <input name="bucket_name" id="bucket_name" placeholder="Enter Name" type="text" ref={investorNameRef} required style={{ height: "50px" }} />


                    </div>

                    <div className={styles.inputDiv} >
                        <label htmlFor="select-companies">Select Companies</label>
                        <FormControl fullWidth id="select-companies">
                            <InputLabel id="demo-multiple-chip-label">Companies</InputLabel>
                            <Select
                                style={{ minHeight: "50px" }}
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={companyNames}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Companies" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {companies.map((name) => (
                                    <MenuItem
                                        key={name.label}
                                        value={name.label}
                                        style={getStyles(name, companyNames, theme)}
                                    >
                                        {name.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>






                <div className={styles.inputDiv}>
                    <label htmlFor="about">About</label>
                    <textarea name="about" id="about" placeholder="Enter description" ref={aboutRef} required></textarea>
                </div>
                <button type="submit">Create Bucket</button>
            </form>
        </div>
    )
}

export default CreateChildBucket