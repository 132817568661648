import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/TrialBatch.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getTrialBatchesFunc } from "../../Redux/Trial/action";
import { Link } from "react-router-dom";
import { Email } from "@mui/icons-material";


const numberToAlphabeticString = (num) => {
    let result = '';
    while (num > 0) {
        let remainder = (num - 1) % 26;
        result = String.fromCharCode(65 + remainder) + result;
        num = Math.floor((num - 1) / 26);
    }
    return result;
};

const TrialBatch = () => {
    const dispatch = useDispatch();
    const { boughtOnlineBatches, manualAccessBatches } = useSelector((store) => store.trial);

    const [dialog, setDialog] = useState(false);
    const [sendMailData, setSendMailData] = useState({
        trialBatch: 0,
        whatsappLink: ""
    });

    useEffect(() => {
        dispatch(getTrialBatchesFunc())

        return () => {

        };
    }, []);

    const handleChange = (ele) => {
        const { name, value } = ele.target;

        setSendMailData({
            ...sendMailData,
            [name]: value
        });
    };

    const handleSendMail = async (event) => {
        event.preventDefault();

        const res = await fetch("https://api.sovrenn.com/analytics/send-trial-mail", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user"),
                "content-type": "application/json"
            },
            body: JSON.stringify(sendMailData)
        });

        const data = await res.json();

        if (res.status === 500) {
            alert(data.error);
            return;
        };

        if (res.status === 200) {
            setDialog(false);
        };

        alert(data.message);

        return;
    };

    return (
        <div className={`${styles.MainContainer}`} >
            <div className={commonStyles.headerDiv}>
                <h1>Trial Batches</h1>

                <Button color="secondary" variant="outlined" fullWidth startIcon={<Email />} sx={{ width: "250px" }} onClick={() => {
                    setDialog(true);
                }}>Send Whatsapp Link</Button>
            </div>

            <div id={styles.dataContainer}>
                <div className={styles.batchesMainDiv}>
                    <h2>Purchased Online</h2>

                    <div className={styles.batchesContainer}>
                        {boughtOnlineBatches.map((ele, ind) => {
                            return <div className={styles.batchCard}>
                                <h2>Batch {numberToAlphabeticString([ele.batch])}</h2>
                                <h3>Total Users: <span>
                                    {ele.total}
                                </span></h3>

                                <Link to={`/users/trial/list?batch=${ele?.batch || 0}`}>
                                    View List
                                </Link>
                            </div>
                        })}
                    </div>
                </div>

                <div className={styles.batchesMainDiv}>
                    <h2>Manual Access</h2>

                    <div className={styles.batchesContainer}>
                        {manualAccessBatches.map((ele, ind) => {
                            return <div className={styles.batchCard}>
                                <h2>Batch {numberToAlphabeticString(ind + 1)}</h2>
                                <h3>Total Users: <span>
                                    {ele.total}
                                </span></h3>

                                <Link to={`/users/trial/list?batch=0`}>
                                    View List
                                </Link>
                            </div>
                        })}
                    </div>
                </div>
            </div>

            <Dialog open={dialog} onClose={() => setDialog(false)}>
                <DialogTitle sx={{ fontWeight: "bolder", paddingBottom: "0" }}>Add Whatsapp Link and Select Batch</DialogTitle>
                <DialogContent sx={{ width: "500px" }}>

                    <TextField onChange={handleChange} name="whatsappLink" size='small' sx={{ marginTop: "20px" }} id="outlined-basic" label="Whatsapp Link" variant="outlined" required fullWidth />

                    <FormControl sx={{ marginTop: "20px" }} size='small' required fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Trial Batch</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Select Trial Batch"
                            name="trialBatch"
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>

                            {boughtOnlineBatches.map((ele, ind) => {
                                return <MenuItem value={ele.batch}>Trial {numberToAlphabeticString(ele.batch)}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={() => {
                        setDialog(false);
                    }}>Cancel</Button>
                    <Button onClick={handleSendMail}>Send Mail</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default TrialBatch