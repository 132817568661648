import { GET_QUESTIONS } from "./action";

const init = {
    questions: [],
    is_loading: true,
    is_error: false,
    pagination: {
        page: 0,
        page_size: 0,
        total_pages: 0,
        total_documents: 0
    }
};


export const questionsReducer = (store = init, { type, payload }) => {
    switch (type) {
        case GET_QUESTIONS: {
            return {
                ...store,
                questions: payload.data,
                pagination: {
                    ...payload.pagination,
                    page: payload.pagination.page - 1
                },
            };
        };

        default: {
            return store;
        };
    };
};
