import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/Referrals.module.css";
import tableStyles from "../Users/styles/Users.module.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const Referrals = () => {
    const [referralsList, setReferralsList] = useState([]);

    useEffect(() => {
        getList();

        return () => {

        };
    }, []);


    const getList = async () => {
        const res = await fetch("https://api.sovrenn.com/referral", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            }
        });

        const data = await res.json();

        if (res.ok) {
            setReferralsList(data.referrals);
        };
        return;
    }

    return (
        <div className={`${styles.MainContainer}`} >
            <div className={commonStyles.headerDiv}>
                <h1>Referrals List</h1>

            </div>

            <table id={styles.userListContainer}>
                <thead id={styles.userListHead}>
                    <tr >
                        <th>Sr. No.</th>
                        <th >User Id</th>
                        <th >Reffered By</th>
                        <th>Referred Name</th>
                        <th>Phone Number</th>
                        <th>Referred Date</th>
                    </tr>
                </thead>

                <tbody>
                    {referralsList.map((ele, ind) => {
                        return <tr className={tableStyles.userCard} key={ele._id}>
                            <td>{ind + 1}</td>
                            <td >{ele.referrer_id.custom_Id}</td>

                            <td>
                                <Link to={`/users/${ele.referrer_id._id}`}>{ele.referrer_id.first_name} {ele.referrer_id.last_name}
                                </Link>
                            </td>
                            <td>{ele.name}</td>
                            <td>{ele.phone_number}</td>
                            <td>{moment(ele.createdAt).format("Do MMM YY")}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default Referrals