import { GET_DATA, GET_COMPANIES, IS_LOADING, SORT_COMPANIES } from "./action";

const init = { companies_list: [], companies: [], sortBy: "", sortOrder: false, isSorted: false, is_loading: true };

export const companyReducer = (store = init, { type, payload }) => {
    switch (type) {
        case GET_COMPANIES: {
            return {
                ...store,
                companies: payload,
            };
        };

        case GET_DATA: {
            return {
                ...store,
                companies_list: payload,
                is_loading: false
            };
        };

        case IS_LOADING: {
            return {
                ...store,
                is_loading: payload
            };
        };

        case SORT_COMPANIES: {
            const { sortBy, sortOrder } = payload;

            const sortedCompanies = [...store.companies_list].sort((a, b) => {
                let result;
                if (sortBy === 'isin') {
                    if (sortOrder) {
                        if (a.isin.includes("ISIN Not available") && !b.isin.includes("ISIN Not available")) {
                            result = -1;
                        } else if (!a.isin.includes("ISIN Not available") && b.isin.includes("ISIN Not available")) {
                            result = 1;
                        } else {
                            result = 0;
                        }
                    } else {
                        if (a.isin.includes("ISIN Not available") && !b.isin.includes("ISIN Not available")) {
                            result = 1;
                        } else if (!a.isin.includes("ISIN Not available") && b.isin.includes("ISIN Not available")) {
                            result = -1;
                        } else {
                            result = 0;
                        }
                    }
                }
                if (sortBy === 'verified_on') {
                    if (sortOrder) {
                        result = new Date(a[sortBy]) - new Date(b[sortBy]);
                    } else {
                        result = new Date(b[sortBy]) - new Date(a[sortBy]);
                    }
                }
                else {
                    if (sortOrder) {
                        result = a[sortBy] - b[sortBy];
                    } else {
                        result = b[sortBy] - a[sortBy];
                    }
                }
                return result;
            });

            return {
                ...store,
                sortBy,
                sortOrder,
                isSorted: true,
                companies_list: sortedCompanies,
            };
        }

        default: {
            return store
        }
    }
}