import { Add, Delete, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCourseListFun } from "../../Redux/course/action";
import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/Courses.module.css";


export const Courses = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('running');
  const { active_list, completed_list } = useSelector((store) => store.course);

  useEffect(() => {
    dispatch(getCourseListFun());

    return () => {

    }
  }, []);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={commonStyles.MainContainer}>

      <div className={commonStyles.headerDiv}>
        <h1>Courses</h1>

        <Link to="/courses/create">
          <Button color="secondary" variant="outlined" startIcon={<Add />} fullWidth>Create Course</Button>
        </Link>
      </div>

      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          centered
        >
          <Tab value="running" label="Running" />
          <Tab value="completed" label="Completed" />
        </Tabs>
      </Box>


      {value === "running" ?
        <div id={styles.runningListDiv}>
          {active_list.map((ele) => {
            return <div key={ele._id} className={styles.courseCard}>
              <div className={styles.dateDiv}>
                <h3>Date: {ele.date}</h3>
                <h4>{ele.start_time} - {ele.end_time}</h4>
              </div>

              <div className={styles.dateDiv}>
                <h4>{ele.type}</h4>
                <h4>By {ele.trainer}</h4>
              </div>

              <div className={styles.dateDiv}>
                <h4>Total Slots: <i>{ele.total_slots}</i></h4>
                <h4>Slots Booked: <i>{ele.total_slots - ele.slots_available}</i></h4>
              </div>

              <h4>Status: Active</h4>

              <div className={styles.buttonsDiv}>
                <Link to={`/courses/${ele._id}`}>
                  <IconButton className={styles.editButton}>
                    <Edit />
                  </IconButton>
                </Link>

                <IconButton className={styles.editButton}>
                  <Delete />
                </IconButton>
              </div>
            </div>
          })}
        </div>
        :
        <div id={styles.runningListDiv}>
          {completed_list.map((ele) => {
            return <div key={ele._id} className={styles.courseCard}>
              <Link to={`/courses/${ele._id}`}>
                <div className={styles.dateDiv}>
                  <h3>Date: {ele.date}</h3>
                  <h4>{ele.start_time} - {ele.end_time}</h4>
                </div>
              </Link>

              <div className={styles.dateDiv}>
                <h4>{ele.type}</h4>
                <h4>By {ele.trainer}</h4>
              </div>

              <div className={styles.dateDiv}>
                <h4>Total Slots: <i>{ele.total_slots}</i></h4>
                <h4>Slots Booked: <i>{ele.total_slots - ele.slots_available}</i></h4>
              </div>

              <h4>Status: {ele.status}</h4>

              <div className={styles.buttonsDiv}>
                <Link to={`/courses/${ele._id}`}>
                  <IconButton className={styles.editButton}>
                    <Edit />
                  </IconButton>
                </Link>

                <IconButton className={styles.editButton}>
                  <Delete />
                </IconButton>
              </div>
            </div>
          })}
        </div>
      }
    </div>
  )
}
