import { useDispatch, useSelector } from "react-redux";
import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/Questions.module.css";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Modal, Radio, RadioGroup, TablePagination, TextField } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { getQuestionsFunc } from "../../Redux/Questions/action";
import blocksToHTML from "../../utils/blocksToHtml";
import { Add, Cancel, Close, Done, Edit } from "@mui/icons-material";
import { initEditor } from "../../utils/initEditor";

const Questions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { questions, pagination } = useSelector((store) => store.questions);
    const [searchParams, setSearchParams] = useSearchParams();
    const ejInstanceRef = useRef();

    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [updatedQuestion, setUpdatedQuestion] = useState(null);

    const page_no = searchParams.get("page_no");
    const page_size = searchParams.get("page_size");

    useEffect(() => {
        dispatch(getQuestionsFunc(page_no, page_size));

        return () => {

        };
    }, [page_no, page_size]);


    useEffect(() => {

        if (ejInstanceRef.current === null && selectedQuestion?.question_text?.length) {

            const DEFAULT_DATA = {
                "time": new Date().getTime(),
                "blocks": selectedQuestion?.question_text
            };

            initEditor({ DEFAULT_INITIAL_DATA: DEFAULT_DATA, handleChange: handleChangeQuestion, ejInstanceRef });
        };

        return () => {
            ejInstanceRef?.current?.destroy();
            ejInstanceRef.current = null;
        };
    }, [selectedQuestion?.question_text]);

    const handleChangeQuestion = (data) => {
        setUpdatedQuestion({
            ...selectedQuestion,
            question_text: data.blocks
        });
    };

    const handleOptionsTextChange = (item, id) => {
        const newOptions = selectedQuestion.options;

        newOptions.filter((ele) => {
            if (ele._id === id) {
                return ele.text = item.target.value
            }
        });

        setSelectedQuestion({
            ...selectedQuestion,
            options: newOptions
        });
    };

    const updateQuestion = async (event) => {
        event.preventDefault();

        if (!updatedQuestion) {
            setSelectedQuestion(null);
            setUpdatedQuestion(null);
            return;
        };

        const res = await fetch(`https://api.sovrenn.com/questions/${selectedQuestion._id}`, {
            method: "PATCH",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user"),
                "Content-type": "application/json"
            },
            body: JSON.stringify(updatedQuestion)
        });

        const data = await res.json();

        setSelectedQuestion(null);
        setUpdatedQuestion(null);
        dispatch(getQuestionsFunc(page_no, page_size));
        return;
    };

    const handleChangePage = (event, newPage) => {
        changePath(newPage, pagination.page_size);
    };

    const handleChangeRowsPerPage = (event) => {
        changePath(0, event.target.value);
    };

    const changePath = (page_no, page_size) => {
        navigate(`/questions?page_no=${page_no}&page_size=${page_size}`);
    };

    if (!questions.length) {
        return (
            <div className={commonStyles.MainContainer}>

                <div className={commonStyles.headerDiv}>
                    <h1>Questions</h1>

                </div>
                <Box sx={{ display: 'flex', justifyContent: "center", width: 100, margin: "100px auto" }}>
                    <CircularProgress />
                </Box>
            </div>
        )
    };

    return (
        <div className={`${styles.MainContainer}`} >
            <div className={commonStyles.headerDiv}>
                <h1>Questions</h1>

                <div >
                    <Link to="/questions/add">
                        <Button color="secondary" variant="outlined" startIcon={<Add />} fullWidth sx={{ width: "220px" }}>Add New Question</Button>
                    </Link>
                </div>
            </div>

            <div >
                <div id={styles.questionsContainer}>
                    {questions.map((ele, ind) => {
                        return selectedQuestion?._id === ele._id ? (
                            <div key={ele._id} className={styles.questionsCard}>
                                <div className={styles.editQuestionText}>
                                    <div style={{
                                        border: "1px solid gray",
                                        maxHeight: "200px",
                                        minHeight: "150px",
                                        overflowY: "auto",
                                        margin: "0px",
                                        padding: "0px",
                                        textAlign: "left",
                                        left: 0,
                                        width: "100%",
                                        fontSize: "larger"
                                    }} id="editorjs"></div>
                                </div>

                                <div>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={selectedQuestion.correct_answer}
                                            onChange={(ele) => {
                                                setSelectedQuestion({
                                                    ...selectedQuestion,
                                                    correct_answer: ele.target.value
                                                });
                                                setUpdatedQuestion({
                                                    ...selectedQuestion,
                                                    correct_answer: ele.target.value
                                                });
                                            }}
                                        >
                                            {selectedQuestion.options.map((item, ind) => {
                                                return (
                                                    <FormControlLabel
                                                        key={item._id}
                                                        value={item._id}
                                                        control={
                                                            <Radio
                                                                sx={{
                                                                    '& .MuiSvgIcon-root': {
                                                                        fontSize: 30,
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <TextField
                                                                value={item.text}
                                                                onChange={(e) => handleOptionsTextChange(e, item._id)} // Handle text change
                                                                variant="outlined"
                                                                size="small"
                                                                sx={{ fontSize: '22px' }}
                                                            />
                                                        }
                                                        sx={{
                                                            '& .MuiFormControlLabel-label': { fontSize: '20px' },
                                                        }}
                                                    />
                                                );
                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                </div>

                                <div className={styles.cardButtons}>
                                    <Button
                                        color="success"
                                        variant="outlined"
                                        startIcon={<Done />}
                                        fullWidth
                                        sx={{ width: '120px' }}
                                        onClick={updateQuestion}
                                    >
                                        Update
                                    </Button>

                                    <Button
                                        color="error"
                                        variant="outlined"
                                        startIcon={<Close />}
                                        fullWidth
                                        sx={{ width: '120px' }}
                                        onClick={() => setSelectedQuestion(null)}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div key={ele._id} className={styles.questionsCard}>
                                <div className={styles.questionText}>
                                    {blocksToHTML(ele.question_text)}
                                </div>

                                <div>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={ele.correct_answer}
                                        // onChange={handleChange}
                                        >
                                            {ele.options.map((item, ind) => {
                                                return (
                                                    <FormControlLabel
                                                        key={item._id}
                                                        value={item._id}
                                                        control={
                                                            <Radio
                                                                sx={{
                                                                    '& .MuiSvgIcon-root': {
                                                                        fontSize: 28,
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label={item.text}
                                                        sx={{
                                                            '& .MuiFormControlLabel-label': { fontSize: '18px' },
                                                        }}
                                                    />
                                                );
                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                </div>

                                <div className={styles.cardButtons}>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        startIcon={<Edit />}
                                        fullWidth
                                        sx={{ width: '120px' }}
                                        onClick={() => {
                                            setSelectedQuestion(ele)
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                </div>


                <TablePagination
                    component="div"
                    count={pagination.total_documents}
                    page={pagination.page}
                    onPageChange={handleChangePage}
                    rowsPerPage={pagination?.page_size || 50}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[25, 50, 100]}
                />
            </div>
        </div>
    )
};

export default Questions;