import { AUTH_ACTION, SET_USER } from "./action";

const init = {
    isAuth: false,
    user: {},
    token: ""
};

export const authReducer = (store = init, { type, payload }) => {

    switch (type) {
        case AUTH_ACTION: {
            return payload;
        };

        case SET_USER: {
            if (payload.data.success && payload.data?.user?.role?.includes("admin")) {
                return {
                    isAuth: payload.isAuth,
                    user: payload.data.user,
                    token: payload.token,
                }
            }
            else {
                return {
                    isAuth: false,
                    user: {},
                    token: ""
                };
            };
        };

        default: {
            return store
        };
    };
};