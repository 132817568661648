import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import moment from "moment/moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { primeOrdersFun } from "../../Redux/Prime/action";
import commonStyles from "../../styles/Common.module.css";
import styles from "../Users/styles/Users.module.css";

const PrimeOrders = () => {
    const dispatch = useDispatch();
    const { prime_order } = useSelector((store) => store.prime);

    useEffect(() => {
        dispatch(primeOrdersFun());

        return () => {

        };
    }, []);


    return (
        <div className={commonStyles.MainContainer}>

            <div className={commonStyles.headerDiv}>
                <h1>Prime Orders</h1>

                <div>

                    <Link to={"/analytics/prime"}>
                        <Button color="secondary" variant="outlined" sx={{ width: "180px", }}
                        >Analyse Orders</Button>
                    </Link>

                    <Link to={"/prime_research/orders/create-order"}>
                        <Button color="secondary" variant="outlined" sx={{ width: "180px" }}
                            startIcon={<Add />}
                        >Create Order</Button>
                    </Link>
                </div>
            </div>

            <table id={styles.userListContainer}>
                <thead id={styles.userListHead}>
                    <tr>
                        <th>Article</th>
                        <th>User</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Amount</th>
                        <th>Bought On</th>
                    </tr>
                </thead>

                <tbody>
                    {prime_order?.orders?.map((ele, ind) => {
                        return <tr className={styles.userCard} key={ele._id}>
                            <td>{ele.company_Id.company_name}</td>
                            <td ><Link to={`/users/${ele.user_Id._id}`}>
                                {ele.user_Id.first_name} {ele.user_Id.last_name}
                            </Link></td>
                            <td>{ele.user_Id.email}</td>
                            <td>{ele.user_Id.phone_number}</td>
                            <td>{ele.amount}</td>
                            <td>{moment(ele.createdAt).format("Do MMM YY")}</td>

                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default PrimeOrders