export const SET_PRIME_ORDERS = "SET_PRIME_ORDERS";
export const SET_PRIME_LIST = "SET_PRIME_LIST";
export const IS_LOADING = "IS_LOADING";

const setPrimeList = (payload) => {
    return {
        type: SET_PRIME_LIST,
        payload
    };
};

const setPrimeOrders = (payload) => {
    return {
        type: SET_PRIME_ORDERS,
        payload
    };
};

const isLoading = () => {
    return {
        type: IS_LOADING
    };
};


export const setPrimeListFun = () => async(dispatch) => {
    dispatch(isLoading());

    const res = await fetch("https://api.sovrenn.com/prime-research/all", {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    });

    const data = await res.json();

    if(res.ok) {
        dispatch(setPrimeList(data.list));
        return;
    };

    dispatch(setPrimeList([]));
};

export const primeOrdersFun = () => async(dispatch) => {
    dispatch(isLoading());

    const res = await fetch("https://api.sovrenn.com/prime-order", {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    });

    const data = await res.json();

    if(res.ok) {
        dispatch(setPrimeOrders(data.primeOrder));
        return;
    };
    dispatch(setPrimeOrders([]));
}