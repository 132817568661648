import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import commonStyles from "../../styles/Common.module.css";
import inputStyles from "./styles/CreateCourse.module.css";
import styles from "./styles/EnrollUser.module.css";
import tableStyles from "../Users/styles/Users.module.css";
import { getUserFun } from "../../Redux/Users/action";
import { useParams } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import modalStyles from "../../styles/ModalStyle.module.css";

const EnrollUser = () => {
    const dispatch = useDispatch();
    const { users } = useSelector((store) => store.users);
    const [checkData, setCheckData] = useState({
        email: "",
        phone_number: "",
        first_name: "",
        last_name: "",
    });
    const { id } = useParams();

    const [modalData, setModalData] = useState({
        user_Id: "",
        amount: "",
    })
    const [modalOpen, setModalOpen] = useState(false);

    const handleChange = (ele) => {
        const { id, value } = ele.target;

        setCheckData({
            ...checkData,
            [id]: value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        dispatch(getUserFun({ email: checkData.email, phone_number: checkData.phone_number, first_name: checkData.first_name, last_name: checkData.last_name }));
    }

    const handleEnroll = async (event) => {
        event.preventDefault();
        setModalOpen(false)
        
        const res = await fetch("https://api.sovrenn.com/manual/course-enroll", {
            method: "PATCH",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            },
            body: JSON.stringify({
                course_Id: id,
                user_Id: modalData.user_Id,
                amount: modalData.amount
            })
        });

        const data = await res.json();

        if(res.ok) {
            alert("Enrolled User Successfully!");

            dispatch(getUserFun({ email: checkData.email, phone_number: checkData.phone_number, first_name: checkData.first_name, last_name: checkData.last_name }));

            return;
        };

        alert("Something went wrong while enrolling the user.");
        return;
    }

    return (
        <div className={`${commonStyles.MainContainer} ${inputStyles.CreateMainContainer}`}>
            <h2>Enroll User</h2>

            <div id={inputStyles.formDiv}>
                <form action="" id={inputStyles.form} onSubmit={handleSubmit}>


                    <div id={inputStyles.secondChildDiv}>
                        <div className={inputStyles.inputDiv}>
                            <label htmlFor="email">
                                Email
                            </label>
                            <input type="email" id="email" name="email" onChange={handleChange} />
                        </div>
                        <div className={inputStyles.inputDiv}>
                            <label htmlFor="phone_number">
                                Phone Number
                            </label>
                            <input type="number" id="phone_number" name="phone_number" onChange={handleChange} />
                        </div>
                    </div>

                    <div id={inputStyles.secondChildDiv}>
                        <div className={inputStyles.inputDiv}>
                            <label htmlFor="first_name">
                                First Name
                            </label>
                            <input type="text" id="first_name" name="first_name" onChange={handleChange} />
                        </div>
                        <div className={inputStyles.inputDiv}>
                            <label htmlFor="last_name">
                                Last Name
                            </label>
                            <input type="text" id="last_name" name="last_name" onChange={handleChange} />
                        </div>
                    </div>

                    <button type="submit" style={{ marginTop: "0" }}>Find User</button>
                </form>
            </div>

            <hr />

            <table id={tableStyles.userListContainer}>
                <thead id={tableStyles.userListHead}>
                    <tr className={tableStyles.userCard}>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Country</th>
                        <th>Phone Number</th>
                        <th>State</th>
                        <th>Enroll</th>
                    </tr>
                </thead>

                <tbody>
                    {users.map((ele, ind) => {
                        return <tr className={tableStyles.userCard} key={ele._id}>
                            <td >
                                {ele.first_name} {ele.last_name}
                            </td>
                            <td>{ele.email}</td>
                            <td>{ele.country_code}</td>
                            <td>{ele.phone_number}</td>
                            <td>{ele.state}</td>
                            <td>{ele.course_Id.includes(id) ? <button>Enrolled</button> : <button onClick={() => {
                                setModalData({
                                    ...modalData,
                                    user_Id: ele._id
                                });
                                setModalOpen(true);
                            }}>Add</button>}</td>
                        </tr>
                    })}
                </tbody>
            </table>


            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Box className={modalStyles.InfoModal}>

                    <h3>Enter Amount</h3>
                    <form onSubmit={handleEnroll} className={modalStyles.modalForm}>
                        <input type="number" onChange={(ele) => {
                            setModalData({
                                ...modalData,
                                amount: ele.target.value
                            });
                        }}/>
                        <button className={modalStyles.submitBtn}>Enroll</button>
                    </form>
                </Box>
            </Modal>
        </div>
    )
}

export default EnrollUser