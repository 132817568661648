import { IS_LOADING, SET_PRIME_LIST, SET_PRIME_ORDERS } from "./action";

const init = {prime_articles: [], prime_order: {}, is_loading: true};

export const primeReducer = (store = init, {type, payload}) => {

    switch(type) {
        case SET_PRIME_LIST: {
            return {
                ...store,
                prime_articles: payload,
                is_loading: false
            };
        };

        case SET_PRIME_ORDERS: {
            return {
                ...store,
                prime_order: payload,
                is_loading: false
            };
        };

        case IS_LOADING: {
            return {
                ...store,
                is_loading: true
            };
        };

        default: {
            return store;
        };
    };
};