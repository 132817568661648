import { Add, Delete, Edit } from '@mui/icons-material';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Select, useTheme } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import commonStyles from "../../styles/Common.module.css";
import styles from "../Buckets/styles/Bucket.module.css";
import { getChildBucketsFun, getParentBucketDataFun } from '../../Redux/NestedBucket/action';
import modalStyles from "../../styles/ModalStyle.module.css";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


const ParentBuckets = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { parent_data, child_buckets, is_loading } = useSelector((store) => store.nested_buckets);
    const { title } = useParams();
    const [modal, setModal] = useState(false);
    const [selectedId, setSelectedId] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [childBuckets, setChildBuckets] = useState([]);

    useEffect(() => {
        if (title) {
            dispatch(getParentBucketDataFun(title));
            dispatch(getChildBucketsFun());
        }

        return () => {

        }
    }, [title]);

    const removeChildBucket = async () => {
        setModal(false);
        const child_bucket = selectedId;

        const res = await fetch(`https://api.sovrenn.com/parent-buckets/remove/${parent_data._id}`, {
            method: "PATCH",
            headers: {
                "Content-type": "application/json",
                "Authorization": localStorage.getItem("sov_user")
            },
            body: JSON.stringify({ child_bucket })
        });

        const data = await res.json();

        if (res.ok) {
            dispatch(getParentBucketDataFun(title));
            alert("Child Bucket Removed Successfully.");
            return;
        };

        alert("Something went wrong.");
        return;
    };


    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        setChildBuckets(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleAddChildBucket = async (event) => {
        event.preventDefault();

        let child_bucket;

        for (let i = 0; i < child_buckets.length; i++) {
            for (let j = 0; j < childBuckets.length; j++) {
                if (child_buckets[i]["bucket_name"] === childBuckets[j]) {
                    child_bucket = child_buckets[i]["_id"]
                };
            };
        };

        const res = await fetch(`https://api.sovrenn.com/parent-buckets/add/${parent_data._id}`, {
            method: "PATCH",
            headers: {
                "Content-type": "application/json",
                "Authorization": localStorage.getItem("sov_user")
            },
            body: JSON.stringify({ child_bucket })
        });

        const data = await res.json();

        if (res.ok) {
            setModalOpen(false);
            dispatch(getParentBucketDataFun(title));
            return;
        };

        alert("Something went wrong.");
        return;
    };

    if (is_loading) {
        return <div className={commonStyles.MainContainer}>

        </div>
    };


    return (
        <div className={commonStyles.MainContainer}>

            <div className={commonStyles.headerDiv}>
                <h1>{parent_data.bucket_name}</h1>



                <div>
                    <Button sx={{ width: "300px" }} onClick={() => setModalOpen(!modalOpen)} color="secondary" variant="outlined" startIcon={<Add />} >Add Child Bucket to this bucket</Button>

                    <Link to="/child-buckets/add">
                        <Button color="secondary" variant="outlined" startIcon={<Add />} fullWidth>Create New Child Bucket</Button>
                    </Link>
                </div>
            </div>

            <div id={styles.bucketContainer}>
                {parent_data?.child_buckets?.map((ele, ind) => {
                    return <div className={styles.bucketCard}>
                        <h3>{ele.bucket_name}</h3>
                        <h4>{ele.about}</h4>

                        <div className={styles.cardBtns}>
                            <Link to={`/parent-buckets/${parent_data.bucket_name}/${ele.bucket_name}`}>
                                <IconButton className={styles.editButton}>
                                    <Edit />
                                </IconButton>
                            </Link>

                            <IconButton className={styles.editButton} onClick={() => {
                                setModal(true);
                                setSelectedId(ele._id)
                            }}>
                                <Delete />
                            </IconButton>
                        </div>
                    </div>
                })}
            </div>

            <Dialog open={modal}>
                <DialogTitle sx={{ fontWeight: "bolder" }}>Confirmation</DialogTitle>
                <DialogContent >
                    <DialogContentText sx={{ color: "black", fontSize: "large" }}>
                        Do you really want to remove this bucket
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setModal(false);
                    }}>Cancel</Button>
                    <Button color='error' onClick={removeChildBucket}>Delete</Button>
                </DialogActions>
            </Dialog>


            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Box className={modalStyles.InfoModal}>
                    <h3>Add Child Bucket to this Bucket</h3>

                    <div className={styles.inputDiv} >
                        <FormControl sx={{ margin: "20px 0" }} fullWidth id="select-companies">
                            <InputLabel id="demo-multiple-chip-label">Child Buckets</InputLabel>
                            <Select
                                style={{ minHeight: "50px" }}
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                // multiple
                                value={childBuckets}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Child Buckets" />}
                                // renderValue={(selected) => (
                                //     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                //         {selected.map((value) => (
                                //             <Chip key={value} label={value} />
                                //         ))}
                                //     </Box>
                                // )}
                                MenuProps={MenuProps}
                            >
                                {child_buckets.map((name) => (
                                    <MenuItem
                                        key={name.bucket_name}
                                        value={name.bucket_name}
                                        style={getStyles(name, childBuckets, theme)}
                                    >
                                        {name.bucket_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <button className={modalStyles.submitBtn} onClick={handleAddChildBucket}>Add</button>
                </Box>
            </Modal>
        </div>
    )
}

export default ParentBuckets;