import { useEffect, useState } from "react";
import commonStyles from "../../styles/Common.module.css";
import tableStyles from "../Users/styles/Users.module.css";
import styles from "../TrialAccess/styles/TrialBatch.module.css";
import { Link } from "react-router-dom";
import { Box, Tab, Tabs } from "@mui/material";
import moment from "moment";


const HotLeads = () => {
    const [userList, setUserList] = useState([]);
    const [showComponent, setShowComponent] = useState("");

    useEffect(() => {
        getUsersList();

        return () => {

        };
    }, []);

    const getUsersList = async (filterBy) => {
        filterBy = filterBy || "";

        const res = await fetch(`https://api.sovrenn.com/analytics/hot-leads?filterBy=${filterBy}`, {
            headers: {
                "Authorization": localStorage.getItem("sov_user")
            },
        });

        const data = await res.json();

        if (res.ok) {
            setUserList(data.userList)
        };
    };

    const getUsersByActions = async (filterBy) => {

        const res = await fetch(`https://api.sovrenn.com/analytics/${filterBy}`, {
            headers: {
                "Authorization": localStorage.getItem("sov_user")
            },
        });

        const data = await res.json();

        if (res.ok) {
            setUserList(data.data)
        };
    };

    const filterChange = (event, value) => {
        setShowComponent(value);
        if (value.startsWith("analytics")) {
            getUsersByActions(value.split(" ")[1]);
        }
        else {
            getUsersList(value);
        }
    };

    return (
        <div className={`${styles.MainContainer}`} >
            <div className={commonStyles.headerDiv}>
                <h1>Hot Leads</h1>
            </div>


            <Box sx={{ marginBottom: "20px" }}>
                <Tabs value={showComponent} onChange={filterChange} aria-label="basic tabs example" centered>
                    <Tab sx={{ width: "100px" }} label="All" value="" />
                    <Tab sx={{ width: "200px" }} label="Trial Hot Leads" value="Trial Hot Lead" />
                    <Tab sx={{ width: "200px" }} label="Times Hot Leads" value="Times Hot Lead" />
                    <Tab sx={{ width: "150px" }} label="Full Access Hot Leads" value="Full Access Hot Lead" />
                    <Tab sx={{ width: "150px" }} label="Tried to purchase Trial" value="analytics trial-purchase-try" />
                    <Tab sx={{ width: "150px" }} label="Used Self Help" value="analytics self-help" />
                </Tabs>
            </Box>

            {userList.length ?
                <div id={tableStyles.tableDiv}>
                    <table id={tableStyles.userListContainer}>
                        <thead id={tableStyles.userListHead}>
                            <tr className={tableStyles.userCard}>
                                <th>Sr. No.</th>
                                <th className={tableStyles.userId}>User Id</th>
                                <th className={tableStyles.tableNameRow}>Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Remark By</th>
                                <th>Remark</th>
                                {showComponent.includes("analytics") ? <th>Last Detected At</th>
                                    :
                                    ""}
                            </tr>
                        </thead>

                        <tbody>
                            {userList.map((ele, ind) => {
                                return <tr className={tableStyles.userCard} key={ele._id}>
                                    <td>{ind + 1}</td>
                                    <td className={tableStyles.userId}>{ele.custom_Id}</td>

                                    {showComponent.includes("analytics") ?
                                        <td>
                                            <Link target="_blank" to={`/users/${ele.user_id}`}>{ele.user_name}
                                            </Link>
                                        </td>
                                        :
                                        <td>
                                            <Link to={`/users/${ele._id}`}>{ele.first_name} {ele.last_name}
                                            </Link>
                                        </td>
                                    }
                                    <td>{ele.email}</td>
                                    <td>{ele.phone_number}</td>
                                    <td>{ele.remark_by || "NA"}</td>
                                    <td>{ele.remark || "NA"}</td>
                                    {showComponent.includes("analytics") ?
                                        <td>{moment(ele.createdAt).format("Do MMM YY") || "NA"}</td>
                                        :
                                        ""}
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                :
                <h4 style={{ textAlign: "center" }}>Could not find any users for {showComponent}</h4>
            }
        </div>
    )
}

export default HotLeads