import { GET_COURSE_DETAILS, GET_COURSE_LIST } from "./action";


const init = {active_list: [], completed_list: [], course_details: {}};

export const courseReducer = (store = init, {type, payload}) => {

    switch(type) {
        case GET_COURSE_LIST: {
            const active = payload.filter((ele) => ele.status === "active");

            const non_active = payload.filter((ele) => ele.status !== "active");

            return {
                ...store,
                active_list: active[0]?.data || [],
                completed_list: non_active.map((ele) => ele.data).flat()
            };
        };

        case GET_COURSE_DETAILS: {
            return {
                ...store,
                course_details: payload
            };
        };

        default: {
            return store;
        };
    };
};