export const GET_QUESTIONS = "GET_QUESTIONS";

const getQuestions = (payload) => {
    return {
        type: GET_QUESTIONS,
        payload
    };
};

export const getQuestionsFunc = (page, rowsPerPage) => async (dispatch) => {

    let page_no = page ? +page + 1 : 1;

    let page_size = rowsPerPage ? rowsPerPage : 50;

    const res = await fetch(`https://api.sovrenn.com/questions/all?page=${page_no}&page_size=${page_size}`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    });

    const data = await res.json();

    if (res.ok) {
        dispatch(getQuestions(data));
    };
};