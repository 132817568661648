import { SET_MACRO_LIST } from "./action";

const init = {
    macro_list: []
};

export const macroReducer = (store = init, { type, payload }) => {

    switch (type) {
        case SET_MACRO_LIST: {
            return {
                ...store,
                macro_list: payload
            };
        };

        default: {
            return store
        };
    };
};