export const GET_BATCHES = "GET_BATCHES";
export const GET_FAMILY_BATCHES = "GET_FAMILY_BATCHES";
export const GET_BATCH_USERS_LIST = "GET_BATCH_USERS_LIST";
export const GET_FAMILY_BATCH_USERS_LIST = "GET_FAMILY_BATCH_USERS_LIST";


const getBatches = (payload) => {
    return {
        type: GET_BATCHES,
        payload
    };
}

const getFamilyBatches = (payload) => {
    return {
        type: GET_FAMILY_BATCHES,
        payload
    };
};

export const getUsersList = (payload) => {
    return {
        type: GET_BATCH_USERS_LIST,
        payload
    };
};

export const getFamilyBatchUsersList = (payload) => {
    return {
        type: GET_FAMILY_BATCH_USERS_LIST,
        payload
    };
};

export const getBatchesFunc = () => async (dispatch) => {

    const res = await fetch("https://api.sovrenn.com/analytics/times-batches", {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    });

    const data = await res.json();

    if (res.ok) {
        dispatch(getBatches(data.timesBatches));
    };
};

export const getFamilyBatchesFunc = () => async (dispatch) => {

    const res = await fetch("https://api.sovrenn.com/analytics/batches", {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    });

    const data = await res.json();

    if (res.ok) {
        dispatch(getFamilyBatches(data.FaBatches));
    };
};

export const getUserListFunc = (startMonth, endMonth, batch) => async (dispatch) => {

    const res = await fetch(`https://api.sovrenn.com/analytics/times-batch-list?startOfMonth=${startMonth}&endOfMonth=${endMonth}&batch=${batch}`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    });

    const data = await res.json();

    if (res.ok) {
        dispatch(getUsersList(data.list));
    };
};

export const getFamilyBatchUserListFunc = (batch) => async (dispatch) => {

    const res = await fetch(`https://api.sovrenn.com/analytics/batch-list/${batch}`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    });

    const data = await res.json();

    if (res.ok) {
        dispatch(getFamilyBatchUsersList(data.userList));
    };
};
