import { useParams } from "react-router-dom";
import commonStyles from "../../styles/Common.module.css";
import { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import { Add, ArrowDownward, ArrowUpward } from "@mui/icons-material";
import EditorComponent from "../Editor/EditorComponent";
import styles from "./styles/PrimeArticle.module.css";
import OverlayCircularProgress from "../Utils/OverlayCircularProgress";
import { initEditor } from "../../utils/initEditor";

const PrimeArticleView = () => {
    const { slug } = useParams();
    const ejInstanceRef = useRef();
    const [primeData, setPrimeData] = useState({
        content: [],
        title: "",
        id: "",
        isPublished: true
    });
    const [newContent, setNewContent] = useState([]);
    const [progress, setProgress] = useState(false);

    useEffect(() => {
        if (slug) {
            getData();
        };

        return () => {

        };
    }, [slug]);

    useEffect(() => {

        if (ejInstanceRef.current === null && primeData.content.length) {

            const DEFAULT_DATA = {
                "time": new Date().getTime(),
                "blocks": primeData.content
            };

            initEditor({ DEFAULT_INITIAL_DATA: DEFAULT_DATA, handleChange, ejInstanceRef });
            console.log({ editorJs: "initialized Init Editor", time: Date.now() });
        };

        console.log({ editorJs: "Invoked EditorJs component", ejInstanceRef, time: Date.now() })

        return () => {
            ejInstanceRef?.current?.destroy();
            ejInstanceRef.current = null;
        };
    }, [primeData]);

    const handleChange = (data) => {
        setNewContent(data.blocks);
    };

    const getData = async () => {
        try {
            const res = await fetch(`https://api.sovrenn.com/prime-research/data/${slug}`, {
                headers: {
                    "authorization": "Bearer " + localStorage.getItem("sov_user")
                }
            });

            const data = await res.json();

            setPrimeData({
                title: data.data.company_Id.company_name,
                content: data?.data?.content,
                isPublished: data?.data?.is_published,
                id: data?.data?._id,
            });
        }
        catch (err) {
            return {
                error: err.message
            }
        }
    };

    const handleUpdate = async () => {
        try {
            if (!newContent.length) {
                return alert("Article Content is not updated");
            };

            setProgress(true);

            const res = await fetch(`https://api.sovrenn.com/prime-research/update/${primeData.id}`, {
                method: "PATCH",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("sov_user"),
                    "Content-type": "application/json"
                },
                body: JSON.stringify({
                    content: newContent
                })
            });

            const data = await res.json();

            setProgress(false);

            if (res.ok) {
                alert("Updated Article Successfully");
                return;
            };

            alert("Something went wrong");
            return;
        }
        catch (err) {

            console.log({ err });
            alert("Something went wrong");
            return;
        };
    };

    if (!primeData.content.length) {
        return <div className={commonStyles.MainContainer}>

            <div className={commonStyles.headerDiv}>
                <h2>...loading</h2>
            </div>
        </div>
    };

    return (
        <div className={commonStyles.MainContainer}>
            <OverlayCircularProgress loading={progress} />

            <div className={commonStyles.headerDiv}>
                <h2>Prime Article - {primeData?.title}</h2>

                <div>
                    {primeData.isPublished ?
                        <Button color="error" variant="outlined" sx={{ width: "150px" }} startIcon={<ArrowDownward />}
                        >Unpublish</Button>
                    :
                        <Button color="primary" variant="outlined" sx={{ width: "150px" }} startIcon={<ArrowUpward />}
                        >Publish</Button>}

                    <Button onClick={handleUpdate} disabled={newContent.length ? primeData.content === newContent : true} color="secondary" variant="outlined" startIcon={<Add />} sx={{ width: "150px" }}
                    >Save</Button>
                </div>
            </div>
            {/* <EditorComponent DEFAULT_INITIAL_DATA={{
                "time": new Date().getTime(),
                "blocks": primeData.content
            }} handleChange={handleChange}
            /> */}


            <div id="editorjs"></div>
        </div>
    )
}

export default PrimeArticleView