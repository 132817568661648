import { useEffect,  useState } from "react"
import { useParams } from "react-router-dom";
import commonStyles from "../../styles/Common.module.css"
import styles from "../News/styles/AddNews.module.css";


const AddBucketData = () => {
    const [bucketData, setBucketData] = useState({
        bucket_doc: null,
        tables: []
    });

    const { id } = useParams();

    const [company, setCompany] = useState({
        company_name: ""
    });



    useEffect(() => {
        getCompanyData();

        return () => {

        }
    }, []);

    const getCompanyData = async () => {
        const res = await fetch(`https://api.sovrenn.com/company/${id}`, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            }
        });

        const data = await res.json();

        if(res.ok) {
            setCompany({
                company_name: data.company.company_name
            });
        };

        return;
    };

 
    const handleFileChange = (ele) => {
        const { id, value } = ele.target;

        setBucketData({
            ...bucketData,
            [id]: ele.target.files[0]
        });
    };

    const handleTablesChange = (ele) => {
        const { name, value } = ele.target;

        setBucketData({
            ...bucketData,
            tables: [...bucketData.tables, value]
        });
    };

    const AddeBucketData = async (event) => {
        event.preventDefault();
        const formData = new FormData();

        if(!bucketData.bucket_doc || !bucketData.tables.length) {
            alert("Please add atleast 1 table and markdown document");
            return;
        }

        formData.set("bucket_doc", bucketData.bucket_doc);

        for (let i = 0; i < bucketData.tables.length; i++) {
            formData.append(`tables[${i}]`, bucketData.tables[i]);
        };

        const res = await fetch(`https://api.sovrenn.com/company/${id}`, {
            method: "PATCH",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            },
            body: formData
        })

        const data = await res.json();

        if (res.ok) {
            setBucketData({
                bucket_doc: null,
                tables: []
            })
            alert("Bucket Data Added Successfully");
        }
        else {
            alert("Erroor occurred while uploading the files");
        }
    };

    return (
        <div className={`${commonStyles.MainContainer} ${styles.CreateMainContainer}`}>
            <h2>Add Bucket Data for {company.company_name}</h2>

            <form onSubmit={AddeBucketData}>
                <div id={styles.firstChildDiv}>
                    <div className={styles.inputDiv} >
                        <label htmlFor="bucket_doc">Bucket Document (.md)</label>
                        <input type="file"
                            accept='text/x-markdown' name="bucket_doc" id="bucket_doc" onChange={handleFileChange} required />
                    </div>
                </div>

                <div id={styles.tablesDiv}>
                    <div className={styles.inputDiv} >
                        <label htmlFor="tableText">Table 1</label>
                        <textarea className={styles.tablesText} name="tableText" cols="30" rows="10" onChange={handleTablesChange}></textarea>
                    </div>

                    <div className={styles.inputDiv} >
                        <label htmlFor="tableText">Table 2</label>
                        <textarea className={styles.tablesText} name="tableText" cols="30" rows="10" onChange={handleTablesChange}></textarea>
                    </div>

                    <div className={styles.inputDiv} >
                        <label htmlFor="tableText">Table 3</label>
                        <textarea className={styles.tablesText} name="tableText" cols="30" rows="10" onChange={handleTablesChange}></textarea>
                    </div>
                </div>

                <button type="submit">Add Data</button>
            </form>
        </div>
    )
}

export default AddBucketData