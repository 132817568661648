export const SET_MACRO_LIST = "SET_MACRO_LIST";


export const setMacroList = (payload) => {
    return {
        type: SET_MACRO_LIST,
        payload
    };
};


export const getMacroListFun = () => async(dispatch) => {
    const res = await fetch("https://api.sovrenn.com/macro-article");

    const data = await res.json();

    if(res.ok) {
        dispatch(setMacroList(data.list));
    };
};