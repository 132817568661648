import { useEffect, useState } from "react";
import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/TrialUserAnalysis.module.css";


const TrialUserAnalysis = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        getData();

        return () => {

        };
    }, [])

    const getData = async () => {

        const res = await fetch("https://api.sovrenn.com/user-activity/trial", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            }
        });

        const data = await res.json();

        if (res.ok) {
            setData(data.data);
        };
    };

    return (
        <div className={commonStyles.MainContainer}>

            <div className={commonStyles.headerDiv}>
                <h1>Trial User Analysis</h1>
            </div>

            <div id={styles.bodyContainer}>

                <h2>From the data of last 7 days</h2>

                <div className={styles.childDivOne}>
                    <h3>
                        Last 7 days Logins: <span>{data?.login?.count}</span>
                    </h3>

                    <h3>
                        Tried to purchase Full Access: <span>{data?.purchase[0].count}</span>
                    </h3>

                    <h3>
                        Self Help Tool Used: <span>{data?.tool_used[0].count}</span>
                    </h3>
                </div>

                <div className={styles.childDivTwo}>
                    <div className={styles.tableDiv}>
                        <h2>Pages Visited</h2>

                        <table className={styles.listTable}>
                            <thead>
                                <tr>
                                    <th>Page Name</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.page_visited?.map((ele, ind) => {
                                    return <tr>
                                        <td>
                                            {ele.page_name === "discovery" ? "Discovery" : ele.page_name === "times" ? "Times" : ""}
                                        </td>

                                        <td>
                                            {ele.count}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div className={styles.tableDiv}>
                        <h2>Articles Read</h2>

                        <div className={styles.articlesTableDiv}>
                            <h3>Prime Articles</h3>

                            <table className={styles.listTable}>
                                <thead>
                                    <tr>
                                        <th>Company Name</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.article_read?.prime?.map((ele, ind) => {
                                        return <tr>
                                            <td>
                                                {ele.company_name}
                                            </td>

                                            <td>
                                                {ele.count}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className={styles.articlesTableDiv}>
                            <h3>IPO Articles</h3>

                            <table className={styles.listTable}>
                                <thead>
                                    <tr>
                                        <th>Company Name</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.article_read?.ipo?.map((ele, ind) => {
                                        return <tr>
                                            <td>
                                                {ele.company_name}
                                            </td>

                                            <td>
                                                {ele.count}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className={styles.articlesTableDiv}>
                            <h3>Discovery Articles</h3>

                            <table className={styles.listTable}>
                                <thead>
                                    <tr>
                                        <th>Company Name</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.article_read?.discovery?.map((ele, ind) => {
                                        return <tr>
                                            <td>
                                                {ele.company_name}
                                            </td>

                                            <td>
                                                {ele.count}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>  
            </div>

        </div>
    )
}

export default TrialUserAnalysis