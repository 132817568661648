import { useEffect, useRef, useState } from "react";
import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/CreateCompany.module.css";
import { industryArr, sectorArr } from "./constant";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSectorsDataFun } from "../../Redux/sector/action";

const commentOnResult = ["Excellent", "Good to Excellent", "Good", "Average to Good", "Average", "Weak to Average", "Weak", "Poor to Weak", "Poor", "NA"];

const quarters = ["Q1", "Q2", "Q3", "Q4", "H1", "H2", "Full Year", "NA"];

const fiscalYears = ["FY23", "FY24", "FY25", "NA"];

const CreateCompany = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [companyData, setCompanyData] = useState({
    company_name: "",
    share_price: "",
    sector_id: "",
    industry: "",
    market_cap: "",
    ttm_pe: "",
    ttm_pat: "",
    isin: "",
    no_of_shares: "",
    profit_growth: "",
    revenue_growth: "",
    exchange: "",
    offer_price_ttm_pe: "",
    company_type: "",
    slug: "",
    revenue_guidance: "",
    quarterly_result: {
      comment_on_result: "",
      last_quarter: "",
      fiscal_year: ""
    }
  });
  const { sectors } = useSelector((store) => store.sectors);

  useEffect(() => {
    getCompanyData();
    dispatch(getSectorsDataFun());

    return () => {

    };
  }, []);

  const getCompanyData = async () => {
    const res = await fetch(`https://api.sovrenn.com/company/${id}`, {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("sov_user")
      }
    });

    const data = await res.json();

    if (res.ok) {
      setCompanyData({
        company_name: data.company.company_name,
        share_price: data.company.share_price || "",
        sector_id: data.company?.sector_id?._id,
        industry: data.company.industry,
        market_cap: data.company.market_cap,
        ttm_pe: data.company.ttm_pe,
        ttm_pat: data.company.ttm_pat || 0,
        no_of_shares: data.company.no_of_shares || 0,
        isin: data.company.isin || "NA",
        profit_growth: data.company.profit_growth,
        revenue_growth: data.company.revenue_growth,
        exchange: data.company.exchange || "BSE",
        offer_price_ttm_pe: data.company.offer_price_ttm_pe || 0,
        company_type: data.company.company_type,
        slug: data.company.slug,
        revenue_guidance: data.company?.revenue_guidance || 0,
        quarterly_result: {
          comment_on_result: data?.company?.quarterly_result?.comment_on_result || "NA",
          last_quarter: data?.company?.quarterly_result?.last_quarter || "NA",
          fiscal_year: data?.company?.quarterly_result?.fiscal_year || "NA"
        }
      });

      return;
    };

    alert("Something went wrong while loading data, please do not make any changes.");
    return;
  }

  const handleChange = (ele) => {
    const { id, name, value } = ele.target;

    if (id === "quarterly_result") {
      setCompanyData({
        ...companyData,
        quarterly_result: {
          ...companyData.quarterly_result,
          [name]: value
        }
      });
      return;
    };

    setCompanyData({
      ...companyData,
      [id]: value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = await fetch(`https://api.sovrenn.com/company/${id}`, {
      method: "PATCH",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("sov_user"),
        "Content-type": "application/json"
      },
      body: JSON.stringify(companyData)
    })
      .then((res) => res.json());

    if (data.success) {
      getCompanyData();
      alert("Company edited successfully!")
    }
    else {
      alert("Ooops something went wrong please try again");
    };
  };

  return (
    <div className={`${commonStyles.MainContainer} ${styles.CreateMainContainer}`}>
      <h2>Update Company Data</h2>

      <div id={styles.formDiv}>
        <form action="" id={styles.form} onSubmit={handleSubmit}>

          <div id={styles.nameNdPriceDiv}>
            <div className={styles.inputDiv}>
              <label htmlFor="company_name">
                Company Name
              </label>
              <input value={companyData.company_name} type="text" id="company_name" name="company_name" required onChange={handleChange} />
            </div>

            <div className={styles.inputDiv}>
              <label htmlFor="share_price">
                Share Price
              </label>
              <input value={companyData.share_price} type="text" id="share_price" name="share_price" required onChange={handleChange} />
            </div>

            <div className={styles.inputDiv}>
              <label htmlFor="no_of_shares">
                Number of Shares
              </label>
              <input value={companyData.no_of_shares} type="text" id="no_of_shares" name="no_of_shares" required onChange={handleChange} />
            </div>

            <div className={styles.inputDiv}>
              <label htmlFor="exchange">Stock Exchange</label>
              <select value={companyData.exchange} id="exchange" name="exchange" required onChange={handleChange}>
                <option value="">Select Exchange</option>

                <option value="NSE">NSE</option>
                <option value="BSE">BSE</option>
              </select>
            </div>
          </div>

          <div id={styles.firstChildDiv}>

            <div className={styles.inputDiv}>
              <label htmlFor="sector_id">Sector</label>
              <select
                value={companyData?.sector_id || "NA"}
                id="sector_id"
                name="sector_id"
                onChange={handleChange}
              >
                <option value="">NA</option>
                {sectors.map((sector) => (
                  <option key={sector._id} value={sector._id}>
                    {sector.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.inputDiv}>
              <label htmlFor="industry">Industry</label>
              <select value={companyData.industry} id="industry" name="industry" required onChange={handleChange}>
                <option value="">Select Industry</option>

                {industryArr.map((ele, ind) => {
                  return <option value={ele}>{ele}</option>
                })}
              </select>
            </div>

            <div className={styles.inputDiv}>
              <label htmlFor="isin">ISIN</label>
              <input value={companyData.isin} type="text" name="isin" id="isin" required onChange={handleChange} />
            </div>
          </div>


          <div id={styles.thirdChildDiv}>

            <div className={styles.inputDiv}>
              <label htmlFor="market_cap">
                Market Cap
              </label>
              <input value={companyData.market_cap} type="number" id="market_cap" name="market_cap" required onChange={handleChange} />
            </div>
            <div className={styles.inputDiv}>
              <label htmlFor="ttm_pe">
                TTM PE
              </label>
              <input value={companyData.ttm_pe} type="number" id="ttm_pe" name="ttm_pe" required onChange={handleChange} />
            </div>

            <div className={styles.inputDiv}>
              <label htmlFor="ttm_pat">
                TTM PAT
              </label>
              <input value={companyData.ttm_pat} type="number" id="ttm_pat" name="ttm_pat" required onChange={handleChange} />
            </div>
            <div className={styles.inputDiv}>
              <label htmlFor="revenue_growth">
                Revenue Growth
              </label>
              <input value={companyData.revenue_growth} type="number" id="revenue_growth" name="revenue_growth" required onChange={handleChange} />
            </div>
            <div className={styles.inputDiv}>
              <label htmlFor="profit_growth">
                Profit Uptrend
              </label>

              <select value={companyData.profit_growth} onChange={handleChange} id="profit_growth" name="profit_growth" required>
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className={styles.inputDiv}>
              <label htmlFor="offer_price_ttm_pe">
                Offer Price PE
              </label>
              <input value={companyData.offer_price_ttm_pe} type="text" id="offer_price_ttm_pe" name="offer_price_ttm_pe" required onChange={handleChange} />
            </div>
          </div>

          <div id={styles.fourthChildDiv}>

            <div className={styles.inputDiv}>
              <label htmlFor="company_type">
                Company Type
              </label>

              <select value={companyData.company_type} onChange={handleChange} id="company_type" name="company_type" required>
                <option value="">Select</option>
                <option value="SME">SME</option>
                <option value="Non SME">Non SME</option>
              </select>
            </div>

            <div className={styles.inputDiv}>
              <label htmlFor="quarterly_result">
                Last Quarter
              </label>

              <select value={companyData.quarterly_result.last_quarter} onChange={handleChange} id="quarterly_result" name="last_quarter" required>
                <option value="">Select</option>
                {quarters.map((ele, ind) => {
                  return <option key={ind} value={ele}>{ele}</option>
                })}
              </select>
            </div>

            <div className={styles.inputDiv}>
              <label htmlFor="quarterly_result">
                Comment On Result
              </label>

              <select value={companyData.quarterly_result.comment_on_result} onChange={handleChange} id="quarterly_result" name="comment_on_result" required>
                <option value="">Select</option>
                {commentOnResult.map((ele, ind) => {
                  return <option key={ind} value={ele}>{ele}</option>
                })}
              </select>
            </div>
            <div className={styles.inputDiv}>
              <label htmlFor="quarterly_result">
                Fiscal Year
              </label>

              <select value={companyData.quarterly_result.fiscal_year} onChange={handleChange} id="quarterly_result" name="fiscal_year" required>
                <option value="">Select</option>
                {fiscalYears.map((ele, ind) => {
                  return <option key={ind} value={ele}>{ele}</option>
                })}
              </select>
            </div>

            <div style={{ margin: 0, width: "160px" }} className={styles.inputDiv}>
              <label htmlFor="revenue_guidance">
                Revenue Guidance
              </label>
              <input value={companyData?.revenue_guidance} type="text" id="revenue_guidance" name="revenue_guidance" required onChange={handleChange} />
            </div>

            <div className={styles.inputDiv}>
              <label htmlFor="bucket_doc">Discovery Data</label>
              <Link to={`/companies/${id}/update-discovery-data`}>
                <button style={{ margin: 0, width: "100%" }}>Add Data</button>
              </Link>
            </div>
          </div>

          <button type="submit">Update Data</button>
        </form>
      </div>
    </div>
  )
}

export default CreateCompany