import { GET_BATCHES, GET_BATCH_USERS_LIST, GET_FAMILY_BATCHES, GET_FAMILY_BATCH_USERS_LIST } from "./action";

const init = { timesBatches: [], userList: [], familyBatches: [], familyBatchUsersList: [] };

export const batchesReducer = (store = init, { type, payload }) => {
    switch (type) {
        case GET_BATCHES: {
            return {
                ...store,
                timesBatches: payload,
            };
        };
        case GET_FAMILY_BATCHES: {
            return {
                ...store,
                familyBatches: payload
            };
        };
        case GET_BATCH_USERS_LIST: {
            return {
                ...store,
                userList: payload
            };
        };
        case GET_FAMILY_BATCH_USERS_LIST: {
            return {
                ...store,
                familyBatchUsersList: payload
            };
        };
        default: {
            return store;
        };
    };
};