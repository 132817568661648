export const GET_REVENUE = "GET_REVENUE";
export const PRIME_ANALYTICS = "PRIME_ANALYTICS";
export const LAST_TRANSACTIONS = "LAST_TRANSACTIONS";
export const CHART_DATA = "CHART_DATA";
export const DAILY_SIGNUPS_BY_CHANEELS = "DAILY_SIGNUPS_BY_CHANEELS";
export const DAILY_USER_ACTIVITY = "DAILY_USER_ACTIVITY";
export const DAILY_WEEKLY_REVENUE = "DAILY_WEEKLY_REVENUE";
export const REVENUE_BY_SOURCE = "REVENUE_BY_SOURCE";
export const SUBS_CONVERSIONS = "SUBS_CONVERSIONS";

const getRevenue = (payload) => {
    return {
        type: GET_REVENUE,
        payload
    };
};

const primeAnalytics = (payload) => {
    return {
        type: PRIME_ANALYTICS,
        payload
    };
};


const lastTransactions = (payload) => {
    return {
        type: LAST_TRANSACTIONS,
        payload
    };
};


const getChartData = (payload) => {
    return {
        type: CHART_DATA,
        payload
    };
};

const getDailySignupByChannelsData = (payload) => {
    return {
        type: DAILY_SIGNUPS_BY_CHANEELS,
        payload
    };
};

const dailyUserActivity = (payload) => {
    return {
        type: DAILY_USER_ACTIVITY,
        payload
    };
};

const getDailyWeeklyRevenue = (payload) => {
    return {
        type: DAILY_WEEKLY_REVENUE,
        payload
    };
};

const getSubsConversion = (payload) => {
    return {
        type: SUBS_CONVERSIONS,
        payload
    };
};

const getRevenueBySource = (payload) => {
    return {
        type: REVENUE_BY_SOURCE,
        payload
    };
};


export const getRevenueFun = () => async (dispatch) => {
    const res = await fetch("https://api.sovrenn.com/analytics/revenue", {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    });

    const data = await res.json();

    if (res.ok) {
        dispatch(getRevenue({ education: data.education, prime: data.primeArticles, trial: data.trialRevenue, fullAccess: data.fullAccessRevenue, times: data.timesRevenue, discovery: data.discoveryRevenue, monthlyRevenue: data.monthlyRevenue, overallRevenue: data.overallRevenue, appRevenue: data.appRevenue, mostEarnedDaySoFar: data.mostEarnedDaySoFar, mostEarnedWeekSoFar: data.mostEarnedWeekSoFar, adminRevenue: data.adminRevenue, websiteRevenue: data.websiteRevenue }));
    };
};

export const primeAnalyticsFun = () => async (dispatch) => {
    const res = await fetch("https://api.sovrenn.com/prime-order/analytics", {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    });

    const data = await res.json();

    if (res.ok) {
        dispatch(primeAnalytics(data.prime))
    }
};

export const lastTransactionsFun = (page, rowsPerPage) => async (dispatch) => {
    let page_no;
    if (page) {
        page_no = +page + 1;
    }
    else {
        page_no = 1
    };

    let page_size;

    if (rowsPerPage) {
        page_size = rowsPerPage;
    }
    else {
        page_size = 400
    };

    const res = await fetch(`https://api.sovrenn.com/analytics/last-transactions?page=${page_no}&page_size=${page_size}`, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    });

    const data = await res.json();

    if (res.ok) {

        dispatch(lastTransactions({ lastOrders: data.lastOrders, trialOrders: data.trialOrders, totalDocuments: data.totalDocuments }));
    };
};

export const dailySignupsFun = () => async (dispatch) => {
    try {
        const res = await fetch("https://api.sovrenn.com/analytics/chart-data", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            }
        });

        const data = await res.json();

        if (res.ok) {
            dispatch(getChartData(data));
        }
    }
    catch (err) {
        console.error(err);
    }
};

export const dailySignupsByChannelsFun = () => async (dispatch) => {
    try {
        const res = await fetch("https://api.sovrenn.com/analytics/daily-signups-by-channels", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            }
        });

        const data = await res.json();

        if (res.ok) {
            dispatch(getDailySignupByChannelsData(data.data));
        }
    }
    catch (err) {
        console.error(err);
    }
};

export const dailyUserActivityFun = () => async (dispatch) => {
    try {
        const res = await fetch("https://api.sovrenn.com/analytics/daily-activity", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            }
        });

        const data = await res.json();

        if (res.ok) {
            dispatch(dailyUserActivity(data.data));
        }
    }
    catch (err) {
        console.error(err);
    }
};

export const getRevenueBySourceFun = () => async (dispatch) => {
    try {
        const res = await fetch("https://api.sovrenn.com/analytics/revenue-split-by-sources", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            }
        });

        const data = await res.json();

        const weekly_revenue_by_source = {};

        for (let i = 0; i < data.weeklyRevenueBySource.length; i++) {
            weekly_revenue_by_source[data.weeklyRevenueBySource[i].source] = data.weeklyRevenueBySource[i].total;
        };

        const monthly_revenue_by_source = {};

        for (let i = 0; i < data.monthlyRevenueBySource.length; i++) {
            monthly_revenue_by_source[data.monthlyRevenueBySource
            [i].source] = data.monthlyRevenueBySource
            [i].total;
        };

        if (res.ok) {
            dispatch(getRevenueBySource({
                monthly_revenue_by_source, weekly_revenue_by_source,
                daily_action_counts: data.dailyActionCount
            }));
        }
    }
    catch (err) {
        console.error(err);
    }
};

export const getDailyWeeklyRevenueFun = () => async (dispatch) => {
    const res = await fetch("https://api.sovrenn.com/analytics/daily-weekly-revenue", {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    });

    const data = await res.json();

    if (res.ok) {
        dispatch(getDailyWeeklyRevenue(data.data))
    }
};

export const getSubsConversionFun = () => async (dispatch) => {
    const res = await fetch("https://api.sovrenn.com/analytics/conversions", {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    });

    const data = await res.json();

    if (res.ok) {
        dispatch(getSubsConversion(data.conversion))
    };
};