
import styles from "./styles/Home.module.css";
import commonStyles from "../../styles/Common.module.css";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserAccessFun } from "../../Redux/Users/action";
import { Box, Button, CircularProgress } from "@mui/material";
import { Add, ArrowDownward } from "@mui/icons-material";
import { CSVLink } from "react-csv";


export const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userAccess } = useSelector((store) => store.users);
  const [checkData, setCheckData] = useState({
    email: "",
    phone_number: "",
    first_name: "",
    last_name: "",
  });

  const [progress, setProgress] = useState(false);
  const csvLinkRef = useRef();
  const [usersResult, setUsersResult] = useState([]);

  const [bseData, setBseData] = useState({
    status: false,
    data: [],
    startDate: "",
    endDate: ""
  });

  useEffect(() => {
    dispatch(getUserAccessFun());

    return () => {

    };
  }, []);

  useEffect(() => {
    if (usersResult.length) {
      csvLinkRef.current.link.click();
    }

    return () => {

    }
  }, [usersResult])

  const handleChange = (ele) => {
    const { id, value } = ele.target;

    setCheckData({
      ...checkData,
      [id]: value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    navigate(`/users?email=${checkData.email}&phone_number=${checkData.phone_number}&first_name=${checkData.first_name}&last_name=${checkData.last_name}`);
  };


  const uploadUsers = async (ele) => {
    const formData = new FormData();

    setProgress(true);

    formData.set("users_list_csv", ele.target.files[0]);

    const res = await fetch("https://api.sovrenn.com/manual/create-accounts", {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("sov_user")
      },
      body: formData
    });

    const data = await res.json();
    setUsersResult(data.data);
    setProgress(false);
  };

  return (
    <div className={`${commonStyles.MainContainer} ${styles.HomeContainer}`} >
      <div className={commonStyles.headerDiv}>
        <h1>Dashboard</h1>

        <div>
          {/* <Button color="secondary" variant="outlined" component="label" startIcon={<ArrowDownward />} sx={{ width: "220px" }}>
            Download BSE Data
          </Button> */}


          <Link to={"/send-app-notification"}>
            <Button color="secondary" variant="outlined" sx={{ width: "180px" }}
            >App Notification</Button>
          </Link>

          <Link to={"/send-mail"}>
            <Button color="secondary" variant="outlined" sx={{ width: "150px" }}
            >Send Email</Button>
          </Link>

          {/* <Button color="secondary" variant="outlined" component="label" startIcon={<Add />} sx={{ width: "150px" }}>
            Add Users
            <input hidden accept=".csv" type="file" onChange={uploadUsers} />
          </Button> */}
        </div>

        {/* <CSVLink
          ref={csvLinkRef}
          data={usersResult}
          filename={"users-result.csv"}
          className="hidden"
          target="_blank"
          style={{ display: "none" }}
        >Download Result</CSVLink> */}
      </div>

      <div id={styles.HomeMainContainer}>
        <div id={styles.userInfoDiv}>
          <h2>FIND USER</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email">Email</label>
              <input type="text" id="email" onChange={handleChange} />
            </div>
            <div>
              <label htmlFor="phone_number">Phone Number</label>
              <input type="number" id="phone_number" onChange={handleChange} />
            </div>
            <div>
              <label htmlFor="first_name">First Name</label>
              <input type="text" id="first_name" onChange={handleChange} />
            </div>
            <div>
              <label htmlFor="last_name">Last Name</label>
              <input type="text" id="last_name" onChange={handleChange} />
            </div>
            <input type="submit" value="Search" />
          </form>
        </div>

        <div id={styles.userAnalytics}>
          <div className={styles.accessCards}>
            <h3>Total Sign Ups</h3>
            <h1>{userAccess.totalUsers?.toLocaleString("en-IN")}</h1>

            <Link to="/users?type" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>Full-Access Users</h3>
            <h1>{userAccess.fullAccess?.toLocaleString("en-IN")}</h1>

            <Link to="/users?type=full-access" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>Life Acess Users</h3>
            <h1>{userAccess.lifeAccess?.toLocaleString("en-IN")}</h1>

            <Link to="/users?type=life" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>News Access Users</h3>
            <h1>{userAccess.news?.toLocaleString("en-IN")}</h1>

            <Link to="/users?type=news" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>SD Access Users</h3>
            <h1>{userAccess.basket?.toLocaleString("en-IN")}</h1>

            <Link to="/users?type=basket" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>Monthly Access Users</h3>
            <h1>{userAccess.monthlyAccess?.toLocaleString("en-IN")}</h1>

            <Link to="/users?type=monthly" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>Quarterly Access Users</h3>
            <h1>{userAccess.quarterlyAccess?.toLocaleString("en-IN")}</h1>

            <Link to="/users?type=quarterly" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>Paid But No Access</h3>
            <h1>{userAccess.paidNoAccess?.toLocaleString("en-IN")}</h1>

            <Link to="/users?type=paid-no-access" className={styles.accessCardsBtn}>View List</Link>
          </div>
          
          <div className={styles.accessCards}>
            <h3>Free Trial Expired Users</h3>
            <h1>{userAccess.freeTrialExpired?.toLocaleString("en-IN")}</h1>

            <Link to="/users?type=free-trial-expired" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>Paid 0</h3>
            <h1>{userAccess.nonPaidUsers?.toLocaleString("en-IN")}</h1>

            <Link to="/users?type=non-paid" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>No Access Users</h3>
            <h1>{userAccess.noAccess?.toLocaleString("en-IN")}</h1>

            <Link to="/users?type=no-access" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>Revenue</h3>

            <Link to="/revenue" className={styles.accessCardsBtn}>Analyse</Link>
          </div>

          {/* <div className={styles.accessCards}>
            <h3>Conversion</h3>
            <h1>{Math.round((userAccess.fullAccess / userAccess.news) * 100)}</h1>

          </div> */}

          <div className={styles.accessCards}>
            <h3>Trial User Analysis</h3>

            <Link to="/trial-user-analysis" className={styles.accessCardsBtn}>View Data</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>Prime Only Users</h3>
            <h1>{userAccess.primeOnly?.toLocaleString("en-IN")}</h1>

            <Link to="/users?type=prime-only" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>Trial Access Users</h3>
            <h1>{userAccess.trialAccess?.toLocaleString("en-IN")}</h1>

            <Link to="/users/trial" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>Trial Expired Users</h3>

            <Link to="/users?type=trial-removed" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>Full Access and Times Expiry</h3>

            <Link to="/access-expiry" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>Referrals List</h3>

            <Link to="/referrals" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>Family & Times Batches</h3>

            <Link to="/times-batches" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>Top Users by Daily Action Counts</h3>

            <Link to="/users?type=daily-top-activity-users" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>Hot Leads List</h3>

            <Link to="/hot-leads" className={styles.accessCardsBtn}>View List</Link>
          </div>

          <div className={styles.accessCards}>
            <h3>User Categorization</h3>

            <Link to="/user-categorization" className={styles.accessCardsBtn}>View List</Link>
          </div>
        </div>
      </div>

      {progress ?
        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}>
          <CircularProgress />
        </Box>
        :
        ""
      }
    </div>
  )
}
