import styles from "./styles/EditorComponent.module.css";
import EditorJS from '@editorjs/editorjs';
// import Header from '@editorjs/header';
import List from '@editorjs/list';
import Marker from "@editorjs/marker";
import ImageTool from '@editorjs/image';
import Header from 'editorjs-header-with-alignment';
import Table from '@editorjs/table';
import Underline from '@editorjs/underline';
import anyButton from "editorjs-button";
import Undo from 'editorjs-undo';
import React, { useEffect, useRef } from "react";

const EditorComponent = React.memo(({ DEFAULT_INITIAL_DATA, handleChange }) => {
    const ejInstance = useRef();

    const initEditor = () => {
        const editor = new EditorJS({
            holder: 'editorjs',

            tools: {
                header: {
                    class: Header,
                    inlineToolbar: true,
                    config: {
                        placeholder: 'Enter a header',
                        levels: [1, 2, 3, 4, 5],
                        defaultLevel: 1,
                        defaultAlignment: 'left'
                    }
                },
                list: {
                    class: List,
                    inlineToolbar: true
                },
                table: {
                    class: Table,
                    inlineToolbar: true,
                },
                image: {
                    class: ImageTool,
                    config: {
                        uploader: {
                            uploadByFile: imageUploader
                        }
                    }
                },
                Marker: {
                    class: Marker,
                    shortcut: 'CMD+SHIFT+M',
                },
                AnyButton: {
                    class: anyButton,
                    inlineToolbar: false,
                    config: {
                        css: {
                            "btnColor": "btn--red",
                        }
                    }
                },
                underline: Underline
            },

            onReady: () => {
                ejInstance.current = editor;
                new Undo({ editor });
            },
            autofocus: true,
            data: DEFAULT_INITIAL_DATA || {},
            onChange: async () => {
                let content = await editor.saver.save();
                
                await handleChange(content);
            }
        });
    };

    const imageUploader = async (file) => {
        try {
            const formData = new FormData();

            formData.append("image", file);

            const res = await fetch("https://api.sovrenn.com/uploads/image", {
                method: "POST",
                headers: {
                    "authorization": "Bearer " + localStorage.getItem("sov_user")
                },
                body: formData
            });

            const data = await res.json();

            if (res.ok) {
                return {
                    success: 1,
                    file: {
                        url: data.data.url,
                    }
                };
            };

            return {
                success: 0
            };
        }
        catch (err) {
            return {
                success: 0,
                error: err.message
            };
        }
    };

    // This will run only once
    useEffect(() => {
        if (ejInstance.current === null) {
            initEditor();
        };

        return () => {
            ejInstance?.current?.destroy();
            ejInstance.current = null;
        };
    }, []);

    return (
        <div className={styles.MainContainer}>
            <div id="editorjs"></div>
        </div>
    );
})

export default EditorComponent