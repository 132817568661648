import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/Questions.module.css";

import React, { useEffect, useRef, useState } from 'react';
import { TextField, MenuItem, Button, Card, CardContent, Typography, IconButton, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Delete } from "@mui/icons-material";
import { initEditor } from "../../utils/initEditor";
import OverlayCircularProgress from "../Utils/OverlayCircularProgress";

const AddQuestion = () => {
    const [question, setQuestion] = useState({
        question_text: [],
        options: ['', ''],
        usage_type: 'ALL',
        correct_answer: '',
        difficulty_level: 'BEGINNER',
    });
    const [showQtextErrMessage, setShowQtextErrMessage] = useState(false);
    const ejInstanceRef = useRef();
    const [errors, setErrors] = useState({});
    const [progress, setProgress] = useState(false);

    useEffect(() => {

        // if (ejInstanceRef.current === null) {
            const DEFAULT_DATA = {
                "time": new Date().getTime(),
                "blocks": []
            }

            initEditor({ DEFAULT_INITIAL_DATA: DEFAULT_DATA, handleChange: handleChangeQuestion, ejInstanceRef });
        // };

        return () => {
            ejInstanceRef?.current?.destroy();
            ejInstanceRef.current = null;
        };
    }, []);

    const handleChangeQuestion = (data) => {
        setQuestion((prev) => ({
            ...prev,
            question_text: data.blocks
        }));

        if (!data.blocks.length) {
            setShowQtextErrMessage(false);
        }
    };

    // Handle input changes
    const handleInputChange = (field, value) => {
        setQuestion((prev) => ({
            ...prev,
            [field]: value.trim(),
        }));
    };

    // Handle options change
    const handleOptionChange = (index, value) => {
        const newOptions = [...question.options];
        newOptions[index] = value;
        setQuestion((prev) => ({ ...prev, options: newOptions }));
    };

    // Add a new option
    const addOption = () => {
        setQuestion((prev) => ({
            ...prev,
            options: [...prev.options, ''],
        }));
    };

    // Remove an option
    const removeOption = (index) => {
        const newOptions = question.options.filter((_, i) => i !== index);
        setQuestion((prev) => ({ ...prev, options: newOptions }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!question.question_text) newErrors.question_text = 'Question text is required';
        if (question.options.some((option) => !option)) newErrors.options = 'All options are required';
        if (!question.correct_answer) newErrors.correct_answer = 'Correct answer is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!question.question_text.length) {
            setShowQtextErrMessage(true);
            return;
        };

        if (validateForm()) {
            setProgress(true);

            const options = question.options.map((ele) => {
                return {
                    text: ele.trim()
                }
            });

            const body = {
                ...question,
                options
            };

            try {
                const res = await fetch("https://api.sovrenn.com/questions/add", {
                    method: "POST",
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem("sov_user"),
                        "Content-type": "application/json"
                    },
                    body: JSON.stringify(body)
                });

                const data = await res.json();

                setProgress(false);
                
                if (res.ok) {
                    alert("Added the question Successfully");
                    return;
                };
                
                alert(data?.message);
            }
            catch (err) {
                setProgress(false);
                alert(err.message);
            };

            setQuestion({
                question_text: [],
                options: ['', ''],
                usage_type: 'ALL',
                correct_answer: '',
                difficulty_level: 'BEGINNER',
            })
            setShowQtextErrMessage(false);
            return;
        };
    };

    return (
        <div className={`${styles.MainContainer}`} >
            <OverlayCircularProgress loading={progress} />

            <div className={commonStyles.headerDiv}>
                <h1>Create New Question</h1>

            </div>

            <Card>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        Question Text
                    </Typography>

                    <form onSubmit={handleSubmit}>
                        {/* Question Text */}
                        <div style={{ minHeight: "50px", border: "1px solid gray", borderRadius: "4px" }}>
                            <div style={{ fontSize: "large" }} id="editorjs"></div>
                        </div>

                        {showQtextErrMessage ? <p style={{ fontSize: "smaller", color: "red", marginLeft: "15px" }}>Question text is required</p> : ""}

                        {/* Usage Type */}
                        {/* <TextField
                            select
                            label="Usage Type"
                            value={question.usage_type}
                            onChange={(e) => handleInputChange('usage_type', e.target.value)}
                            fullWidth
                            margin="normal"
                        >
                            <MenuItem value="ALL">ALL</MenuItem>
                            <MenuItem value="EXAM">EXAM</MenuItem>
                        <MenuItem value="QUIZ">QUIZ</MenuItem>
                        </TextField>

                        Difficulty Level
                        <TextField
                            select
                            label="Difficulty Level"
                            value={question.difficulty_level}
                            onChange={(e) => handleInputChange('difficulty_level', e.target.value)}
                            fullWidth
                            margin="normal"
                        >
                            <MenuItem value="BEGINNER">BEGINNER</MenuItem>
                            <MenuItem value="INTERMEDIATE">INTERMEDIATE</MenuItem>
                        </TextField> */}

                        {/* Options */}
                        <Typography variant="h5" style={{ marginTop: '25px' }}>
                            Options
                        </Typography>
                        {question.options.map((option, index) => (
                            <Grid sx={{ width: "115%" }} container alignItems="center" spacing={2} key={index}>
                                <Grid item xs={10}>
                                    <TextField
                                        label={`Option ${index + 1}`}
                                        value={option}
                                        onChange={(e) => handleOptionChange(index, e.target.value)}
                                        fullWidth
                                        required
                                        margin="normal"
                                        error={!!errors.options}
                                        helperText={errors.options}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    {index > 1 && (
                                        <IconButton color="error" onClick={() => removeOption(index)}>
                                            <Delete />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                        <Button
                            startIcon={<AddIcon />}
                            onClick={addOption}
                            variant="outlined"
                            color="primary"
                            style={{ marginTop: '10px', marginBottom: '20px' }}
                        >
                            Add Option
                        </Button>

                        {/* Correct Answer */}
                        <TextField
                            select
                            label="Correct Answer"
                            value={question.correct_answer}
                            onChange={(e) => handleInputChange('correct_answer', e.target.value)}
                            fullWidth
                            margin="normal"
                            error={!!errors.correct_answer}
                            helperText={errors.correct_answer}
                        >
                            {question.options.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>

                        <button type="submit" style={{ margin: "20px 30% 0", marginTop: '20px', width: "40%", fontSize: "larger", fontWeight: "bold", height: "40px" }}>
                            Create
                        </button>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
};

export default AddQuestion;