import { GET_SECTORS } from "./action";

const init = { sectors: [] };

export const sectorsReducer = (store = init, { type, payload }) => {
    switch (type) {
        case GET_SECTORS:
            return {
                sectors: payload
            };

        default: return store
    };
};