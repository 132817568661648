import { Box, Button, CircularProgress, Dialog, DialogContent } from "@mui/material";
import { CSVLink } from "react-csv";
import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/TrialBatch.module.css";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import tableStyles from "../Users/styles/Users.module.css";
import { getTrialUsersListFunc } from "../../Redux/Trial/action";
import moment from "moment";
import { Upload } from "@mui/icons-material";


const TrialUsers = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((store) => store.auth);
    const { usersList, fullAccess, sdAccess, newsAccess, noSessions } = useSelector((store) => store.trial);
    const [searchParams, setSearchParams] = useSearchParams();

    const type = searchParams.get("type");
    const batch = searchParams.get("batch");
    const [addContactsProgress, setAddContactsProgress] = useState(false);

    const [updateRemark, setUpdateRemark] = useState({
        remark: "",
        user_id: "",
        lead: "",
    });

    useEffect(() => {
        if (batch) {
            dispatch(getTrialUsersListFunc(batch));
        }
        return () => {

        };
    }, []);

    useEffect(() => {
        if (updateRemark.user_id) {
            const updateData = setTimeout(() => {
                sendUpdateRequest();

            }, 1000);
            return () => clearTimeout(updateData)
        };

        return () => {

        }

    }, [updateRemark]);


    const sendUpdateRequest = async () => {
        let body = {};

        for (let key in updateRemark) {
            if (key == "added_in_times" || key == "added_in_fullaccess") {

                body[key] = updateRemark[key];
                continue;
            };

            if (key !== "user_id" && updateRemark[key]) {

                body[key] = updateRemark[key]
                body["remark_by"] = user.first_name + " " + user.last_name;
            };
        };

        const res = await fetch(`https://api.sovrenn.com/user/edit-info/${updateRemark.user_id}`, {
            method: "PATCH",
            headers: {
                "Content-type": "application/json",
                "Authorization": localStorage.getItem("sov_user")
            },
            body: JSON.stringify(body)
        });

        const data = await res.json();

        if (res.ok) {
            if (batch) {
                dispatch(getTrialUsersListFunc(batch));
            }

            setUpdateRemark({
                remark: "",
                user_id: "",
                lead: "",
            });
        };
    };

    const updateChange = (key, value, id) => {

        if (updateRemark.user_id !== id) {
            setUpdateRemark({
                remark: "",
                user_id: "",
                lead: "",
            });
        };

        setUpdateRemark({
            ...updateRemark,
            [key]: value,
            user_id: id
        });
    };

    const headers = [
        { label: "User Id", key: "custom_Id" },
        { label: "First Name", key: "first_name" },
        { label: "Last Name", key: "last_name" },
        { label: "Email", key: "email" },
        { label: "Country Code", key: "country_code" },
        { label: "Phone Number", key: "phone_number" },
        { label: "No. Of Sessions Booked", key: "sessions" },
        { label: "State", key: "state" },
        { label: "Subscribed to", key: "subscriptions" },
    ]

    const date = new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear();


    return (
        <div className={`${styles.MainContainer}`} >
            <div className={commonStyles.headerDiv}>
                <h1>Trial Batch: {batch}</h1>

                <div>
                    <CSVLink data={usersList} filename={`trial-access-users-list-${date}-${batch}`} headers={headers} target="_blank">
                        <Button color="secondary" variant="outlined" startIcon={<DownloadOutlinedIcon />} sx={{ width: "250px" }}>Download List</Button>
                    </CSVLink>
                </div>
            </div>

            <div id={styles.analyticsDiv}>
                <h3>Full Access: <span>{fullAccess}</span></h3>
                <h3>News Access: <span>{newsAccess}</span></h3>
                <h3>Discovery Access: <span>{sdAccess}</span></h3>
                <h3>No Education Sessions Booked: <span>{noSessions}</span></h3>
            </div>

            <div id={tableStyles.tableDiv}>
                <table id={tableStyles.userListContainer}>
                    <thead id={tableStyles.userListHead}>
                        <tr className={tableStyles.userCard}>
                            <th>Sr. No.</th>
                            <th className={tableStyles.userId}>Where did hear</th>
                            <th className={tableStyles.tableNameRow}>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Session Booked</th>
                            <th>Access To</th>
                            <th>Lead</th>
                            <th>WP Times</th>
                            <th>WP FA</th>
                            <th>Remark</th>
                            <th>Remark By</th>
                            <th>Actions Count</th>
                            <th>Purchased From</th>
                            <th>App Installed</th>
                            <th>Pulse Exists</th>
                            <th>Purchased On</th>
                        </tr>
                    </thead>

                    <tbody>
                        {usersList.map((ele, ind) => {
                            return <tr className={tableStyles.userCard} key={ele._id}>
                                <td>{ind + 1}</td>
                                <td className={tableStyles.userId}>{ele?.where_did_hear_about_sovrenn || "NA"}</td>

                                <td>
                                    <Link target="_blank" to={`/users/${ele._id}`}>{ele.user_name}
                                    </Link>
                                </td>
                                <td>{ele.email}</td>
                                <td>{ele.phone_number}</td>

                                <td>Booked <span style={ele.sessions ? { fontSize: "large", color: "green" } : { fontSize: "large", color: "red" }}>{ele.sessions}</span> Sessions</td>

                                <td className={tableStyles.tableSubscriptions}>{ele.subscriptions.map((ele, ind) => {
                                    return <p>{ele === "news" ? "News" : ele === "full-access" ? "Full_Access" : ele === "basket" ? "SD" : ele === "trial" ? "Trial" : ele === "life" ? "Life" : ele === "monthly" ? "Monthly" : ele === "quarterly" ? "Quarterly" : ""}</p>
                                })}</td>

                                <td >
                                    <select id="lead" value={ele?.lead || ""} className={tableStyles.leadSelect}
                                        onChange={(event) =>

                                            updateChange("lead", event.target.value, ele._id)
                                        }>
                                        <option value="">Select</option>
                                        <option value="Hot Lead">Hot Lead</option>
                                        <option value="Not Interested">Not Interested</option>
                                        <option value="Undecided">Undecided</option>
                                        <option value="Not Connected">Not Connected</option>
                                    </select>
                                </td>

                                <td>
                                    <input
                                        checked={ele.added_in_times ? true : false}
                                        id="added_in_times"
                                        onChange={
                                            () => {
                                                updateChange("added_in_times", !ele.added_in_times, ele._id)
                                            }
                                        }
                                        className={tableStyles.checkBoxes}
                                        type="checkbox" />
                                </td>
                                <td>
                                    <input
                                        checked={ele.added_in_fullaccess ? true : false}
                                        id="added_in_fullaccess"
                                        className={tableStyles.checkBoxes}
                                        type="checkbox"
                                        onChange={
                                            () => {
                                                updateChange("added_in_fullaccess", !ele.added_in_fullaccess, ele._id)
                                            }
                                        } />
                                </td>

                                <td className={tableStyles.tableRemarkColumn}>
                                    <textarea type="text"
                                        id="remark"
                                        className={tableStyles.remarkInput}
                                        onChange={(event) =>

                                            updateChange("remark", event.target.value, ele._id)
                                        }>{ele.remark}</textarea>
                                </td>
                                
                                <td>{ele?.remark_by || "NA"}</td>
                                <td>{ele.actions_count}</td>

                                <td>{ele.bought_from}</td>
                                <td>{ele.has_app_installed ? "Yes": "No"}</td>
                                <td>{ele.my_portfolio_exists ? "Yes": "No"}</td>

                                <td>{moment(ele.purchased_date).format("Do MMM YY")}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>

            <Dialog open={addContactsProgress} disableBackdropClick disableEscapeKeyDown>
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '20px',
                    }}
                >
                    <CircularProgress color="secondary" style={{ width: "50px", height: "50px" }} />
                    <h3 style={{ textAlign: 'center', marginTop: '20px' }}>
                        Please wait, contacts are being added
                    </h3>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default TrialUsers