import { useEffect, useState } from "react";
import commonStyles from "../../styles/Common.module.css";
import tableStyles from "../Users/styles/Users.module.css";
import styles from "../TrialAccess/styles/TrialBatch.module.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Box, CircularProgress, Tab, TablePagination, Tabs } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";


const UserCategorization = () => {
    const navigate = useNavigate();
    const { user } = useSelector((store) => store.auth);
    const [userList, setUserList] = useState([]);
    const [showComponent, setShowComponent] = useState("very-light");
    const [pagination, setPagination] = useState({
        totalRows: 500,
        page: 0,
        total_pages: 0,
        total_docs: 0,
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);

    const type = searchParams.get("type");
    const page_no = searchParams.get("page_no");
    const page_size = searchParams.get("page_size");

    const [updateRemark, setUpdateRemark] = useState({
        remark: "",
        user_id: "",
        lead: "",
    });

    useEffect(() => {

        if (type === null) {
            getUsersList("very-light", true)
        }
        else {
            getUsersList(type, true);
        }

        return () => {

        };
    }, [page_no, page_size, type]);

    useEffect(() => {
        if (updateRemark.user_id) {
            const updateData = setTimeout(() => {
                sendUpdateRequest();

            }, 1000);
            return () => clearTimeout(updateData)
        };

        return () => {

        }

    }, [updateRemark]);

    const updateChange = (key, value, id) => {

        if (updateRemark.user_id !== id) {
            setUpdateRemark({
                remark: "",
                user_id: "",
                lead: "",
            });
        };

        setUpdateRemark({
            ...updateRemark,
            [key]: value,
            user_id: id
        });
    };

    const sendUpdateRequest = async () => {
        let body = {};

        for (let key in updateRemark) {
            if (key == "added_in_times" || key == "added_in_fullaccess") {

                body[key] = updateRemark[key];
                continue;
            };

            if (key !== "user_id" && updateRemark[key]) {

                body[key] = updateRemark[key];
                body["remark_by"] = user.first_name + " " + user.last_name;
            };
        };

        const res = await fetch(`https://api.sovrenn.com/user/edit-info/${updateRemark.user_id}`, {
            method: "PATCH",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            },
            body: JSON.stringify(body)
        });

        const data = await res.json();

        console.log(data);

        if (res.ok) {
            if (type === null) {
                getUsersList("light", false)
            }
            else {
                getUsersList(type, false);
            };

            setUpdateRemark({
                remark: "",
                user_id: "",
                lead: "",
            });
        }
    };

    const getUsersList = async (filterBy, setLoading) => {
        setIsLoading(setLoading);
        filterBy = filterBy || "";

        const page = page_no || 1;
        const pageSize = page_size || 500;

        const res = await fetch(`https://api.sovrenn.com/analytics/${filterBy}-user-categorization?page=${page}&page_size=${pageSize}`, {
            headers: {
                "Authorization": localStorage.getItem("sov_user")
            },
        });

        const data = await res.json();

        setIsLoading(false);

        if (res.ok) {
            setUserList(data.userList);
            setPagination({
                totalRows: data.pagination.page_size,
                page: data.pagination.page - 1,
                total_pages: data.pagination.totalPages,
                total_docs: data.pagination.totalDocs,
            })
        };
    };

    const handleChangePage = (event, newPage) => {
        console.log(newPage);
        setPagination({
            ...pagination,
            page: newPage
        });
        changePath(newPage + 1, pagination.totalRows);
    };

    const handleChangeRowsPerPage = (event) => {
        setPagination({
            ...pagination,
            totalRows: parseInt(event.target.value)
        });
        changePath(pagination.page + 1, event.target.value);
    };

    const changePath = (page_no, page_size) => {
        navigate(`/user-categorization?type=${type || "light"}&page_no=${page_no}&page_size=${page_size}`);
    };

    const filterChange = (event, value) => {
        setIsLoading(true);
        setShowComponent(value);
        setPagination({
            ...pagination,
            page: 0,
            totalRows: 500
        });
        navigate(`/user-categorization?type=${value}&page_no=${1}&page_size=${500}`);
    };

    if (isLoading) {
        return <div className={`${styles.MainContainer}`} >
            <div className={commonStyles.headerDiv}>
                <h1>User Categorization by actions</h1>
            </div>


            <Box sx={{ marginBottom: "20px" }}>
                <Tabs value={showComponent} onChange={filterChange} aria-label="basic tabs example" centered>
                    <Tab sx={{ width: "300px" }} label="Very Light Users (No Activity)" value="very-light" />
                    <Tab sx={{ width: "300px" }} label="Light Users" value="light" />
                    <Tab sx={{ width: "200px" }} label="Moderate Users" value="moderate" />
                    <Tab sx={{ width: "200px" }} label="Power Users" value="heavy" />
                </Tabs>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: "center", width: 100, margin: "15vh auto" }}>
                <CircularProgress />
            </Box>
        </div>
    };

    return (
        <div className={`${styles.MainContainer}`} >
            <div className={commonStyles.headerDiv}>
                <h1>User Categorization by actions</h1>
            </div>


            <Box sx={{ marginBottom: "20px" }}>
                <Tabs value={showComponent} onChange={filterChange} aria-label="basic tabs example" centered>
                    <Tab sx={{ width: "300px" }} label="Very Light Users (No Activity)" value="very-light" />
                    <Tab sx={{ width: "300px" }} label="Light Users" value="light" />
                    <Tab sx={{ width: "200px" }} label="Moderate Users" value="moderate" />
                    <Tab sx={{ width: "200px" }} label="Power Users" value="heavy" />
                </Tabs>
            </Box>

            {userList.length ?
                <div id={tableStyles.tableDiv}>
                    <table id={tableStyles.userListContainer}>
                        <thead id={tableStyles.userListHead}>
                            <tr className={tableStyles.userCard}>
                                <th >Sr. No</th>
                                <th className={tableStyles.tableNameRow}>Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Actions Count</th>
                                {/* <th>Total Actions</th> */}
                                <th>Access To</th>
                                <th>Lead</th>
                                <th>Remark</th>
                                <th>Remark By</th>
                            </tr>
                        </thead>

                        <tbody>
                            {userList.map((ele, ind) => {
                                return <tr className={tableStyles.userCard} key={ele._id}>
                                    <td className={tableStyles.tableNameRow}>
                                        {ind + 1}
                                    </td>

                                    <td className={tableStyles.tableNameRow}><Link to={`/users/${ele._id}`} target="_blank">
                                        {ele.first_name} {ele.last_name}
                                    </Link></td>

                                    <td
                                    // onClick={() => {
                                    //     setEmailContent({
                                    //         ...emailContent,
                                    //         user_email: ele.email
                                    //     });
                                    //     setMailDialog(true);
                                    // }}
                                    >
                                        {ele.email}
                                    </td>

                                    <td className={tableStyles.tablePhoneNoColumn}>
                                        <a target="_blank" href={`https://wa.me/${ele.phone_number}`}>
                                            {(ele?.country_code) + " " + ele.phone_number}
                                        </a>
                                    </td>

                                    <td>
                                        {ele.actions_count || 0}
                                    </td>

                                    {/* <td>
                                        {ele.total_actions || 0}
                                    </td> */}

                                    <td className={tableStyles.tableSubscriptions}>{ele?.subscriptions?.map((ele, ind) => {
                                        return <p>{ele === "news" ? "News" : ele === "full-access" ? "Full_Access" : ele === "basket" ? "SD" : ele === "trial" ? "Trial" : ele === "life" ? "Life" : ele === "monthly" ? "Monthly" : ele === "quarterly" ? "Quarterly" : ""}</p>
                                    })}</td>

                                    <td >
                                        <select id="lead" value={ele?.lead || ""} className={tableStyles.leadSelect}
                                            onChange={(event) =>

                                                updateChange("lead", event.target.value, ele._id)
                                            }>
                                            <option value="">Select</option>
                                            <option value="Trial Hot Lead">Trial Hot Lead</option>
                                            <option value="Times Hot Lead">Times Hot Lead</option>
                                            <option value="Full Access Hot Lead">Full Access Hot Lead</option>
                                            <option value="Not Interested">Not Interested</option>
                                            <option value="Undecided">Undecided</option>
                                            <option value="Not Connected">Not Connected</option>
                                        </select>
                                    </td>

                                    <td className={styles.tableRemarkColumn}>
                                        <textarea type="text"
                                            id="remark"
                                            className={styles.remarkInput}
                                            onChange={(event) =>

                                                updateChange("remark", event.target.value, ele._id)
                                            }>{ele.remark}</textarea>
                                    </td>

                                    <td>
                                        {ele.remark_by}
                                    </td>

                                </tr>
                            })}
                        </tbody>
                    </table>

                    <TablePagination
                        component="div"
                        count={pagination.total_docs}
                        page={pagination.page}
                        onPageChange={handleChangePage}
                        rowsPerPage={pagination.totalRows}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[100, 500, 1000]}
                    />
                </div>
                :
                <h4 style={{ textAlign: "center" }}>Could not find any users for {showComponent}</h4>
            }


            {/* <TablePagination
                component="div"
                count={pagination.total_docs}
                page={pagination.page}
                onPageChange={handleChangePage}
                rowsPerPage={pagination.totalRows}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[100, 500, 1000]}
            /> */}

        </div>
    )
}

export default UserCategorization