import { Add, Edit } from "@mui/icons-material"
import { Button, IconButton } from "@mui/material"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import commonStyles from "../../styles/Common.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setPrimeListFun } from "../../Redux/Prime/action";
import moment from "moment";
import tableStyles from "../Users/styles/Users.module.css";
import styles from "./styles/PrimeResearch.module.css";

export const PrimeResearch = () => {
  const dispatch = useDispatch();
  const { prime_articles } = useSelector((store) => store.prime);

  useEffect(() => {
    dispatch(setPrimeListFun());

    return () => {

    };
  }, []);

  return (
    <div className={commonStyles.MainContainer}>

      <div className={commonStyles.headerDiv}>
        <h1>Prime Articles</h1>

        <div>
          <Link to={"/prime/orders"}>
            <Button color="secondary" variant="outlined" sx={{ width: "150px" }}
            >View Orders</Button>
          </Link>

          <Link to="/prime/add">
            <Button color="secondary" variant="outlined" startIcon={<Add />} sx={{ width: "200px" }}
            >Add Article</Button>
          </Link>
        </div>
      </div>

      <table id={tableStyles.userListContainer}>
        <thead id={tableStyles.userListHead}>
          <tr>
            <th>Sr. No.</th>
            <th>Created At</th>
            <th>Company Name</th>
            <th>Is Published</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {prime_articles.map((ele, ind) => {
            return <tr className={tableStyles.userCard} key={ind}>
              <td>{ind + 1}</td>
              <td>{moment(ele.createdAt).format("Do MMM YY")}</td>
              <td className={styles.name}>{ele?.company_name}</td>
              <td className={styles.name}>{ele?.is_published ? "YES" : "NO"}</td>
              <td>
                <Link to={`/prime/${ele.slug}`}>
                  <IconButton className={styles.editButton}>
                    <Edit />
                  </IconButton>
                </Link>
              </td>
            </tr>
          })}
        </tbody>
      </table>

    </div>
  )
}
