export const GET_COURSE_LIST = "GET_COURSE_LIST";
export const GET_COURSE_DETAILS = "GET_COURSE_DETAILS";

const getCourseList = (payload) => {
    return {
        type: GET_COURSE_LIST,
        payload
    };
};

const getCourseDetails = (payload) => {
    return {
        type: GET_COURSE_DETAILS,
        payload
    };
};


export const getCourseListFun = () => async(dispatch) => {
    const res = await fetch("https://api.sovrenn.com/course/list", {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    });
    const data = await res.json();

    if(res.ok) {
        dispatch(getCourseList(data.courses));
        return;
    }

    dispatch(getCourseList([]));
}

export const getCourseDetailsFun = (id) => async (dispatch) => {
    const res = await fetch(`https://api.sovrenn.com/course/${id}`, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    });
    const data = await res.json();

    if (res.ok) {
        dispatch(getCourseDetails(data.course[0]));
        return;
    }

    dispatch(getCourseDetails([]));
}