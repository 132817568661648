
// export const sectorArr = [
//     'Advertising', 'Agri', 'Agro',
//     'Agro-Chemicals','Apparel', 'Alloys', 'Automobile',
//     'Beverages', 'Cable and Wires', 'Capital Goods',
//     'Chemicals', 'Co-working','Commodities', 'Consumer Discretionary',
//     'Consumer Durables', 'Consumer Food', 'Consumer Services', 'Consumer Staples', 'Construction', 'Diamond',
//     'Diversified', 'Drones', 'Ecotextile Solution', 'Electronics','Electrical Equipment',
//     'Energy', 'Engineering', 'Entertainment',
//     'FMCG', 'Food', 'Financials',
//     'Food Products', 'Health Care', 'Hospital',
//     'IT', 'Industrial Products',
//     'Industrials', 'Infrastructure', 'Kitchen', 'Lights (LED)', 'Logistics', 'Macro', 'Manufacturing','Manufacturing-Garments', 'Manufacturing-Packaging Material',
//     'Materials', 'Media', 'Metals & Mining', 'Metal Farication', 'Oil & Gas', 'Outdoor Furnishing',  'Petrochemicals', 'Pharma', 'Plastics', 'Plastic Engineering & Molding',
//     'Power Generation', 'Railways', 'Real Estate',
//     'Renewables', 'Retail', 'Rubber', 'Salt', 'Scientific Instruments',
//     'Services', 'Shipping and Hospitality', 'Solar', 'Spices', 'Technology', 'Telecommunication', 'Telecom and Renewable Infrastructure',
//     'Textile',
//     'Trading', 'Transportation', 'Waste Management', 'Water Treatment', 'Miscellaneous', 'Valves'
// ] 

export const sectorArr = [
    'Information Technology', 'Pharma', 'Commodity Trading/Metal/Paper', 'Consumer Retail/FMCG', 'Consumer Discretionary', 'Capital Goods - Electrical Equipment', 'Capital Goods - Non Electrical Equipments', 'Industrial Products', 'Wires and Cables', 'Infrastructure', 'Telecom/Solar/Power Infra', 'Realty', 'Building Material', 'NBFCs', 'Banking', 'Financial Services', 'Microfinance', 'Brokerage', 'Power Generation', 'Electric Vehicles', 'Automobile Manufacturing', 'Automobile Ancillaries', 'Railways', 'Clean Energy', 'Hydro Engineering', 'Smart Meter', 'Recycling', 'Healthcare - Hospitals/Diagnostics', 'Speciality Chemicals', 'Commodity Chemicals', 'FMCG', 'Packaging', 'Defence', 'Drones', 'Contract Manufacturing', 'EMS', 'Logistics', 'Travel Services', 'HR Services', 'Tech Platform', 'Steel products', 'EPC', 'Stationery', 'Education', 'Agro-processing', 'Agri Products', 'Alcoholic Beverages', 'Event Management', 'Entertainment', 'VFX', 'Biofuels', 'Miscellaneous', 'NA'
]

export const industryArr = [
    'Agricultural Products',
    'Apparel', 'Advertising', 
    'Automobiles',
    'Auto Components',
    'Aquaculture',
    'BPO',
    'Banking',
    'Bearings',
    'Breweries & Distilleries',
    'Cables',
    'Capital Market', 'Cargo', 'Casting',
    'Chemicals',
    'Commodity Chemicals',
    'Civil Construction',
    'Cloud Computing Services',
    'Commercial Vehicles',
    'Communications Equipment',
    'Construction',
    'Construction Materials',
    'Consultancy Services',
    'Consumer Finance',
    'Defense',
    'Dental', 'Diamonds',
    'Diversified',
    'Diversified Retail',
    'E-commerce',
    'Edible Oil',
    'Education',
    'EV Ancillaries',
    'Electrical Equipment',
    'Electrodes & Refractories',
    'Electronic Components',
    'Electronics',
    'Electronics Retail',
    'Engineering',
    'Equipment & Accessories',
    'Event Management',
    'Explosives', 'Fabrics',
    'Fertilisers',
    'Fertilisers and Agrochemicals',
    'Financial Services', 'Films',
    'Food', 'Green Energy',
    'Home Furnishing',
    'Home Appliances', 'Hospital',
    'Hotels',
    'Hotels & Restaurants',
    'IT',
    'IT Services',
    'Industrial Gases',
    'Industrial Products',
    'Industrial Minerals', 'Infra',
    'Iron & Steel Products',
    'Jewellery',
    'Kithchen Appliances',
    'Leisure Products',
    'Logistics',
    'Macro', 'Manufacturing',
    'Marine',
    'Meat Products including Poultry',
    'Media',
    'Medical Devices',
    'Medical Equipment',
    'NBFC',
    'Non-ferrous metals',
    'Offshore Support Solution Drilling',
    'Packaging',
    'Paints',
    'Personal Care', 'Petrochemicals', 'Pet Bottles',
    'Pesticides',
    'Pharmaceuticals',
    'Plastics',
    'Plastic Products',
    'Power Electronics',
    'Professional Services',
    'Railway Wagons',
    'Railways',
    'Real Estate',
    'Recreation / Amusement Parks', 'Refining',
    'Residential Commerical Projects',
    'Retail-Electronics', 'Salt', 'Shipping',
    'Software',
    'Solar Energy',
    'Specialty Chemicals',
    'Specialty Retail',
    'Stationery', 'Telecommunication',
    'Textiles',
    'Telecom - Equipment & Accessories',
    'Trading & Distribution',
    'Tyres',
    'Wind Energy',
    'Waste Management', 'Visual Effects',
    'Miscellaneous'
]
