import { BUCKET_DATA, GET_BUCKETS, GET_STAR_BUCKETS, LOADING } from "./action";

const init = { functional: [], sectoral: [], buckets: [], bucketData: {}, isLoading: false, star_buckets: [] };

export const bucketReducer = (store = init, { type, payload }) => {

    switch (type) {
        case GET_BUCKETS: {
            return {
                ...store,
                functional: payload.functional,
                sectoral: payload.sectoral,
                buckets: [...payload.functional, ...payload.sectoral],
                isLoading: false
            };
        };

        case GET_STAR_BUCKETS: {
            return {
                ...store,
                star_buckets: payload,
                isLoading: false
            };
        };

        case BUCKET_DATA: {
            return {
                ...store,
                bucketData: payload,
                isLoading: false
            };
        };

        case LOADING: {
            return {
                ...store,
                isLoading: true
            };
        };

        default: {
            return store;
        };
    };
};