import styles from "./styles/SendMail.module.css";
import commonStyles from "../../styles/Common.module.css";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useEffect, useRef, useState } from "react";
import { Button, Chip, Grid } from "@mui/material";
import { Add, Delete, Refresh } from "@mui/icons-material";

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["link"],
        ["clean"],
    ],
};

const formats = [
    "header",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
];


const SendMail = () => {
    const [content, setContent] = useState({
        subject: "",
        message: "",
    });

    const [draftList, setDraftList] = useState([]);

    const [userType, setUserType] = useState({
        userType: "",
        trialBatch: 0,
        course_id: ""
    });

    const [sessionList, setSessionList] = useState([]);
    const [mailingList, setMailingList] = useState([]);
    const [mailingListTotal, setMailingListTotal] = useState(0);

    const mailListRef = useRef();

    useEffect(() => {
        getEducationSessions();
        handleGetDrafts();

        return () => {

        }
    }, []);

    useEffect(() => {

        if (userType) {
            const getMailingList = setTimeout(() => {
                getMailingListFun();

            }, 1000);

            return () => clearTimeout(getMailingList);
        }

        return () => {

        }
    }, [userType]);

    const getEducationSessions = async () => {

        const res = await fetch("https://api.sovrenn.com/course");

        const data = await res.json();

        setSessionList([...data.beginnerList, ...data.interMediateList]);
    }

    const handleChange = (ele) => {
        const { value, id } = ele.target;

        if (id === "userType") {

            const split = value.split(":");

            if (split[0] === "education") {

                setUserType({
                    ...userType,
                    [id]: "education",
                    course_id: split[1],
                    trialBatch: 0
                });
                return;
            };

            if (split[0] === "trialBatch") {

                setUserType({
                    ...userType,
                    [id]: "trial",
                    trialBatch: +split[1]
                });
                return;
            };

            setUserType({
                ...userType,
                [id]: value,
                trialBatch: 0
            });

            return;
        };

        setContent({
            ...content,
            [id]: value,
        });
    };

    const handleSend = async (event) => {
        event.preventDefault();

        const body = {
            ...content,
            ...userType
        };

        const res = await fetch("https://api.sovrenn.com/analytics/send-mail", {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user"),
                "Content-type": "application/json"
            }
        });

        const data = await res.json();

        if (res.ok) {
            alert("Email Sent successfully.");
        }
        else {
            alert("Something went wrong.");
        }

        setContent({
            subject: "",
            message: "",
        });
        setUserType({
            userType: "",
            trialBatch: 0,
            course_id: ""
        });

        return;
    };

    const handleGetDrafts = async (ele) => {
        const res = await fetch("https://api.sovrenn.com/mail-drafts", {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("sov_user")
            },
        });

        const data = await res.json();

        if (res.ok) {
            setDraftList(data.mailDrafts);
        };
    };

    const handleSaveDraft = async (event) => {
        event.preventDefault();

        const res = await fetch("https://api.sovrenn.com/mail-drafts", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "authorization": "Bearer " + localStorage.getItem("sov_user")
            },
            body: JSON.stringify(content)
        });

        const data = await res.json();

        if (res.ok) {
            alert("Saved to draft.");
            setContent({
                subject: "",
                message: ""
            });
            handleGetDrafts();
            return;
        };

        alert("Something went wrong.");
        return;
    };

    const handleRemoveDraft = async (id) => {
        const res = await fetch(`https://api.sovrenn.com/mail-drafts/${id}`, {
            method: "DELETE",
            headers: {
                "authorization": "Bearer " + localStorage.getItem("sov_user")
            },
        });

        const data = await res.json();
        handleGetDrafts();
    };

    const getMailingListFun = async () => {
        if (!userType.userType) return;

        const res = await fetch("https://api.sovrenn.com/analytics/mailing-list", {
            method: "POST",
            body: JSON.stringify(userType),
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user"),
                "Content-type": "application/json"
            }
        });

        const data = await res.json();

        if (res.ok) {
            setMailingList(data.userList);
            setMailingListTotal(data.total);
            return;
        };

        setMailingList([]);
        return;
    };

    return (
        <div className={`${commonStyles.MainContainer} ${styles.HomeContainer}`} >
            <div className={commonStyles.headerDiv}>
                <h1>Send Mail</h1>
            </div>

            <div id={styles.mailContainer}>
                <div id={styles.draftsContainer}>
                    <h3>Drafts</h3>

                    {draftList.map((ele, index) => {
                        return <div key={index} className={styles.draftItem}
                            onClick={() => setContent({
                                subject: ele.subject,
                                message: ele.message
                            })}>
                            <h4><span>Subject: </span>{ele.subject}</h4>

                            <div dangerouslySetInnerHTML={{ __html: ele.message }}></div>

                            <Button sx={{ marginTop: "10px", color: "#627b8e", borderColor: "#627b8e" }} variant="outlined" startIcon={<Delete />} fullWidth
                                onClick={() => handleRemoveDraft(ele._id)}>Remove</Button>
                        </div>
                    })}
                </div>
                <form onSubmit={handleSend} id={styles.createMailForm}>
                    <div className={styles.inputDiv}>
                        <h3>Subject</h3>
                        <input value={content.subject}
                            onChange={(ele) => setContent({
                                ...content,
                                subject: ele.target.value
                            })}
                            type="text" id="subject" required />
                    </div>

                    <div className={styles.textEditorDiv}>
                        <h3>Message</h3>

                        <ReactQuill
                            value={content.message}
                            onChange={(value) => setContent({
                                ...content,
                                message: value
                            })}
                            modules={modules}
                            formats={formats}
                            placeholder="Write something..."
                            className={styles.textEditor}
                        />
                    </div>

                    <div className={styles.inputDiv}>
                        <h3>Select Users</h3>
                        <select ref={mailListRef} id="userType" onChange={handleChange} required>
                            <option value="">Select</option>
                            {sessionList.map((ele, ind) => {
                                return <option value={"education:" + ele._id}>{ele.date} Education Session</option>
                            })}
                            <option value="full-access">Full Access</option>
                            <option value="trial">Trial Live</option>
                            <option value="news">News Access</option>
                            <option value="basket">Discovery Access</option>
                            <option value="paid-no-access">Paid But No Access</option>
                            <option value="no-full-no-trial-ever">No Full Access and Trial Ever Tried</option>
                            <option value="no-access">No Access</option>
                            <option value="no-access-with-trial">No Access With Trial</option>
                            <option value="trial-expired">Trial Expired</option>
                            <option value="prime-only">Prime Only</option>
                            <option value="test">Test</option>
                        </select>
                    </div>

                    <h3><b>Total Emails: </b> {mailingListTotal}</h3>

                    <div style={{ width: '100%', maxHeight: '200px', overflow: 'auto', margin: "20px 0" }}>
                        <Grid container spacing={1}>
                            {mailingList.map((ele, index) => (
                                <Grid item key={index}>
                                    <Chip label={ele} />
                                </Grid>
                            ))}
                        </Grid>
                    </div>

                    <div style={{ display: "flex", gap: "20px", justifyContent: "center" }} >
                        <button style={{ width: "100%", margin: "0" }} type="submit">
                            Send
                        </button>
                        <button style={{ width: "100%", margin: "0" }} onClick={handleSaveDraft}>
                            Save to Draft
                        </button>

                        <Button sx={{ color: "#627b8e", borderColor: "#627b8e" }} variant="outlined" startIcon={<Refresh />}
                            onClick={() => {
                                setContent({
                                    subject: "",
                                    message: ""
                                });

                                setMailingList([]);
                                mailListRef.current.value = "";
                            }}>Reset</Button>
                    </div>
                </form>
            </div>

        </div>
    );
};

export default SendMail