import commonStyles from "../../styles/Common.module.css";
import styles from "../TrialAccess/styles/TrialBatch.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getBatchesFunc, getFamilyBatchesFunc } from "../../Redux/Batches/action";
import moment from "moment";

const batch = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

const TimesBatches = () => {
    const dispatch = useDispatch();
    const { timesBatches, familyBatches } = useSelector((store) => store.batches);

    useEffect(() => {
        dispatch(getBatchesFunc());
        dispatch(getFamilyBatchesFunc());

        return () => {

        };
    }, []);

    return (
        <div className={`${styles.MainContainer}`} >
            <div className={commonStyles.headerDiv}>
                <h1>Batches</h1>
            </div>

            <div id={styles.dataContainer}>
                <div className={styles.batchesMainDiv}>
                    <h2>Family Batches</h2>

                    <div className={styles.batchesContainer}>
                        {familyBatches?.map((ele, ind) => {
                            return <div className={styles.batchCard}>
                                <h2>Batch {ele._id.slice(0, 2)} {ele._id.slice(5, 10)}</h2>
                                <h3>Total Users: <span>
                                    {ele.totalUsers}
                                </span></h3>

                                <Link to={`/family-batches?batch=${ele._id}`}>
                                    View List
                                </Link>
                            </div>
                        })}
                    </div>
                </div>

                <div className={styles.batchesMainDiv}>
                    <h2>Times Batches</h2>

                    <div className={styles.batchesContainer}>
                        {timesBatches?.map((ele, ind) => {
                            return <div className={styles.batchCard}>
                                <h2>{moment(ele.month, 'YYYY-MM').format('MMM YYYY')} ({ele.batch})</h2>
                                <h3>Total Users: <span>
                                    {ele.total}
                                </span></h3>

                                <Link to={`/times-batches/list?startOfMonth=${ele.startOfMonth}&endOfMonth=${ele.endOfMonth}&batch=${ele.batch}`}>
                                    View List
                                </Link>
                            </div>
                        })}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TimesBatches