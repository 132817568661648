import { useEffect, useReducer, useState } from "react";
import commonStyles from "../../styles/Common.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getUserListFunc } from "../../Redux/Batches/action";
import tableStyles from "../Users/styles/Users.module.css";
import styles from "../TrialAccess/styles/TrialBatch.module.css";
import { Link, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Email } from "@mui/icons-material";


const BatchUsersList = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { userList } = useSelector((store) => store.batches);
    const [updateUserData, setUpdateUserData] = useState({
        added_in_fullaccess: null,
        added_in_times: null,
        user_id: ""
    });
    const [dialog, setDialog] = useState(false);
    const [sendMailData, setSendMailData] = useState({
        whatsappLink: ""
    });
    const startOfMonth = searchParams.get("startOfMonth");
    const endOfMonth = searchParams.get("endOfMonth");
    const batch = searchParams.get("batch");

    useEffect(() => {
        dispatch(getUserListFunc(startOfMonth, endOfMonth, batch));

        return () => {

        };
    }, []);


    useEffect(() => {
        if (updateUserData.user_id) {
            const updateData = setTimeout(() => {
                sendUpdateRequest();

            }, 1000);
            return () => clearTimeout(updateData)
        };

        return () => {

        }

    }, [updateUserData]);
    const handleChange = (ele) => {
        const { name, value } = ele.target;

        setSendMailData({
            ...sendMailData,
            [name]: value
        });
    };

    const handleSendMail = async (event) => {
        event.preventDefault();

        
        if (!sendMailData.whatsappLink || !startOfMonth || !endOfMonth || !batch) {
            alert("Please enter valid whatsapp link");
            return;
        };

        const body = {
            startOfMonth,
            endOfMonth,
            batch,
            whatsappLink: sendMailData.whatsappLink
        };

        const res = await fetch("https://api.sovrenn.com/analytics/send-times-batch-mail", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user"),
                "content-type": "application/json"
            },
            body: JSON.stringify(body)
        });

        const data = await res.json();

        if (!res.ok) {
            alert(data.error);
            return;
        };
        
        setDialog(false);
        alert("Sent email successfully");
        sendMailData({
            whatsappLink: ""
        });
        return;
    };

    const sendUpdateRequest = async () => {
        let body = {};

        for (let key in updateUserData) {
            if (updateUserData[key] !== null) {

                body[key] = updateUserData[key];
                continue;
            };
        };

        const res = await fetch(`https://api.sovrenn.com/user/edit-info/${updateUserData.user_id}`, {
            method: "PATCH",
            headers: {
                "Content-type": "application/json",
                "Authorization": localStorage.getItem("sov_user")
            },
            body: JSON.stringify(body)
        });

        const data = await res.json();

        if (res.ok) {
            dispatch(getUserListFunc(startOfMonth, endOfMonth));
            setUpdateUserData({
                added_in_fullaccess: null,
                added_in_times: null,
                user_id: ""
            })
        };
    };

    const updateChange = (key, value, id) => {

        setUpdateUserData({
            ...updateUserData,
            [key]: value,
            user_id: id
        });
    };

    return (
        <div className={`${styles.MainContainer}`} >
            <div className={commonStyles.headerDiv}>
                <h1>Batch: {moment(startOfMonth).format('MMM YYYY')}</h1>

                <Button color="secondary" variant="outlined" fullWidth startIcon={<Email />} sx={{ width: "250px" }} onClick={() => {
                    setDialog(true);
                }}>Send Whatsapp Link</Button>
            </div>

            <div id={tableStyles.tableDiv}>
                <table id={tableStyles.userListContainer}>
                    <thead id={tableStyles.userListHead}>
                        <tr className={tableStyles.userCard}>
                            <th>Sr. No.</th>
                            <th>Wherer Did Hear</th>
                            {/* <th className={tableStyles.userId}>User Id</th> */}
                            <th className={tableStyles.tableNameRow}>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Access To</th>
                            <th>WP Times</th>
                            <th>WP FA</th>
                            <th>Purchased on</th>
                            <th>Actions Count</th>
                            <th>App Installed</th>
                        </tr>
                    </thead>

                    <tbody>
                        {userList.map((ele, ind) => {
                            return <tr className={tableStyles.userCard} key={ele._id}>
                                <td>{ind + 1}</td>
                                <td>{ele?.where_did_hear_about_sovrenn || "NA"}</td>
                                <td>
                                    <Link to={`/users/${ele._id}`}>{ele.user_name}
                                    </Link>
                                </td>
                                <td>{ele.email}</td>
                                <td>{ele.phone_number}</td>

                                <td className={tableStyles.tableSubscriptions}>{ele.subscriptions.map((ele, ind) => {
                                    return <p>{ele === "news" ? "News" : ele === "full-access" ? "Full_Access" : ele === "basket" ? "SD" : ele === "trial" ? "Trial" : ele === "life" ? "Life" : ele === "monthly" ? "Monthly" : ele === "quarterly" ? "Quarterly" : ""}</p>
                                })}</td>


                                <td>
                                    <input
                                        checked={ele.added_in_times ? true : false}
                                        id="added_in_times"
                                        onChange={
                                            () => {
                                                updateChange("added_in_times", !ele.added_in_times, ele._id)
                                            }
                                        }
                                        className={tableStyles.checkBoxes}
                                        type="checkbox" />
                                </td>
                                <td>
                                    <input
                                        checked={ele.added_in_fullaccess ? true : false}
                                        id="added_in_fullaccess"
                                        className={tableStyles.checkBoxes}
                                        type="checkbox"
                                        onChange={
                                            () => {
                                                updateChange("added_in_fullaccess", !ele.added_in_fullaccess, ele._id)
                                            }
                                        } />
                                </td>
                                <td>{moment(ele.createdAt).format("Do MMM YY")}</td>

                                <td>{ele.actions_count}</td>
                                <td>{ele.has_app_installed ? "Yes" : "No"}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>

            <Dialog open={dialog} onClose={() => setDialog(false)}>
                <DialogTitle sx={{ fontWeight: "bolder", paddingBottom: "0" }}>Add Whatsapp Link</DialogTitle>
                <DialogContent sx={{ width: "500px" }}>

                    <TextField onChange={handleChange} name="whatsappLink" size='small' sx={{ marginTop: "20px" }} id="outlined-basic" label="Whatsapp Link" variant="outlined" required fullWidth />

                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={() => {
                        setDialog(false);
                    }}>Cancel</Button>
                    <Button onClick={handleSendMail}>Send Mail</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default BatchUsersList