export const GET_BATCHES = "GET_BATCHES";
export const GET_USERS_LIST = "GET_USERS_LIST";


const getTrialBatches = (payload) => {
    return {
        type: GET_BATCHES,
        payload
    };
};

export const getUsersList = (payload) => {
    return {
        type: GET_USERS_LIST,
        payload
    };
};


export const getTrialBatchesFunc = () => async (dispatch) => {

    const res = await fetch("https://api.sovrenn.com/analytics/trial-batches", {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    });

    const data = await res.json();

    if (res.ok) {
        dispatch(getTrialBatches({
            boughtOnlineBatches: data.boughtOnlineBatches,
            manualAccessBatches: data.manualAccessBatches
        }));
    };

    return;
};

export const getTrialUsersListFunc = (batch) => async (dispatch) => {

    const res = await fetch(`https://api.sovrenn.com/analytics/trial-batch-list?batch=${batch}`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    });

    const data = await res.json();

    if (data.success) {
        dispatch(getUsersList({
            usersList: data.list,
            fullAccess: data.fullAccess,
            noSessions: data.noSessions,
            newsAccess: data.newsAccess,
            sdAccess: data.sdAccess
        }));
    };

    return;
};
