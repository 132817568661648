import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { primeAnalyticsFun } from "../../Redux/Analytics/action";
import commonStyles from "../../styles/Common.module.css";
import styles from "../Users/styles/Users.module.css";


const AnalysePrime = () => {
  const dispatch = useDispatch();
  const { prime_analytics } = useSelector((store) => store.analytics);

  useEffect(() => {
    dispatch(primeAnalyticsFun());

    return () => {

    }
  }, []);

  console.log(prime_analytics);
  
  return (
    <div className={commonStyles.MainContainer}>

      <div className={commonStyles.headerDiv}>
        <h1>Prime Analytics</h1>

      </div>

      <table id={styles.userListContainer}>
        <thead id={styles.userListHead}>
          <tr>
            <th>Sr. No.</th>
            <th>Count</th>
            <th>Amount</th>
            <th>Article On</th>
          </tr>
        </thead>

        <tbody>
          {prime_analytics.map((ele, ind) => {
            return <tr className={styles.userCard} key={ele._id}>
              <td>{ind + 1}</td>
              <td>{ele.count}</td>
              <td>₹{ele._id.amount}</td>
              <td>{ele._id.company_name}</td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
  )
}

export default AnalysePrime