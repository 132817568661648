import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authAction } from "../../Redux/Auth/action";
import commonStyles from "../../styles/Common.module.css";
import styles from "./Login.module.css";

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    password: ""
  });

  const handleChange = (ele) => {
    const { value, id } = ele.target;

    setForm({
      ...form,
      [id]: value
    });
  };

  const handleSubmit = async(event) => {
    event.preventDefault();

    const res = await fetch("https://api.sovrenn.com/login", {
      method: "POST",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify(form)
    })
    .then((d) => d.json());


    if(res.success && res?.user?.role?.includes("admin")) {
      localStorage.setItem("sov_user", res.token);
      dispatch(authAction({
        isAuth: true,
        user: res.user,
        token: res.token
      }))
      navigate(`/`, {replace: true});

      setForm({
        email: "",
        password: ""
      })
      return;
    };

    alert("Email or password is not correct");
    return;
  }
  return (
    <div className={`${commonStyles.MainContainer}`} >

      <div id={styles.LoginContainer}>
        <h2>Login</h2>

        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email">Email</label>
            <input value={form.email} type="text" id="email" onChange={handleChange} />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <input value={form.password} type="text" id="password" onChange={handleChange} />
          </div>
          <input type="submit" value="Login" />
        </form>
      </div>

    </div>
  )
}
