import { useDispatch } from "react-redux";
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom";
import { setUserFun } from "../Redux/Auth/action";

export const ProtectedRoute = ({ children }) => {
    const dispatch = useDispatch();
    const isAuth = useSelector((store) => store.auth.isAuth);
    const token = localStorage.getItem("sov_user");

    if (isAuth) {
        return children
    };

    if (token && !isAuth) {
        dispatch(setUserFun(token));

        return children;
    };

    return <Navigate to="/login" replace />
}