import { useState } from "react";
import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/CreateCourse.module.css";

const CreateCourse = () => {
    const [courseData, setCourseData] = useState({
        type: "",
        trainer: "",
        date: "",
        start_time: "",
        end_time: "",
        price: 0,
        min_price: 0,
        meet_link: "",
        description: "",
        total_slots: 0,
        language: "",
    });

    const handleChange = (ele) => {
        const { value, id } = ele.target;

        setCourseData({
            ...courseData,
            [id]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = await fetch("https://api.sovrenn.com/course", {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            },
            body: JSON.stringify(courseData)
        })
            .then((res) => res.json());

        alert("Session Created Successfully!")
    }

    return (
        <div className={`${commonStyles.MainContainer} ${styles.CreateMainContainer}`}>
            <div className={commonStyles.headerDiv}>
                <h1>Create Course</h1>
            </div>

            <div id={styles.formDiv}>
                <form action="" id={styles.form} onSubmit={handleSubmit}>
                    <div id={styles.firstChildDiv}>
                        <div className={styles.inputDiv}>
                            <label htmlFor="type">
                                Type
                            </label>
                            <select id="type" required onChange={handleChange}>
                                <option value="">Select Course Type</option>
                                <option value="Beginner">Beginner</option>
                                <option value="Intermediate">Intermediate</option>
                            </select>
                        </div>

                        <div className={styles.inputDiv}>
                            <label htmlFor="trainer">Trainer</label>
                            <select id="trainer" required onChange={handleChange}>
                                <option value="">Select Trainer</option>
                                <option value="Aditya Joshi">Aditya Joshi</option>
                            </select>
                        </div>

                        <div className={styles.inputDiv}>
                            <label htmlFor="language">Language</label>
                            <select id="language" required onChange={handleChange}>
                                <option value="">Select Language</option>
                                <option value="English">English</option>
                                <option value="Hindi">Hindi</option>
                            </select>
                        </div>

                        <div className={styles.inputDiv}>
                            <label htmlFor="total_slots">
                                Total Slots
                            </label>
                            <input type="number" id="total_slots" name="total_slots" required onChange={handleChange} />
                        </div>
                    </div>

                    <div id={styles.secondChildDiv}>
                        <div className={styles.inputDiv}>
                            <label htmlFor="date">
                                Date
                            </label>
                            <input type="date" id="date" name="date" required onChange={handleChange} />
                        </div>
                        <div className={styles.inputDiv}>
                            <label htmlFor="start_time">
                                Start Time
                            </label>
                            <input type="time" id="start_time" name="start_time" required onChange={handleChange} />
                        </div>
                        <div className={styles.inputDiv}>
                            <label htmlFor="end_time">
                                End Time
                            </label>
                            <input type="time" id="end_time" name="end_time" required onChange={handleChange} />
                        </div>
                        <div className={styles.inputDiv}>
                            <label htmlFor="price">
                                Max Price
                            </label>
                            <input type="number" id="price" name="price" required onChange={handleChange} />
                        </div>

                        <div className={styles.inputDiv}>
                            <label htmlFor="min_price">
                                Min Price
                            </label>
                            <input type="number" id="min_price" name="min_price" required onChange={handleChange} />
                        </div>
                    </div>

                    <div id={styles.thirdChildDiv}>
                        <div className={styles.inputDiv}>
                            <label htmlFor="description">
                                Description
                            </label>
                            <textarea name="description" id="description" required onChange={handleChange} />
                        </div>
                        <div className={styles.inputDiv}>
                            <label htmlFor="meet_link">
                                Zoom Link
                            </label>
                            <input type="text" id="meet_link" name="meet_link" required onChange={handleChange} />
                        </div>

                        <button type="submit">Create Session</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateCourse