import { Add, Delete, Edit } from '@mui/icons-material';
import { Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Select, TextField, useTheme } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import commonStyles from "../../styles/Common.module.css";
import styles from "../Buckets/styles/Bucket.module.css";
import { getChildBucketsFun, getParentBucketsFun } from '../../Redux/NestedBucket/action';
import modalStyles from "../../styles/ModalStyle.module.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


const ParentBuckets = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { parent_buckets, child_buckets, is_loading } = useSelector((store) => store.nested_buckets);
    const [modal, setModal] = useState(false);
    const [selectedId, setSelectedId] = useState("");

    const [modalOpen, setModalOpen] = useState(false);
    const [parentData, setParentData] = useState({
        bucket_name: "",
        about: ""
    });

    const [childBuckets, setChildBuckets] = useState([]);

    useEffect(() => {
        dispatch(getParentBucketsFun());
        dispatch(getChildBucketsFun());

        return () => {

        }
    }, []);

    const deleteBucket = async () => {
        setModal(false);
        const res = await fetch(`https://api.sovrenn.com/parent-buckets/${selectedId}`, {
            method: "DELETE",
            headers: {
                "Authorization": localStorage.getItem("sov_user")
            }
        });

        const data = await res.json();

        if (res.ok) {
            dispatch(getParentBucketsFun());
            alert(data.message);
            return;
        };

        alert("Something went wrong");
        return;
    };

    const handleCreateBucket = async (event) => {
        event.preventDefault();

        const childBucketList = [];

        for (let i = 0; i < child_buckets.length; i++) {
            for (let j = 0; j < childBuckets.length; j++) {
                if (child_buckets[i]["bucket_name"] === childBuckets[j]) {
                    childBucketList.push(child_buckets[i]["_id"]
                    );
                };
            };
        };

        const bodyData = {
            bucket_name: parentData.bucket_name,
            about: parentData.about,
            child_buckets: childBucketList,
        };

        const res = await fetch("https://api.sovrenn.com/parent-buckets", {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                "Authorization": localStorage.getItem("sov_user")
            },
            body: JSON.stringify(bodyData)
        });

        const data = await res.json();
        
        if(res.ok) {
            setModalOpen(false);
            alert("New Parent Bucket Created Successfully.");
            dispatch(getParentBucketsFun());
            return;
        };

        alert("Something went wrong.");
        return;
    };


    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        setChildBuckets(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    if (is_loading) {
        return <div className={commonStyles.MainContainer}>

        </div>
    };

    return (
        <div className={commonStyles.MainContainer}>

            <div className={commonStyles.headerDiv}>
                <h1>Thematic Buckets</h1>

                <div>
                    <Button sx={{width: "300px"}} onClick={() => setModalOpen(!modalOpen)} color="secondary" variant="outlined" startIcon={<Add />} >Create New Parent Bucket</Button>

                    <Link to="/child-buckets/add">
                        <Button sx={{ width: "300px" }}  color="secondary" variant="outlined" startIcon={<Add />} fullWidth>Create New Child Bucket</Button>
                    </Link>
                </div>
            </div>

            <div id={styles.bucketContainer}>
                {parent_buckets.map((ele, ind) => {
                    return <div className={styles.bucketCard}>
                        <h3>{ele.bucket_name}</h3>
                        <h4>{ele.about}</h4>

                        <div className={styles.cardBtns}>
                            <Link to={`/parent-buckets/${ele.bucket_name}`}>
                                <IconButton className={styles.editButton}>
                                    <Edit />
                                </IconButton>
                            </Link>

                            <IconButton className={styles.editButton} onClick={() => {
                                setModal(true);
                                setSelectedId(ele._id)
                            }}>
                                <Delete />
                            </IconButton>
                        </div>
                    </div>
                })}
            </div>

            <Dialog open={modal}>
                <DialogTitle sx={{ fontWeight: "bolder" }}>Confirmation</DialogTitle>
                <DialogContent >
                    <DialogContentText sx={{ color: "black", fontSize: "large" }}>
                        Do you really want delete this bucket
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setModal(false);
                    }}>Cancel</Button>
                    <Button color='error' onClick={deleteBucket}>Delete</Button>
                </DialogActions>
            </Dialog>

            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Box className={modalStyles.InfoModal}>
                    <h3>Create New Parent Bucket</h3>

                    <TextField
                        id="outlined-multiline-static"
                        label="Bucket Title"
                        multiline
                        rows={1}
                        fullWidth
                        sx={{ fontSize: "small" }}
                        required
                        onChange={(ele) => setParentData({
                            ...parentData,
                            bucket_name: ele.target.value
                        })}
                        value={parentData.bucket_name}
                    />

                    {/* <Autocomplete
                        sx={{ margin: "20px 0" }}
                        fullWidth
                        size="medium"
                        disablePortal
                        id="child_buckets"
                        name="child_buckets"
                        options={child_buckets}
                        onChange={(ele, value) => {
                            setParentData({
                                ...parentData,
                                company: value.id
                            })
                        }}
                        getOptionSelected={(option, value) => option.value === value.value}
                        renderInput={(params) => <TextField required {...params} label="Child Buckets" />}
                    /> */}

                    <FormControl sx={{ margin: "20px 0" }} fullWidth id="select-companies">
                        <InputLabel id="demo-multiple-chip-label">Child Buckets</InputLabel>
                        <Select
                            style={{ minHeight: "50px" }}
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={childBuckets}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Child Buckets" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {child_buckets.map((name) => (
                                <MenuItem
                                    key={name.bucket_name}
                                    value={name.bucket_name}
                                    style={getStyles(name, childBuckets, theme)}
                                >
                                    {name.bucket_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        id="outlined-multiline-static"
                        label="About"
                        multiline
                        rows={4}
                        fullWidth
                        sx={{ fontSize: "small", marginBottom: "20px" }}
                        required
                        onChange={(ele) => setParentData({
                            ...parentData,
                            about: ele.target.value
                        })}
                        value={parentData.about}
                    />

                    <button className={modalStyles.submitBtn} onClick={handleCreateBucket}>Create Bucket</button>
                </Box>
            </Modal>
        </div>
    )
}

export default ParentBuckets;