export const GET_DATA = "GET_DATA";
export const GET_COMPANIES = "GET_COMPANIES";
export const IS_LOADING = "IS_LOADING";
export const SORT_COMPANIES = "SORT_COMPANIES";

const getCompanies = (payload) => {
    return {
        type: GET_COMPANIES,
        payload
    };
};

export const getCompaniesList = (payload) => {
    return {
        type: GET_DATA,
        payload
    };
};

export const isLoading = (payload) => {
    return {
        type: IS_LOADING,
        payload,
    };
};

export const sortCompanies = (payload) => {
    return {
        type: SORT_COMPANIES,
        payload,
    };
};

export const getCompaniesFun = () => async(dispatch) => {

    const d = await fetch("https://api.sovrenn.com/company", {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    })
        .then((res) => res.json());

    const data = d.companies.map((ele) => {
        return { label: ele.company_name, id: ele._id };
    });

    dispatch(getCompanies(data));
};

export const getCompaniesListFun = (loading) => async (dispatch) => {
    dispatch(isLoading(loading));

    const d = await fetch("https://api.sovrenn.com/company/buckets-present", {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    })
        .then((res) => res.json());

    dispatch(getCompaniesList(d.companies));
};

export const getFilteredCompanies = (content, quarter) => async (dispatch) => {
    dispatch(isLoading(true));

    const d = await fetch(`https://api.sovrenn.com/company/filter-by-content?filter_by=${content}&quarter=${quarter}`, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("sov_user")
        }
    })
        .then((res) => res.json());

    dispatch(getCompaniesList(d.companies));
};