import { useEffect, useState } from "react";
import commonStyles from "../../styles/Common.module.css";
import tableStyles from "../Users/styles/Users.module.css";
import styles from "../TrialAccess/styles/TrialBatch.module.css";
import { Link } from "react-router-dom";
import { Box, Tab, Tabs } from "@mui/material";
import moment from "moment";


const AccessExpiry = () => {
    const [userList, setUserList] = useState([]);
    const [showComponent, setShowComponent] = useState("about-to-expire-fa-times");

    useEffect(() => {
        getUsersList("about-to-expire-fa-times");

        return () => {

        };
    }, []);

    const getUsersList = async (filterBy) => {
        setUserList([]);

        const res = await fetch(`https://api.sovrenn.com/analytics/${filterBy}`, {
            headers: {
                "Authorization": localStorage.getItem("sov_user")
            },
        });

        const data = await res.json();

        if (res.ok) {
            setUserList(data.data)
        };
    };

    const filterChange = (event, value) => {
        setShowComponent(value);

        getUsersList(value);
    };

    return (
        <div className={`${styles.MainContainer}`} >
            <div className={commonStyles.headerDiv}>
                <h1>Full Access and Times Expiry</h1>
            </div>


            <Box sx={{ marginBottom: "20px" }}>
                <Tabs value={showComponent} onChange={filterChange} aria-label="basic tabs example" centered>
                    <Tab sx={{ width: "200px" }} label="About To Expire" value="about-to-expire-fa-times" />
                    <Tab sx={{ width: "300px" }} label="Expired in the last 15 days" value="expired-fa-times" />
                    <Tab sx={{ width: "200px" }} label="Renewed Access" value="renewed-fa-times" />
                    <Tab sx={{ width: "220px" }} label="Not Renewed Access" value="not-renewed-fa-times" />
                </Tabs>
            </Box>

            {userList.length ?
                <div id={tableStyles.tableDiv}>
                    <table id={tableStyles.userListContainer}>
                        <thead id={tableStyles.userListHead}>
                            <tr className={tableStyles.userCard}>
                                <th>Sr. No.</th>
                                <th className={tableStyles.tableNameRow}>Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Expiry Date</th>
                                <th>Expired Access</th>
                                <th>Subscriptions</th>
                                <th>Remark By</th>
                                <th>Action Counts</th>
                                <th>Remark</th>
                                {showComponent.includes("analytics") ? <th>Last Detected At</th>
                                    :
                                    ""}
                            </tr>
                        </thead>

                        <tbody>
                            {userList.map((ele, ind) => {
                                return <tr className={tableStyles.userCard} key={ele._id}>
                                    <td>{ind + 1}</td>

                                    <td>
                                        <Link to={`/users/${ele._id}`}>{ele.first_name} {ele.last_name}
                                        </Link>
                                    </td>

                                    <td>{ele.email}</td>
                                    <td>{ele.phone_number}</td>
                                    <td>{moment(ele.expiry_date).format("Do MMM YY")}</td>

                                    <td className={styles.tableSubscriptions}>{ele.type === "news" ? "News" : ele.type === "full-access" ? "Full_Access" : ele.type === "basket" ? "SD" : ele.type === "trial" ? "Trial" : ele.type === "life" ? "Life" : ele.type === "monthly" ? "Monthly" : ele.type === "quarterly" ? "Quarterly" : ""}</td>

                                    <td className={styles.tableSubscriptions}>{ele.subscriptions.map((ele, ind) => {
                                        return <p>{ele === "news" ? "News" : ele === "full-access" ? "Full_Access" : ele === "basket" ? "SD" : ele === "trial" ? "Trial" : ele === "life" ? "Life" : ele === "monthly" ? "Monthly" : ele === "quarterly" ? "Quarterly" : ""}</p>
                                    })}</td>

                                    <td>{ele.action_counts || 0}</td>
                                    <td>{ele.remark_by || "NA"}</td>
                                    <td>{ele.remark || "NA"}</td>
                                    {showComponent.includes("analytics") ?
                                        <td>{moment(ele.createdAt).format("Do MMM YY") || "NA"}</td>
                                        :
                                        ""}
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                :
                <h4 style={{ textAlign: "center" }}>Could not find any users for {showComponent}</h4>
            }
        </div>
    )
}

export default AccessExpiry