import { GET_REVENUE, LAST_TRANSACTIONS, PRIME_ANALYTICS, CHART_DATA, DAILY_WEEKLY_REVENUE, SUBS_CONVERSIONS, REVENUE_BY_SOURCE, DAILY_SIGNUPS_BY_CHANEELS, DAILY_USER_ACTIVITY } from "./action";

const init = {
    prime_analytics: [],
    revenue: {},
    last_transactions: [],
    trial_orders: [],
    chart_data_daily: [],
    chart_data_weekly: [],
    daily_weekly_revenue: [],
    is_loading: true,
    total_documents: 0,
    conversions: {},
    monthly_revenue_by_source: [],
    weekly_revenue_by_source: [],
    daily_action_counts: [],
    daily_signups_by_channels: [],
    daily_signups_by_utm_sources: [],
    daily_user_activity_by_type: [],
};

export const analyticsReducer = (store = init, { type, payload }) => {
    switch (type) {
        case GET_REVENUE: {
            return {
                ...store,
                revenue: payload
            };
        };

        case REVENUE_BY_SOURCE: {
            return {
                ...store,
                ...payload
            };
        };

        case PRIME_ANALYTICS: {
            return {
                ...store,
                prime_analytics: payload
            };
        };

        case LAST_TRANSACTIONS: {
            return {
                ...store,
                last_transactions: payload.lastOrders,
                trial_orders: payload.trialOrders,
                is_loading: false,
                total_documents: payload.totalDocuments,
            };
        };

        case CHART_DATA: {
            return {
                ...store,
                chart_data_weekly: payload.weeklyData,
                chart_data_daily: payload.dailyData
            }
        };

        case DAILY_SIGNUPS_BY_CHANEELS: {
            return {
                ...store,
                daily_signups_by_channels: payload.dailySignupsByChannels,
                daily_signups_by_utm_sources: payload.dailySignupsBySource
            }
        };

        case DAILY_USER_ACTIVITY: {
            console.log(payload);
            return {
                ...store,
                daily_user_activity_by_type: payload
            }
        };

        case DAILY_WEEKLY_REVENUE: {
            return {
                ...store,
                daily_weekly_revenue: payload
            };
        };

        case SUBS_CONVERSIONS: {
            return {
                ...store,
                conversions: payload
            };
        };

        default: {
            return store;
        };
    };
};