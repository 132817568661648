import { Button } from "@mui/material";
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getCourseDetailsFun } from "../../Redux/course/action";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/CourseDetails.module.css";
import { Add } from "@mui/icons-material";


const CourseDetails = () => {
  const dispatch = useDispatch();
  const { course_details } = useSelector((store) => store.course);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getCourseDetailsFun(id));

    return () => {

    }
  }, []);

  if (!Object.keys(course_details).length) {
    return (
      <div className={commonStyles.MainContainer}>

        <div className={commonStyles.headerDiv}>
          <h1>Course Details</h1>

        </div>

        <h2>...loading</h2>
      </div>
    )
  };

  const downloadData = [];

  course_details.users.map((ele, ind) => {
    downloadData.push({
      User_Id: ele.user.custom_Id,
      Name: ele.user.first_name + " " + ele.user.last_name,
      Email: ele.user.email,
      PhoneNumber: ele.user.phone_number,
      State: ele.user.state,
      Paid: ele.amount
    });
  });

  return (
    <div className={commonStyles.MainContainer}>

      <div className={commonStyles.headerDiv}>
        <h1>Course Details</h1>

        <div>
          <Link to={`/courses/${id}/enroll_user`}>
            <Button color="secondary" variant="outlined" startIcon={<Add />} sx={{ width: "150px" }}>Add User</Button>
          </Link>

          <CSVLink data={downloadData} filename={`${course_details.date}-enrolled-users`} target="_blank">
            <Button color="secondary" variant="outlined" startIcon={<DownloadOutlinedIcon />} sx={{ width: "250px" }}>Download List</Button>
          </CSVLink>

        </div>
      </div>

      <div id={styles.DetailsContainer}>
        <div className={styles.topCard}>
          <div className={styles.dateDiv}>
            <h3>{course_details.type}</h3>
            <h3>Trainer: {course_details.trainer}</h3>
          </div>

          <div className={styles.dateDiv}>
            <h3>{course_details.date}</h3>
            <h3>{course_details.start_time} - {course_details.end_time}</h3>
          </div>

          <div className={styles.dateDiv}>
            <h3>Total Slots: {course_details.total_slots}</h3>
            <h3>Slots Booked: {course_details.total_slots - course_details.slots_available}</h3>
          </div>

          <div className={styles.dateDiv}>
            <h3>Paid Users: {course_details.paid_users}</h3>
            <h3>Non Paid Users: {course_details.unpaid_users}</h3>
          </div>

          <div className={styles.dateDiv}>
            <h3>Status: {course_details.status === "active" ? "Active" : "Completed"}</h3>
            <h3>Revenue: ₹{course_details.total_earnings}</h3>
          </div>
        </div>

        <h2>Enrolled Users</h2>

        <table id={styles.userListContainer}>
          <thead id={styles.userListHead}>
            <tr>
              <th>Sr. No.</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>State</th>
              <th>Free/Paid</th>
            </tr>
          </thead>

          <tbody>
            {course_details.users.map((ele, ind) => {
              return <tr className={styles.userCard} key={ind}>
                <td>{ind + 1}</td>
                <td>{ele.user.first_name} {ele.user.last_name}</td>
                <td>{ele.user.email}</td>
                <td>{ele.user.phone_number}</td>
                <td>{ele.user.state}</td>
                <td style={{ color: ele.amount == 0 && ele.user.total_spent == 0 ? "red" : "black"}}>{ele.amount == 0 && ele.user.total_spent == 0 ? "Free" : "Paid"}</td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default CourseDetails