import commonStyles from "../../styles/Common.module.css";
import { useEffect, useRef, useState } from "react";
import { Autocomplete, Button, TextField } from "@mui/material";
import { Add, ArrowUpward } from "@mui/icons-material";
import EditorComponent from "../Editor/EditorComponent";
import styles from "./styles/PrimeArticle.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getCompaniesFun } from "../../Redux/company/action";
import OverlayCircularProgress from "../Utils/OverlayCircularProgress";
import { initEditor } from "../../utils/initEditor";

const AddPrimeArticle = () => {
    const dispatch = useDispatch();
    const ejInstanceRef = useRef();
    const { companies } = useSelector((store) => store.companies);
    const [primeData, setPrimeData] = useState({
        price: 200,
        company_Id: "",
        description: ""
    });
    const [content, setContent] = useState([]);
    const [progress, setProgress] = useState(false);

    useEffect(() => {
        if (!companies.length) {
            dispatch(getCompaniesFun());
        };

        return () => {

        };
    }, []);


    useEffect(() => {

        // if (ejInstanceRef.current === null) {
            const DEFAULT_DATA = {
                "time": new Date().getTime(),
                "blocks": []
            }
            initEditor({ DEFAULT_INITIAL_DATA: DEFAULT_DATA, handleChange, ejInstanceRef });

            console.log({ editorJs: "initialized Init Editor" });
        // };

        console.log({ editorJs: "Invoked EditorJs component", ejInstanceRef })

        return () => {
            ejInstanceRef?.current?.destroy();
            ejInstanceRef.current = null;
        };
    }, []);


    const handleChange = (data) => {
        setContent(data.blocks);
    };

    const setCompany_Id = (ele, value) => {
        setPrimeData({
            ...primeData,
            company_Id: value.id
        });
    };

    const handleSave = async () => {
        try {

            if (!content.length) {
                return alert("Add the Prime Research content, can't be empty");
            };

            if (!primeData.company_Id) {
                return alert("Select the company");
            };

            setProgress(true);

            const res = await fetch(`https://api.sovrenn.com/prime-research/save`, {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("sov_user"),
                    "Content-type": "application/json"
                },
                body: JSON.stringify({
                    ...primeData,
                    content
                })
            });

            const data = await res.json();

            console.log(data);

            setProgress(false);

            if (res.ok) {
                alert("Published Article Successfully");
                return;
            };

            alert("Something went wrong");
            return;
        }
        catch (err) {

            console.log({ err });
            alert("Something went wrong");
            return;
        };
    };

    return (
        <div className={commonStyles.MainContainer}>
            <OverlayCircularProgress loading={progress} />

            <div className={commonStyles.headerDiv}>
                <h2>Add New Prime Article</h2>

                <div>
                    <Button disabled={content.length ? false : true} color="secondary" variant="outlined" startIcon={<Add />} onClick={handleSave} sx={{ width: "150px" }}
                    >Save</Button>
                </div>
            </div>

            <div>
                <div id={styles.firstChildDiv}>
                    <div className={styles.inputDiv}>
                        <label htmlFor="company_Id">Select Company</label>
                        <Autocomplete
                            fullWidth
                            size="medium"
                            disablePortal
                            id="company_Id"
                            name="company_Id"
                            options={companies}
                            sx={{ width: 400 }}
                            onChange={setCompany_Id}
                            getOptionSelected={(option, value) => option.value === value.value}
                            renderInput={(params) => <TextField required {...params} label="Company" />}
                        />
                    </div>
                </div>

                <div id={styles.firstChildDiv}>
                    <div className={styles.inputDiv}>
                        <label htmlFor="company_Id">Description</label>
                        <input onChange={(ele) => setPrimeData({
                            ...primeData,
                            description: ele.target.value
                        })} type="text" className={styles.inputBox} />
                    </div>
                </div>


                <div className={styles.inputDiv}>
                    <label htmlFor="company_Id">Article Content</label>

                    <div style={{ minHeight: "500px", border: "1px solid gray" }}>
                        {/* <EditorComponent DEFAULT_INITIAL_DATA={{
                            "time": new Date().getTime(),
                            "blocks": primeData.content
                        }} handleChange={handleChange}
                        /> */}

                        <div id="editorjs"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddPrimeArticle