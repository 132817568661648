import { GET_CHILD_BUCKETS, GET_CHILD_DATA, GET_PARENT_BUCKETS, GET_PARENT_DATA, SET_LOADING_NESTED } from "./action";

const init = {parent_buckets: [], child_buckets: [], is_loading: true, parent_data: {}, child_data: {}};

export const nestedBucketReducer = (store = init, {type, payload}) => {
    
    switch(type) {
        case GET_PARENT_BUCKETS: {
            return {
                ...store,
                parent_buckets: payload,
                is_loading: false
            };
        };

        case GET_CHILD_BUCKETS: {
            return {
                ...store, 
                child_buckets: payload,
                is_loading: false
            };
        };

        case GET_PARENT_DATA: {
            return {
                ...store,
                parent_data: payload,
                is_loading: false
            };
        };

        case GET_CHILD_DATA: {
            return {
                ...store,
                child_data: payload,
                is_loading: false
            };
        };

        case SET_LOADING_NESTED: {
            return {
                ...store,
                is_loading: true
            };
        };

        default: return store;
    };
};