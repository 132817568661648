import styles from "./styles/SendMail.module.css";
import commonStyles from "../../styles/Common.module.css";
import 'react-quill/dist/quill.snow.css';
import { useRef, useState } from "react";


const SendAppNotification = () => {
    const [content, setContent] = useState({
        title: "",
        body: "",
    });

    const navigateRef = useRef()

    const handleChange = (ele) => {
        const { value, id } = ele.target;


        setContent({
            ...content,
            [id]: value,
        });
    };

    const handleSend = async (event) => {
        event.preventDefault();

        const body = {
            ...content,
            page: navigateRef?.current?.value || "/homeScreen",
        };


        const res = await fetch("https://api.sovrenn.com/common/send-notification-to-all", {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            }
        });

        const data = await res.json();

        if (res.ok) {
            alert("App Notification Sent successfully.");

            setContent({
                title: "",
                body: "",
            });

            navigateRef.current.value = "/homeScreen"
        }
        else {
            alert("Something went wrong.");
        };
        return;
    };

    return (
        <div className={`${commonStyles.MainContainer} ${styles.HomeContainer}`} >
            <div className={commonStyles.headerDiv}>
                <h1>Send App Notification</h1>
            </div>

            <div id={styles.mailContainer}>
                <form onSubmit={handleSend} id={styles.createMailForm}>
                    <div className={styles.inputDiv}>
                        <h3>Title</h3>
                        <input value={content.title} type="text" id="title" onChange={handleChange} required />
                    </div>

                    <div >
                        <h3 style={{ color: "#809275" }}>Message</h3>

                        <textarea
                            id="body"
                            value={content.body}
                            onChange={handleChange}
                            className={styles.messageBox}
                            placeholder="Write message here..."
                            required
                        />
                    </div>

                    <div className={styles.inputDiv}>
                        <h3>Navigate to</h3>
                        <select ref={navigateRef} id="userType">
                            <option value="/homeScreen">Home Screen</option>

                            <option value="/discoveryScreen">Discovery Screen</option>
                            <option value="/primeScreen">Prime Screen</option>
                            <option value="/timesScreen">News Screen</option>
                            <option value="/portfolioScreen">Pulse Screen</option>
                            <option value="/ipoScreen">IPO Screen</option>
                        </select>
                    </div>

                    <div style={{ display: "flex", gap: "20px", justifyContent: "center" }} >
                        <button style={{ width: "50%", margin: "0" }} type="submit">
                            Send
                        </button>
                    </div>
                </form>
            </div>

        </div>
    );
};

export default SendAppNotification