import styles from "./styles/CreateBucket.module.css";
import commonStyles from "../../styles/Common.module.css"
import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, useTheme } from "@mui/material";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCompaniesFun } from "../../Redux/company/action";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


const CreateBucket = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [companyNames, setCompanyNames] = useState([]);
    const { companies } = useSelector((store) => store.companies);
    
    const titleRef = useRef();
    const descRef = useRef();
    const typeRef = useRef();
    const isLatestResultBucketRef = useRef();

    useEffect(() => {
        dispatch(getCompaniesFun());

        return () => {

        }
    }, []);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setCompanyNames(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const companyList = [];

        for(let i = 0; i < companies.length; i++) {
            for(let j = 0; j < companyNames.length; j++) {
                if(companies[i]["label"] === companyNames[j]) {
                    companyList.push(companies[i]["id"]);
                };
            };
        };

        const bodyData = {
            title: titleRef.current.value,
            description: descRef.current.value,
            basket_list: [],
            is_latest_result_bucket: isLatestResultBucketRef.current.value === "true" ? true : false,
            type: typeRef.current.value
        };

        const res = await fetch("https://api.sovrenn.com/buckets", {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            },
            body: JSON.stringify(bodyData)
        });
        
        const data = await res.json();

        if(data.success) {
            alert("New Basket has been created successfully!");
            return;
        }

        alert("Something went wrong, Basket creation failed.");
        return;
    }

    return (
        <div className={`${commonStyles.MainContainer} ${styles.CreateMainContainer}`}>
            <div className={commonStyles.headerDiv}>
                <h1>Create New Bucket</h1>
            </div>

            <form onSubmit={handleSubmit}>
                <div className={styles.inputDiv} style={{marginBottom: "20px"}}>
                    <label htmlFor="title">Basket Title</label>
                    <input name="title" id="title" placeholder="Add title" type="text" ref={titleRef} required />
                </div>

                <div id={styles.firstChildDiv}>
                    <div className={styles.inputDiv}>
                        <label htmlFor="title">Basket Type</label>
                        <select ref={typeRef} name="type" id="type" required>
                            <option value="">Select Type</option>
                            <option value="Functional">Functional</option>
                            <option value="Sectoral">Sectoral</option>
                        </select>
                    </div>

                    {/* <div className={styles.inputDiv}>
                        <label htmlFor="select-companies">Select Companies</label>
                        <FormControl fullWidth id="select-companies">
                            <InputLabel id="demo-multiple-chip-label">Companies</InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={companyNames}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Companies" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {companies.map((name) => (
                                    <MenuItem
                                        key={name.label}
                                        value={name.label}
                                        style={getStyles(name, companyNames, theme)}
                                    >
                                        {name.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div> */}

                    <div className={styles.inputDiv}>
                        <label htmlFor="is_latest_result_bucket">Is this latest result bucket</label>
                        <select value="false" ref={isLatestResultBucketRef} name="is_latest_result_bucket" id="is_latest_result_bucket" required>
                            <option value="" disabled>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                
                <div className={styles.inputDiv}>
                    <label htmlFor="description">Description</label>
                    <textarea name="description" id="description" placeholder="Add Description" ref={descRef} required></textarea>
                </div>
                <button type="submit">Create Basket</button>
            </form>
        </div>
    )
}

export default CreateBucket