export const SET_IPO_LIST = "SET_IPO_LIST";
export const IS_LOADING = "IS_LOADING";

const setIpoList = (payload) => {
    return {
        type: SET_IPO_LIST,
        payload
    };
};

const isLoading = () => {
    return {
        type: IS_LOADING
    };
};


export const setIpoListFun = () => async(dispatch) => {
    dispatch(isLoading());

    const res = await fetch("https://api.sovrenn.com/ipo/list");

    const data = await res.json();

    if(res.ok) {
        dispatch(setIpoList(data.list));
        return;
    };

    dispatch(setIpoList([]));
};